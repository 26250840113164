.x3-1 {
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
  width: fit-content;
  margin-top: 100px;
}

.design-then-our-desi-1 {
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  max-width: 280px;
}
.header-p {
  display: flex;
  flex-direction: column;
}
.x3-1.x3-2 {
  height: 56px;
  margin-top: 30px;
  width: 477px;
}
.span0-1 {
  letter-spacing: 1.5px;
  margin-bottom: 15px;
}

.mainCard {
  flex-direction: column;
}
.lineDiv {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 700px) {
  .span0-1 {
    font-size: 28px !important;
  }
  .x3-1.x3-2 {
    width: 100%;
  }
  .design-then-our-desi-1 {
    font-size: 16px !important;
  }
}
