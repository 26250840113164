.about-page {
  align-items: center;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  min-width: 355px;
  position: relative;
}
.top-grad {
  position: absolute;
  width: 400.03px;
  height: 510.44px;
  left: 0px;
  top: 0px;

  opacity: 0.7;
}
.purple-about {
  position: absolute;
  width: 256.4px;
  height: 296.51px;
  top: 350px;
  left: 150px;
  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.green-about {
  position: absolute;
  width: 450.03px;
  height: 290.43px;
  top: 170px;
  left: 0px;
  background: #119291;

  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.about-purple {
  position: absolute;
  width: 350.92px;
  height: 271.78px;
  left: 1214.31px;
  top: 527.22px;

  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.about-green {
  position: absolute;
  width: 400px;
  height: 254.19px;
  left: 1100px;
  top: 338px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.about-left {
  position: absolute;
  width: 400px;
  height: 241px;
  left: 972px;
  top: 1200px;

  opacity: 0.7;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.about-right {
  position: absolute;
  width: 330px;
  height: 301px;
  left: 1802px;
  top: 1651px;

  opacity: 0.7;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about-purple2 {
  position: absolute;
  width: 304.22px;
  height: 201.78px;
  left: 1703.07px;
  top: 1350.22px;

  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.about-green2 {
  position: absolute;
  width: 430px;
  height: 250.19px;
  left: 1652px;
  top: 1151px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.geo-about-top {
  background-image: url("../../static/img/GeoTopRight.png");
  position: absolute;
  width: 188px;
  height: 301.89px;

  top: 11.11px;
  background-repeat: no-repeat;
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-about-left {
  background-image: url("../../static/img/GeoBotLeft.png");
  position: absolute;
  width: 207px;
  height: 671.89px;
  left: 0px;
  top: 209.43px;
  background-repeat: no-repeat;
  z-index: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-about-bottom {
  background-image: url("../../static/img/aboutLeftGeo.png");
  position: absolute;
  width: 297px;
  height: 871.89px;
  left: 0px;
  top: 1150.11px;
  background-repeat: no-repeat;
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-about-right {
  background-image: url("../../static/img/aboutGeoRight.png");
  position: absolute;
  width: 225px;
  height: 305.89px;
  left: 0px;
  top: 1973.43px;
  background-repeat: no-repeat;
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.hero-section-2 {
  height: 979px;
  min-width: 350px;
  position: relative;
}

.flex-col-3 {
  align-items: center;
  flex-direction: column;
  display: flex;
  min-height: 1216px;
  position: relative;
  top: -1px;
  width: 768px;
}

.logo-4 {
  display: flex;
  gap: 2277px;
  height: 46.8px;
  min-width: 71.04px;
  position: relative;
}

.bg-white-4 {
  height: 46.8px;
  width: 71.04px;
}

.about-us {
  margin-left: 1px;
  margin-top: 76px;
  opacity: 10px;
  position: relative;
  transform: translate(0, 25px);
  width: fit-content;
}

.about-us.animate-enter14 {
  animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
  opacity: 0;
  transform: translate(0, 25px);
}

.chip-3 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
  margin-left: 120px;
}

.line-2 {
  height: 1px;
  min-width: 32px;
  object-fit: cover;
  position: relative;
}

.about-us-1 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-196 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: fit-content;
}
.image-people {
  width: 100px;
  height: 195px;
  position: relative;

  display: inline-block;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  height: 204px;
  min-width: 150px;
  overflow: hidden;
  position: relative;
}
.image-people .image-14 {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.gif {
  height: 204px;
  width: 150px;
  background-color: var(--eerie-black);
  z-index: 10;
}

.image-people:hover .image-14 {
  cursor: pointer;
  height: 204px;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 150px;
  display: inline;
}
.image-14:hover,
.image-people:hover {
  transform: scale(1.1);
}
.we-are-alpha-encoded {
  letter-spacing: 0;
  line-height: 54px;

  position: relative;
  text-align: center;
  font-size: 32px !important;
  margin-top: 20px;
  width: 375px;
}
.define {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -25;
  margin-bottom: 5;
  position: relative;
  text-align: center;
  width: 375px;
}
.we-are-a-digital-age-2 {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -40px;
  opacity: 0;
  position: relative;
  text-align: center;
  transform: translate(0, 25px);
  width: 350px;
}
.we-are-confident-tha-2 {
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 375px;
}

.image {
  background-size: 100% 100%;
  border-radius: 16px;
  cursor: pointer;
  height: 197px;
  margin-top: 32px;
  transition: all 0.2s ease;
  width: 352px;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  object-position: cover;
  background-size: cover;
}

.image:hover {
  transform: scale(1.1);
}
.frame-24-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 180px;
}
.names-mobile {
  flex-direction: row;
  display: flex;
}

.name-3 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  font-size: 20px !important;
}

.ceo-and-founder {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  font-size: 18px !important;
  white-space: pre-wrap;
}

.group-307 {
  align-self: center;
  display: flex;
  height: 234px;
  min-width: 1216px;
  position: relative;
  margin-top: -380px;
  margin-bottom: 90px;
}

.content-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 100px;
  height: 434px;
  position: relative;
  width: 1216px;
}

.we-are-a-digital-age {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  opacity: 0;
  position: relative;
  text-align: center;
  transform: translate(0, 25px);
  width: 350px;
}

.section-our-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  height: 2350px;
  min-width: 375px;
  position: relative;
  padding-top: 80px;
}

.our-team-1 {
  height: 74px;
  letter-spacing: 0;
  line-height: 74px;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 248px;
  margin-bottom: 40px;
  text-align: center;
  align-self: center;
  width: fit-content;
  display: flex;
  opacity: 0;
  transform: translate(0, 25px);
  white-space: nowrap;
}

.our-team-1.animate-enter16 {
  animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
  opacity: 0;
  transform: translate(0, 25px);
}

.profile-team {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 62px;

  margin-left: 25px;
  position: relative;
  width: 350px;
}

.profile-team-item {
  display: none;
}
.profile-team-item-mobile {
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  gap: 25px;
  position: relative;
}

.frame-2-2-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  width: 100px;
  flex-direction: row;
  gap: 18px;
  position: relative;
}

.frame-247 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 359px;
}
.names {
  display: none;
}
.balduino {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.creative-director {
  align-self: stretch;
  color: var(--white);
  font-family: var(--font-family-eudoxus_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  white-space: nowrap;
}

@keyframes animate-enter14-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-enter15-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-enter16-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@media screen and (min-width: 368px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 375px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 400.03px;
    height: 510.44px;
    left: 0px;
    top: 50px;

    opacity: 0.7;
  }
  .purple-about {
    position: absolute;
    width: 256.4px;
    height: 296.51px;
    top: 180px;
    left: 60px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 403.03px;
    height: 290.43px;
    top: 100px;
    left: 25px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 350.92px;
    height: 271.78px;
    left: 1114.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 400px;
    height: 254.19px;
    left: 1100px;
    top: 338px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 400px;
    height: 241px;
    left: 972px;
    top: 1200px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 330px;
    height: 301px;
    left: 1802px;
    top: 1651px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 304.22px;
    height: 201.78px;
    left: 1703.07px;
    top: 1350.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 430px;
    height: 250.19px;
    left: 1652px;
    top: 1151px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 188px;
    height: 301.89px;

    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 207px;
    height: 671.89px;
    left: 0px;
    top: 209.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 297px;
    height: 671.89px;
    left: 0px;
    top: 1150.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 225px;
    height: 305.89px;
    left: 0px;
    top: 1973.43px;
    background-repeat: no-repeat;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .hero-section-2 {
    height: 979px;
    min-width: 375px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 768px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 1px;
    margin-top: 35px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 100px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: fit-content;
  }
  .image-people {
    width: 100px;
    height: 195px;
    position: relative;

    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 204px;
    min-width: 150px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 204px;
    width: 150px;
    background-color: var(--eerie-black);
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 204px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 150px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }
  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 54px;

    position: relative;
    text-align: center;
    font-size: 32px !important;
    margin-top: 20px;
    width: 375px;
  }
  .define {
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -25;
    margin-bottom: 5;
    position: relative;
    text-align: center;
    width: 375px;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -80px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 370px;
  }
  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 375px;
  }

  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 300px;
    margin-top: 20px;
    transition: all 0.2s ease;
    width: 385px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    object-position: cover;
  }

  .image:hover {
    transform: scale(1.1);
  }
  .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 180px;
  }
  .names-mobile {
    flex-direction: row;
    display: flex;
  }

  .name-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    font-size: 20px !important;
  }

  .ceo-and-founder {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    white-space: nowrap;
    font-size: 18px !important;
    white-space: pre-wrap;
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -350px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 350px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    height: 2000px;
    min-width: 375px;
    position: relative;
    padding-top: 80px;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    align-items: center;
    display: flex;
    margin-bottom: 40px;
    flex-direction: row;
    text-align: center;
    align-self: center;
    width: fit-content;
    display: flex;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 62px;

    margin-left: 25px;
    position: relative;
    width: 350px;
  }

  .profile-team-item {
    display: none;
  }
  .profile-team-item-mobile {
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    display: flex;
    gap: 25px;
    position: relative;
  }

  .frame-2-2-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    width: 100px;
    flex-direction: row;
    gap: 18px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }
  .names {
    display: none;
  }
  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

@media screen and (min-width: 768px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 768px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 783.03px;
    height: 510.44px;
    left: 0px;
    top: 250px;

    opacity: 0.7;
  }
  .purple-about {
    position: absolute;
    width: 450.4px;
    height: 296.51px;
    top: 200px;
    left: 200px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 703.03px;
    height: 340.43px;
    top: 50px;
    left: 50px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 730.92px;
    height: 205.78px;
    left: 1114.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 669px;
    height: 254.19px;
    left: 1052px;
    top: 338px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 469px;
    height: 241px;
    left: 972px;
    top: 1050px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 930px;
    height: 601px;
    left: 1702px;
    top: 1101px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 454.22px;
    height: 301.78px;
    left: 1703.07px;
    top: 1220.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 530px;
    height: 354.19px;
    left: 1652px;
    top: 981px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 697px;
    height: 771.89px;
    left: 0px;
    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 497px;
    height: 871.89px;
    left: 3px;
    top: 109.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 497px;
    height: 650px;
    top: 980.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 497px;
    height: 671.89px;
    left: 1163px;
    top: 1353.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .hero-section-2 {
    height: 1279px;
    min-width: 768px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 768px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 1px;
    margin-top: 76px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 220px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: fit-content;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -80px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 700px;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 605px;
  }
  .frame-2-2-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    width: 200px;
    flex-direction: column;
    gap: 18px;
    position: relative;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 304px;
    min-width: 229px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 304px;
    width: 229px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 304px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 229px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }
  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 456px;
    margin-top: 82px;
    transition: all 0.2s ease;
    width: 700px;
  }
  .names-mobile {
    display: none;
  }
  .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 282px;
  }
  .names {
    flex-direction: row;
    display: flex;
  }
  .name-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    font-size: 28px !important;
  }

  .ceo-and-founder {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    white-space: nowrap;
    font-size: 20px !important;
  }
  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -250px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 700px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    height: 1600px;
    min-width: 900px;
    position: relative;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    text-align: center;
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: -50px;
    margin-bottom: 0px;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
    width: fit-content;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 52px;
    height: 2162px;
    margin-left: 25px;
    position: relative;
    width: 768px;
  }

  .profile-team-item {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 50px;
    position: relative;
  }
  .profile-team-item-mobile {
    display: none;
  }
  .frame-250 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }

  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
@media screen and (min-width: 992px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 992px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 983.03px;
    height: 510.44px;
    left: 88px;
    top: 200px;

    opacity: 0.7;
  }
  .purple-about {
    position: absolute;
    width: 506.4px;
    height: 256.51px;
    top: 240px;
    left: 150px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 750.03px;
    height: 395.43px;
    top: 84px;
    left: 40px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 730.92px;
    height: 271.78px;
    left: 1114.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 969px;
    height: 254.19px;
    left: 992px;
    top: 338px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 569px;
    height: 241px;
    left: 972px;
    top: 958px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 930px;
    height: 601px;
    left: 1902px;
    top: 1051px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 504.22px;
    height: 301.78px;
    left: 1703.07px;
    top: 1320.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 730px;
    height: 404.19px;
    left: 1652px;
    top: 1081px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 697px;
    height: 771.89px;
    left: 0px;
    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 497px;
    height: 871.89px;
    left: 3px;
    top: 109.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 697px;
    height: 650px;
    top: 1110.11px;
    background-repeat: no-repeat;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 497px;
    height: 671.89px;
    left: 1163px;
    top: 1453.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .hero-section-2 {
    height: 1279px;
    min-width: 1440px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 1440px;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -80px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 980px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 1px;
    margin-top: 76px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 225px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .names-mobile {
    display: none;
  }
  .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 400px;
  }
  .names {
    flex-direction: row;
    display: flex;
  }
  .name-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    font-size: 28px !important;
  }

  .ceo-and-founder {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    white-space: nowrap;
    font-size: 20px !important;
  }
  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 605px;
  }
  .names-mobile {
    display: none;
  }
  .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 312px;
  }
  .names {
    flex-direction: row;
    display: flex;
  }
  .name-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    font-size: 28px !important;
  }

  .ceo-and-founder {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    white-space: nowrap;
    font-size: 20px !important;
  }
  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 496px;
    margin-top: 82px;
    transition: all 0.2s ease;
    width: 850px;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -250px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 980px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    height: 1450px;
    min-width: 900px;
    position: relative;
    padding-top: 0px;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    flex-direction: row;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 0px;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
    width: fit-content;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 52px;
    height: 2162px;
    margin-left: 112px;
    position: relative;
    width: 992px;
  }

  .profile-team-item {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 80px;
    position: relative;
  }
  .profile-team-item2 {
    display: none;
  }

  .frame-250 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }

  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
@media screen and (min-width: 1200px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 1283.03px;
    height: 510.44px;
    left: 58px;
    top: 150px;

    opacity: 0.8;
  }
  .purple-about {
    position: absolute;
    width: 606.4px;
    height: 406.51px;
    top: 300px;
    left: 50px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 1050.03px;
    height: 440.43px;
    top: 94px;
    left: 30px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 530.92px;
    height: 271.78px;
    left: 1214.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 869px;
    height: 254.19px;
    left: 992px;
    top: 338px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 769px;
    height: 241px;
    left: 972px;
    top: 958px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 930px;
    height: 601px;
    left: 2202px;
    top: 1151px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 554.22px;
    height: 301.78px;
    left: 1703.07px;
    top: 1220.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 730px;
    height: 354.19px;
    left: 1652px;
    top: 981px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 697px;
    height: 771.89px;
    left: 0px;
    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 497px;
    height: 871.89px;
    left: 3px;
    top: 109.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 697px;
    height: 650px;
    top: 1110.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 497px;
    height: 671.89px;
    left: 1163px;
    top: 1353.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .hero-section-2 {
    height: 1279px;
    min-width: 1440px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 1440px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 1px;
    margin-top: 86px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 225px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0px;
    position: relative;
    width: fit-content;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 605px;
  }

  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 496px;
    margin-top: 50px;
    transition: all 0.2s ease;
    width: 1026px;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -280px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }
  .frame-2-2-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 394px;
    min-width: 329px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 394px;
    width: 329px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 394px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 329px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1200px;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -80px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1216px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    gap: 56px;
    height: 1600px;
    min-width: 1440px;
    position: relative;
    padding-top: 0px;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    flex-direction: row;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 0px;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
    width: fit-content;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: 2162px;
    margin-left: 112px;
    position: relative;
    width: 920px;
  }

  .profile-team-item {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 70px;
    position: relative;
  }

  .frame-250 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }

  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 304px;
    min-width: 229px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 304px;
    width: 229px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 304px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 229px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
@media screen and (min-width: 1280px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 1283.03px;
    height: 510.44px;
    left: 58px;
    top: 150px;

    opacity: 0.7;
  }
  .purple-about {
    position: absolute;
    width: 606.4px;
    height: 206.51px;
    top: 300px;
    left: 400px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 1000px;
    height: 440.43px;
    top: 125px;
    left: 180px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 530.92px;
    height: 271.78px;
    left: 1214.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 869px;
    height: 254.19px;
    left: 1092px;
    top: 338px;

    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 769px;
    height: 241px;
    left: 972px;
    top: 958px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 930px;
    height: 601px;
    left: 2202px;
    top: 1151px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 554.22px;
    height: 251.78px;
    left: 1703.07px;
    top: 1120.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 730px;
    height: 354.19px;
    left: 1652px;
    top: 981px;

    background: #119291;
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 697px;
    height: 771.89px;
    left: 0px;
    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 497px;
    height: 871.89px;
    left: 3px;
    top: 109.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 697px;
    height: 650px;
    top: 1110.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 497px;
    height: 671.89px;
    left: 1163px;
    top: 1353.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .hero-section-2 {
    height: 1279px;
    min-width: 1440px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 1440px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 0px;
    margin-top: 86px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 220px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0px;
    position: relative;
    width: fit-content;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 605px;
  }

  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 496px;
    margin-top: 50px;
    transition: all 0.2s ease;
    width: 1026px;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -280px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }
  .frame-2-2-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 394px;
    min-width: 329px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 394px;
    width: 329px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 394px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 329px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1200px;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1216px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    gap: 56px;
    height: 1800px;
    min-width: 1440px;
    position: relative;
    padding-top: 0px;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    flex-direction: row;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 0px;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
    width: fit-content;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: 2162px;
    margin-left: 112px;
    position: relative;
    width: 920px;
  }

  .profile-team-item {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 70px;
    position: relative;
  }

  .frame-250 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }

  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 304px;
    min-width: 229px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 304px;
    width: 229px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 304px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 229px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
@media screen and (min-width: 1600px) {
  .about-page {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 1440px;
    position: relative;
  }
  .top-grad {
    position: absolute;
    width: 1283.03px;
    height: 510.44px;
    left: 200px;
    top: 150px;

    opacity: 0.7;
  }
  .purple-about {
    position: absolute;
    width: 656.4px;
    height: 496.51px;
    top: 280px;
    left: 390px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-about {
    position: absolute;
    width: 1000.03px;
    height: 425.43px;
    top: 120px;
    left: 200px;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-purple {
    position: absolute;
    width: 630.92px;
    height: 251.78px;
    left: 1214.31px;
    top: 527.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green {
    position: absolute;
    width: 640px;
    height: 254.19px;
    left: 1250px;
    top: 288px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-left {
    position: absolute;
    width: 769px;
    height: 241px;
    left: 972px;
    top: 958px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .about-right {
    position: absolute;
    width: 930px;
    height: 601px;
    left: 2602px;
    top: 1151px;

    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    position: absolute;
    width: 654.22px;
    height: 301.78px;
    left: 1703.07px;
    top: 1280.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .about-green2 {
    position: absolute;
    width: 830px;
    height: 354.19px;
    left: 1602px;
    top: 921px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .geo-about-top {
    background-image: url("../../static/img/GeoTopRight.png");
    position: absolute;
    width: 697px;
    height: 771.89px;
    left: 0px;
    top: 11.11px;
    background-repeat: no-repeat;
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-left {
    background-image: url("../../static/img/GeoBotLeft.png");
    position: absolute;
    width: 497px;
    height: 871.89px;
    left: 3px;
    top: 109.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-bottom {
    background-image: url("../../static/img/aboutLeftGeo.png");
    position: absolute;
    width: 697px;
    height: 650px;
    top: 1110.11px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-about-right {
    background-image: url("../../static/img/aboutGeoRight.png");
    position: absolute;
    width: 497px;
    height: 671.89px;
    left: 1163px;
    top: 1353.43px;
    background-repeat: no-repeat;
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .hero-section-2 {
    height: 1279px;
    min-width: 1440px;
    position: relative;
  }

  .flex-col-3 {
    align-items: center;
    flex-direction: column;
    display: flex;
    min-height: 1216px;
    position: relative;
    top: -1px;
    width: 1440px;
  }

  .logo-4 {
    display: flex;
    gap: 2277px;
    height: 46.8px;
    min-width: 71.04px;
    position: relative;
  }

  .bg-white-4 {
    height: 46.8px;
    width: 71.04px;
  }

  .about-us {
    margin-left: 1px;
    margin-top: 76px;
    opacity: 0;
    position: relative;
    transform: translate(0, 25px);
    width: fit-content;
  }

  .about-us.animate-enter14 {
    animation: animate-enter14-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
    margin-left: 225px;
  }

  .line-2 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;

    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-196 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 605px;
  }

  .image {
    background-size: 100% 100%;
    border-radius: 16px;
    cursor: pointer;
    height: 496px;
    margin-top: 82px;
    transition: all 0.2s ease;
    width: 1026px;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    display: flex;
    height: 234px;
    min-width: 1216px;
    position: relative;
    margin-top: -250px;
    margin-bottom: 35px;
  }

  .content-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 434px;
    position: relative;
    width: 1216px;
  }

  .number-statistic {
    align-items: flex-end;
    display: flex;
    gap: 132px;
    position: relative;
    width: fit-content;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1216px;
  }
  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: -80px;
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translate(0, 25px);
    width: 1216px;
  }

  .section-our-team {
    display: flex;
    flex-direction: column;
    gap: 56px;
    height: 1850px;
    min-width: 1440px;
    position: relative;
    padding-top: 0px;
  }

  .our-team-1 {
    height: 74px;
    letter-spacing: 0;
    line-height: 74px;
    flex-direction: row;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 0px;
    opacity: 0;
    transform: translate(0, 25px);
    white-space: nowrap;
    width: fit-content;
  }

  .our-team-1.animate-enter16 {
    animation: animate-enter16-frames 1.5s ease-in-out 1s 1 normal forwards;
    opacity: 0;
    transform: translate(0, 25px);
  }

  .profile-team {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: 2162px;
    margin-left: 112px;
    position: relative;
    width: 1217px;
  }

  .profile-team-item {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 70px;
    position: relative;
  }

  .frame-250 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 28px;
    position: relative;
  }

  .frame-247 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 359px;
  }

  .balduino {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .creative-director {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
  }

  .profile-team-item-mobile {
    display: none;
  }

  .image-people {
    width: 130px;
    height: 195px;
    position: relative;
    display: inline-block;
    background-color: var(--white);
    border-radius: 20px;
    display: flex;
    height: 394px;
    min-width: 329px;
    overflow: hidden;
    position: relative;
  }
  .image-people .image-14 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .gif {
    height: 394px;
    width: 329px;
  }
  .names-mobile {
    display: none;
  }
  .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 400px;
  }
  .names {
    flex-direction: row;
    display: flex;
  }
  .name-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    font-size: 28px !important;
  }
  .modal .ceo-and-founder {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    white-space: nowrap;
    font-size: 20px !important;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    height: 394px;
    object-fit: cover;
    transition: all 0.2s ease;
    width: 329px;
    display: inline;
  }
  .image-14:hover,
  .image-people:hover {
    transform: scale(1.1);
  }
  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
