.section-services-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 72px;
  padding: 96px 112px;
  position: relative;
  width: 100%;
  height: 862px;
}

.title-1 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  /* opacity: 0; */
  position: relative;
  transform: translate(0, 25px);
  width: 100%;
}

.title-1.animate-enter2 {
  animation: animate-enter2-frames 1.5s ease-in-out 1.5s 1 normal forwards;
  /* opacity: 0; */
  transform: translate(0, 25px);
}

.turning-visions-into-reality-1 {
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
  text-align: center;
  justify-content: center;
}

.services-1 {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 30px;
  height: 356px;
  position: relative;

  width: 100%;
}

.speaker-1-1 {
  height: 40px;
  min-width: 40px;
  object-fit: cover;
  position: relative;
}
@media screen and (max-width: 1306px) {
  .section-services-1 {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
  .services-1 {
    align-items: center;
    justify-content: center;
    display: flex;

    width: 100%;
    flex-wrap: wrap;
    height: auto;
    flex-direction: row;
  }
}
@media screen and (max-width: 1055px) {
  .services-1 {
    height: auto;
    width: 100%;
    margin: auto;
  }
  .section-services-1 {
    height: auto;
  }
}

@media screen and (max-width: 880px) {
  .turning-visions-into-reality-1 {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: start;
    line-height: 70px;
  }
  .title-1 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: start;
    align-items: flex-start;
    gap: 10px;
  }
}
@media screen and (max-width: 700px) {
  .section-services-1 {
    gap: 40px !important;
  }
  .turning-visions-into-reality-1 {
    display: flex;
    font-size: 32px !important;
    line-height: 40px;
    align-items: start;
  }
  .title-1 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: start;
    align-items: flex-start;
  }
  .section-services-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 400px) {
  .section-services-1 {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
