@media screen and (min-width: 0px) {
  .frame-22 {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    gap: 37px;
  }
  .mobile-show {
    width: 1000px;
    height: 0.4px;
    margin-left: auto;
    margin-right: auto;
    background-color: #696969;
  }
}
@media screen and (min-width: 768px) {
  .frame-22 {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    gap: 37px;
  }
  .mobile-show {
    width: 1000px;
    height: 0.1px;
    margin-left: auto;
    margin-right: auto;
    background-color: #696969;
  }
}
@media screen and (min-width: 992px) {
  .frame-22 {
    align-items: flex-start;
    flex-direction: row !important;
    display: flex;
    gap: 37px;
    width: 940px;
  }

  .mobile-show {
    display: none;
  }
}
@media screen and (min-width: 1400px) {
  .frame-22 {
    align-items: flex-start;
    display: flex;
    flex-direction: row !important;
    gap: 97px;
    position: relative;
    width: 1183px;
  }

  .mobile-show {
    display: none;
  }
}
