.services-3 {
  align-items: center;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
/*Gradient*/
.purple-blur {
  background-color: #5025bf;
  position: absolute;
  width: 306.12px;
  height: 302.13px;
  right: 289.59px;
  top: -350;
  background: #5025bf;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);
}
.green-blur {
  background-color: #119291;
  position: absolute;
  width: 305.48px;
  height: 219.18px;
  right: 305px;
  top: 0px;
  background: #119291;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);
}
.left-blur {
  position: absolute;
  width: 356.48px;
  left: 148.4px;
  top: 175.06px;

  opacity: 0.96;
}
/**/
.hero-section-3 {
  height: 1200px;
  max-width: 1440px;
  width: 100%;
  position: relative;
  margin-bottom: 120px;
}

.flex-col-services {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 866px;
  position: relative;
  top: -1px;
  max-width: 1440px;
  width: 100%;
  gap: 40px;
}

.flex-row {
  align-items: center;
  justify-content: center;
  display: flex;

  height: fit-content;
  margin-top: 102px;
  max-width: 1200px;
  width: 100%;
}

.healine {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  position: relative;
  transform: translate(-25px, 0);
  width: fit-content;
}

.healine.animate-enter23 {
  animation: animate-enter23-frames 1.5s ease-in-out 1s 1 normal forwards;
  opacity: 0;
  transform: translate(-25px, 0);
}

.chip-5 {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.line-1-2 {
  height: 1px;
  min-width: 32px;
  object-fit: cover;
  position: relative;
}

.services-4,
.projects-2 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.we-prioritize-the-se {
  letter-spacing: 0;
  line-height: 74px;
  position: relative;
  width: 90%;
}

.element-1 {
  height: 146px;
  margin-top: 30px;
  opacity: 0;
  transform: translate(25px, 0);
  width: 147px;
  align-self: baseline;
  z-index: 10;
}

.element-1.animate-enter24 {
  animation: animate-enter24-frames 1.5s ease-in-out 1s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

.overlap-group1-3 {
  height: 146px;
  position: relative;
  width: 145px;
}

.overlap-group-15 {
  height: 146px;
  left: 0;
  position: absolute;
  top: 0;
  width: 145px;
}

.ellipse-163-1 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(130, 168, 244, 0) 17.45%,
    rgba(130, 168, 244, 0.8) 77.16%
  );
  border-radius: 72.5px;
  height: 145px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 145px;
}

.rectangle-10 {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 145px;
}

.text-12 {
  color: var(--white);
  font-family: var(--font-family-clash_grotesk-regular);
  font-size: 60px;
  font-weight: 400;
  left: 33px;
  letter-spacing: 0;
  line-height: 96px;
  position: absolute;
  top: 25px;
  transform: rotate(15deg);
  white-space: nowrap;
}
/* BOTTOM SERVICE SECTION */
.serviceSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 356px;
  margin-top: 80px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  gap: 70px;
}
.serviceButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 5px;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  z-index: 15;
  flex-shrink: 1;
}
.singleButton {
  display: flex;
  align-items: center;
  align-self: stretch;
  background-color: var(--white-5);
  border: 2px solid;
  border-color: var(--shark);
  border-radius: 20px;
  cursor: pointer;
  padding: 15px 35px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  box-sizing: content-box;
  /* width: 300px; */
}
.singleButton.pressed {
  margin: -1px;
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
  border: 3px solid #00d7c3;
}
.sectionIcon {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
@keyframes animate-enter23-frames {
  from {
    opacity: 0;
    transform: translate(-25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-enter29-frames {
  from {
    opacity: 0;
    transform: translate(25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-enter24-frames {
  from {
    opacity: 0;
    transform: translate(25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 1225px) {
  .hero-section-3 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .healine {
    width: 80%;
  }

  .hero-section-3 {
    height: 1300px;
  }
  .serviceButtons {
    gap: 0px !important;
    padding: 15px;
  }
}

@media screen and (max-width: 1075px) {
  .sectionIcon {
    width: 30px;
    height: 30px;
  }
  .service-btn-tag {
    font-size: 18px !important;
  }
  .serviceSection {
    gap: 40px;
  }
}

@media screen and (max-width: 1000px) {
  .hero-section-3 {
    height: 1300px;
  }
  .serviceButtons {
    gap: 0px;
  }
}
@media screen and (max-width: 905px) {
  .serviceButtons {
    justify-content: center;
    gap: 0px;
  }
  .singleButton {
    margin: 10px 10px;
  }
  .hero-section-3 {
    height: 1350px;
  }
  .serviceSection {
    gap: 20px;
  }
  .flex-col-services {
    gap: 10px;
  }
}

@media screen and (max-width: 805px) {
  .serviceSection {
    margin-top: 50px;
  }
  .hero-section-3 {
    height: 1600px;
  }
}
@media screen and (max-width: 775px) {
  .serviceButtons {
    flex-wrap: wrap;
  }
  .singleButton {
    height: 55px;
    /* width: 225px; */
    margin: 10px 10px;
  }
  .flex-row {
    margin-top: 40px;
  }
}
@media screen and (max-width: 700px) {
  .hero-section-3 {
    height: 1400px;
  }
  .we-prioritize-the-se {
    font-size: 32px !important;
    line-height: 40px;
  }
  .rectangle-10 {
    height: 90px;
    width: 90px;
    top: 15px;
    left: 5px;
  }
  .ellipse-163-1 {
    height: 90px;
    width: 90px;
    top: 15px;
    left: 5px;
  }
  .overlap-group-15 {
    height: 90px;
    width: 90px;
    top: 15px;
    left: 5px;
  }
  .overlap-group1-3 {
    height: 90px;
    width: 90px;
  }
  .text-12 {
    font-size: 32px;
  }
}
@media screen and (max-width: 640px) {
  .rectangle-10,
  .ellipse-163-1,
  .overlap-group-15,
  .overlap-group1-3,
  .text-12,
  .element-1 {
    display: none;
    width: 0%;
  }
  .healine {
    width: 100%;
  }
  .we-prioritize-the-se {
    width: 100%;
  }
  .singleButton {
    width: 160px;
  }
  .hero-section-3 {
    height: 1650px;
  }
}
@media screen and (max-width: 500px) {
  .service-btn-tag {
    font-size: 16px !important;
  }
  .singleButton {
    width: 160px;
  }
  .sectionIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .serviceButtons {
    padding: 15px 0px;
  }
  .hero-section-3 {
    height: 1650px;
  }
}
@media screen and (max-width: 480px) {
  .hero-section-3 {
    height: 1650px;
  }
  .singleButton {
    width: 135px;
    padding: 8px;
    margin: 3px;
  }
}
@media screen and (max-width: 400px) {
  .hero-section-3 {
    height: 1750px;
  }
}
@media screen and (max-width: 368px) {
  .serviceButtons {
    width: 110%;
  }
}

@media screen and (max-width: 340px) {
  .serviceButtons {
    width: 120%;
  }
}
