.marquee {
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 52px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
  margin-bottom: 12;

  justify-content: flex-start;
}

.marquee h2 {
  display: inline-block;
  animation: marquee 15s linear infinite;
  padding-left: 15px;
  font-family: "Space";
  font-weight: normal !important;
  /* margin-right: 10px; */
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.mainHeading {
  display: flex;
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
  margin-bottom: 15;
  flex-wrap: wrap;
  justify-content: center;
}

.bottomJumbotron {
  display: flex;
  flex-direction: column;

  height: 414px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--black);
  align-items: center;
  justify-content: center;
}

.button-1 {
  align-items: center;
  background-color: var(--portage);
  border-radius: 12px;
  display: flex;
  gap: 10px;
  padding: 20px 56px;
  position: relative;
}

.smallText {
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  margin-bottom: 30;
  flex-wrap: wrap;
}
.view-more {
  letter-spacing: 0;
  flex-direction: row;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  /* width: fit-content; */
}
/* Gradients */

.left-gradient {
  position: absolute;
  width: 100%;
  height: 414px;
  align-content: flex-start;
  left: -457px;
  opacity: 0.7;
}
.right-gradient {
  position: absolute;
  width: 100%;
  height: 414px;
  align-content: flex-end;
  opacity: 0.7;
  left: 1807px;
}
.purple {
  position: absolute;
  width: 694px;
  height: 374px;
  top: 200px;
  background: #5025bf;
  filter: blur(60px);
  transform: translate3d(0, 0, 0);
}
.green {
  position: absolute;
  width: 659px;
  height: 414px;
  top: -160px;
  background: #119291;
  filter: blur(60px);
  transform: translate3d(0, 0, 0);
}
.purple2 {
  position: absolute;
  width: 694px;
  height: 374px;
  top: 200px;
  left: -100px;
  background: #5025bf;
  filter: blur(60px);
  transform: translate3d(0, 0, 0);
}
.green2 {
  position: absolute;
  width: 519px;
  height: 314px;
  top: -100px;
  left: -100px;
  background: #119291;
  filter: blur(60px);
  transform: translate3d(0, 0, 0);
}
/* Geometrics */
.geomtris-jumbo-right {
  background-image: url(../../static/img/JumboRight.png);
  position: absolute;
  width: 497px;
  height: 471.89px;
  right: -42px;
  top: 20;
  background-repeat: no-repeat;
  opacity: 0.52;
}
.geomtris-left {
  background-image: url(../../static/img/JumboLeft.png);
  position: absolute;
  width: 497px;
  height: 471.89px;
  left: 0px;
  top: -1;
  background-repeat: no-repeat;
  opacity: 0.52;
}
@media screen and (max-width: 1750px) {
  /* Gradients */

  .left-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-start;
    left: -457px;
    opacity: 0.7;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 1557px;
  }
  .purple {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 659px;
    height: 414px;
    top: -160px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .purple2 {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    left: -100px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 314px;
    top: -100px;
    left: -100px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 1500px) {
  /* Gradients */

  .left-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-start;
    left: -407px;
    opacity: 0.7;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 1157px;
  }
  .purple {
    position: absolute;
    width: 654px;
    height: 374px;
    top: 200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .purple2 {
    position: absolute;
    width: 694px;
    height: 354px;
    top: 200px;
    left: 130px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    left: 240px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (max-width: 1400px) {
  /* Gradients */

  .left-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-start;
    left: -407px;
    opacity: 0.7;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 1157px;
  }
  .purple {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .purple2 {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    left: -50px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    left: 50px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 1200px) {
  /* Gradients */

  .left-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-start;
    left: -407px;
    opacity: 0.7;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 857px;
  }
  .purple {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .purple2 {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    left: -50px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 414px;
    top: -120px;
    left: 50px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 992px) {
  /* Gradients */

  .left-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-start;
    left: -407px;
    opacity: 0.7;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 650px;
  }
}
@media screen and (max-width: 855px) {
  .mainHeading {
    /* align-self: center !important; */

    font-size: 32px !important;
    line-height: 40px;
  }
  .bottomJumbotron {
    height: 250px;
    gap: 0px;
  }
  .right-gradient {
    position: absolute;
    width: 100%;
    height: 414px;
    align-content: flex-end;
    opacity: 0.7;
    left: 750px;
  }
  .purple2 {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 100px;
    left: -200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -120px;
    left: -100px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .purple {
    position: absolute;
    width: 694px;
    height: 374px;
    top: 200px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -120px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  /* .button-1 {
      width: 146px;
      height: 48px;
      justify-content: center;
      align-items: center;
    }
    .view-more {
      display: flex;
      font-size: 14px !important;
      align-items: center;
      width: 100%;
      justify-content: center;
    } */
}
@media screen and (max-width: 700px) {
  .mainHeading {
    align-self: center !important;

    font-size: 28px !important;
    margin-bottom: 5px;
    line-height: 35px;
    text-align: center;
  }

  .bottomJumbotron {
    height: 170px;
  }
  .button-1 {
    width: 126px;
    height: 38px;
    justify-content: center;
    align-items: center;
  }
  .view-more {
    display: flex;
    font-size: 14px !important;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .smallText {
    display: flex;
    margin-bottom: 4px;
    font-size: 14px !important;
  }
  .right-gradient {
    left: 597px;
  }
  .purple2 {
    position: absolute;
    width: 394px;
    height: 274px;
    top: 20px;
    left: -60px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -120px;
    left: 0px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .purple {
    position: absolute;
    width: 654px;
    height: 274px;
    top: 100px;
    left: -45px;
    background: #5025bf;
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -100px;
    left: 40px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 550px) {
  .right-gradient {
    left: 537px;
  }
  .purple2 {
    position: absolute;
    width: 394px;
    height: 274px;
    top: 50px;
    left: -90px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green2 {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -120px;
    left: -20px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .purple {
    position: absolute;
    width: 654px;
    height: 274px;
    top: 100px;
    left: -45px;
    background: #5025bf;
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
  }
  .green {
    position: absolute;
    width: 519px;
    height: 214px;
    top: -100px;
    left: 40px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  /* Geometrics */
  .geomtris-jumbo-right {
    background-image: url(../../static/img/JumboRight.png);
    position: absolute;
    width: 497px;
    height: 471.89px;
    right: -150px;
    top: -20;
    background-repeat: no-repeat;
    opacity: 0.52;
  }
  .geomtris-left {
    background-image: url(../../static/img/JumboLeft.png);
    position: absolute;
    width: 497px;
    height: 471.89px;
    left: -50px;
    top: 0px;
    background-repeat: no-repeat;
    opacity: 0.52;
  }
}
@media screen and (max-width: 500px) {
  .bottomJumbotron {
    background-image: url(../../static/img/JumbotronBack.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
  }
  .mainHeading {
    font-size: 22px !important;
  }
  .marquee {
    width: 80%;
  }
  .marquee h2 {
    padding-left: 8px;
  }

  .right-gradient {
    display: none;
  }
  .left-gradient {
    display: none;
  }
  .purple2 {
    /* position: absolute;
    width: 394px;
    height: 274px;
    top: 50px;
    left: -90px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0); */
    display: none;
  }
  .green2 {
    /* position: absolute;
    width: 519px;
    height: 214px;
    top: -120px;
    left: -20px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0); */
    display: none;
  }
  .purple {
    /* position: absolute;
    width: 654px;
    height: 274px;
    top: 100px;
    left: -45px;
    background: #5025bf;
    filter: blur(50px);
    transform: translate3d(0, 0, 0); */
    display: none;
  }
  .green {
    /* position: absolute;
    width: 519px;
    height: 214px;
    top: -100px;
    left: 40px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0); */
    display: none;
  }
  /* Geometrics */
  .geomtris-jumbo-right {
    /* background-image: url(../../static/img/JumboRight.png);
    position: absolute;
    width: 497px;
    height: 271.89px;
    right: -200px;
    top: -20;
    background-repeat: no-repeat;
    opacity: 0.52; */
    display: none;
  }
  .geomtris-left {
    /* background-image: url(../../static/img/JumboLeft.png);
    position: absolute;
    width: 497px;
    height: 271.89px;
    left: -130px;
    top: 0px;
    background-repeat: no-repeat;
    opacity: 0.52; */
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .mainHeading {
    font-size: 20px !important;
  }
}
