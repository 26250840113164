#word-span {
  padding: 5px;
}
#curlySpan {
  position: absolute;
  width: 100%;
  margin-left: 12px;
  top: -5px;
  display: inline-flex;
}
#curlySpan-mobile {
  position: absolute;
  width: 100%;
  margin-left: 12px;
  top: -17px;
  left: 145px;
  z-index: -1;
  display: inline-flex;
}
.lets-build-cool-sht-together-1 {
  color: var(--white);
  font-family: var(--font-family-switzer-extrabold);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 74px;
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 700px) {
  .lets-build-cool-sht-together-1 {
    font-size: 32px;
    line-height: 40px;
  }
}
.glitch {
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  animation: glitch 725ms infinite;
  overflow: visible;
  height: fit-content;
}

.glitch .test {
  position: absolute;
  top: 3;
  left: 4;
}

.glitch .test:first-child {
  animation: glitch 500ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.04em, -0.03em);
  opacity: 0.75;
}

.glitch .test:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.04em, 0.03em);
  opacity: 0.75;
}
.animatedTextGif {
  position: relative;
  top: -14px;
  left: -6px;
}
@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
      -0.04em -0.025em 0 #fffc00;
  }
}
