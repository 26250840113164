:root {
  --anzac: #d9bc43;
  --apple: #58a438;
  --black: #000000;
  --black-2: #00000066;
  --black-3: #00000080;
  --black-4: #00000033;
  --blue-chill: #1192914c;
  --concord: #7c7c7c;
  --eerie-black: #1c1c1c;
  --portage: #82a8f4;
  --shark: #252525;
  --silver: #bdbdbd;
  --white: #ffffff;
  --white-2: #ffffff1a;
  --white-3: #ffffff80;
  --white-4: #ffffff12;
  --white-5: #ffffff0a;
  --white-6: #ffffff4c;
  --white-7: #ffffffcc;

  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 36px;
  --font-size-xs: 12px;
  --font-size-xxl: 52px;
  --font-size-xxs: 9px;
  --font-size-ll: 28px;

  --font-family-clash_grotesk-medium: "Clash Grotesk-Medium", Helvetica;
  --font-family-clash_grotesk-regular: "Clash Grotesk-Regular", Helvetica;
  --font-family-eudoxus_sans-bold: "Eudoxus Sans-Bold", Helvetica;
  --font-family-eudoxus_sans-medium: "Eudoxus Sans-Medium", Helvetica;
  --font-family-eudoxus_sans-regular: "Eudoxus Sans-Regular", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-sf_pro_display-medium: "SF Pro Display-Medium", Helvetica;
  --font-family-space_grotesk: "Space Grotesk", Helvetica;
  --font-family-space_mono: "Space Mono", Helvetica;
  --font-family-switzer-bold: "Switzer-Bold", Helvetica;
  --font-family-switzer-extrabold: "Switzer-Extrabold", Helvetica;
  --font-family-switzer-medium: "Switzer-Medium", Helvetica;
  --font-family-switzer-regular: "Switzer-Regular", Helvetica;
  --font-family-switzer-thin: "Switzer-Thin", Helvetica;
  --font-family-switzer-semibold: "Switzer-Semibold", Helvetica;
}
.spacegrotesk-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}
.spacegrotesk-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-silver-20px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
.spacegrotesk-normal-white-22px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-medium-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.spacemono-normal-white-9px {
  color: var(--white);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-white-32px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-normal-silver-16px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.spacegrotesk-bold-white-28px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-ll);
  font-style: normal;
  font-weight: 700;
}
.spacegrotesk-bold-white-36px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-8px {
  color: var(--white-3);
  font-family: var(--font-family-space_mono);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.switzer-normal-white-28px {
  color: var(--white);
  font-family: var(--font-family-switzer-semibold);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.switzer-regular-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-portage-12px {
  color: var(--portage);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}
.switzer-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.inter-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-white-32px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.switzer-bold-white-64px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.switzer-bold-white-54px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-9px-2 {
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.spacemono-normal-apple-9px {
  color: var(--apple);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.spacemono-normal-anzac-9px {
  color: var(--anzac);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.switzer-medium-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-silver-12px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-white-28px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-portage-16px {
  color: var(--portage);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-8px-2 {
  color: var(--white);
  font-family: var(--font-family-space_mono);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-medium-white-10px {
  color: var(--white-7);
  font-family: var(--font-family-space_grotesk);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-17px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.switzer-bold-white-52px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}

.inter-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-concord-14px {
  color: var(--concord);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-silver-14px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.eudoxussans-medium-white-20px {
  color: var(--white-3);
  font-family: var(--font-family-eudoxus_sans-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.eudoxussans-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-eudoxus_sans-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.switzer-normal-eerie-black-16px {
  color: var(--eerie-black);
  font-family: var(--font-family-switzer-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-white-64px-2 {
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-portage-64px {
  color: var(--portage);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-medium-silver-24px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-11px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-medium-silver-12px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-shark-14px {
  color: var(--shark);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
