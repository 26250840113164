.blog-3 {
  align-items: center;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  position: relative;
}
.blog-container {
  width: 100%;
  height: auto;
}
.dib-homepage-title {
  display: none;
}

.section-our-blog {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: auto;
  margin-bottom: 100px;
  display: flex;
  position: relative;
}
.line-1-2 {
  min-width: 32px;
  object-fit: cover;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.blogs-2 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-5 {
  align-items: center;
  display: flex;
  gap: 40px;
  height: 356px;
  margin-top: 112px;
  position: relative;
  width: 350px;
}

.blog {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  position: relative;
  width: auto;
}

.title-212 {
  align-self: center !important;
  flex-direction: row;
  margin-top: 60px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: auto;
  text-align: center;
}

.chip-6 {
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.see-blogs-that-we {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 50px;
  position: relative;
  font-size: 32px !important;
}

@media screen and (min-width: 768px) {
  blog-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 100%;
    position: relative;
    margin-bottom: 0px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .blog {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    height: auto;
    justify-content: flex-start;

    position: relative;
    width: 700px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-blogs-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }
  .blog-container {
    width: 100%;
    height: auto;
  }
  .title-212 {
    align-self: flex-start;
    flex-direction: row;
    gap: 50px;
    opacity: 0;
    position: relative;
    transform: translate(25px, 0);
    width: auto;
    margin-right: 0px;
  }
}

@media screen and (min-width: 992px) {
  blog-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 100%;
    position: relative;
    margin-bottom: 0px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .blog {
    align-items: center;
    display: flex;
    flex-direction: column;

    height: auto;
    justify-content: center;

    position: relative;
    width: 900px;
  }

  .title-212 {
    align-self: flex-start;
    flex-direction: row;
    gap: 20px;
    opacity: 0;
    position: relative;
    margin-top: 0px;
    transform: translate(25px, 0);
    width: auto;
    margin-right: 0px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }
  .blog-container {
    width: 100%;
    height: auto;
  }
  .see-blogs-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }
}
@media screen and (min-width: 1200px) {
  blog-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 100%;
    position: relative;
    margin-bottom: 0px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .blog {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    height: auto;
    justify-content: flex-start;

    position: relative;
    width: 1183px !important;
  }

  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    position: relative;
    width: auto;
  }
  .blog-container {
    width: 100%;
    height: auto;
  }
  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-blogs-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: 52px !important;
  }
}
@media screen and (min-width: 1400px) {
  blog-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 100%;
    position: relative;
    margin-bottom: 0px;
    margin-top: 75px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .blog {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    height: auto;
    justify-content: flex-start;

    position: relative;
    width: 1183px !important;
  }
  .blog-container {
    width: 100%;
    height: auto;
  }
  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    position: relative;
    width: auto;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-blogs-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
  }

  .frame-224 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
    width: fit-content;
  }

  .vector-22-7 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }

  .vector-23 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }

  @keyframes animate-enter23-frames {
    from {
      opacity: 0;
      transform: translate(-25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter29-frames {
    from {
      opacity: 0;
      transform: translate(25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter24-frames {
    from {
      opacity: 0;
      transform: translate(25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
