@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Space+Grotesk:400,700,500|Space+Mono:400,700|Inter:500");

@font-face {
  font-family: "SF Pro Display-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/SFPRODISPLAYMEDIUM.OTF");
}
@font-face {
  font-family: "Switzer-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/Switzer-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Switzer-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/Switzer-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Clash Grotesk-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/ClashGrotesk-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Eudoxus Sans-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/EudoxusSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Eudoxus Sans-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/EudoxusSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Switzer-Extrabold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Switzer-Extrabold.otf") format("opentype");
}
@font-face {
  font-family: "Switzer-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Switzer-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "Clash Grotesk-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/ClashGrotesk-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Switzer-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Switzer-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Awesome-Journey";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Awesome-Journey.otf") format("opentype");
}
@font-face {
  font-family: "Fancy-Signature";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Fancy-Signature.otf") format("opentype");
}
@font-face {
  font-family: "Magical-Star";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Magical-Star.otf") format("opentype");
}
@font-face {
  font-family: "Monoton-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Monoton-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Spiced-Cheese";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Spiced-Cheese.otf") format("opentype");
}
@font-face {
  font-family: "Unkempt-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Unkempt-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Ice-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/ICEBOLD.ttf") format("truetype");
}
@font-face {
  font-family: "Digitall";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Digitall.ttf") format("truetype");
}
@font-face {
  font-family: "Riviera";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Riviera.otf") format("opentype");
}
@font-face {
  font-family: "NidusSans";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/NidusSans.ttf") format("opentype");
}
@font-face {
  font-family: "Space";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/SpaceHeavy.otf") format("opentype");
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Orbitron Medium.ttf") format("truetype");
}
.screen a {
  display: contents;
  text-decoration: none;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: "super-sick-alpha-font";
  src: url("static/fonts/super-sick-alpha-font.woff?20378718") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'super-sick-alpha-font';
    src: url('../font/super-sick-alpha-font.svg?20378718#super-sick-alpha-font') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "super-sick-alpha-font";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  font-size: 120%;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logical-thinking:before {
  content: "\e800";
} /* '' */
.icon-web:before {
  content: "\e801";
} /* '' */
.icon-roadmap:before {
  content: "\e802";
} /* '' */
.icon-ui:before {
  content: "\e803";
} /* '' */
.icon-maintenance:before {
  content: "\e804";
} /* '' */
.icon-champagne:before {
  content: "\e805";
} /* '' */
