.privacy-policy-screen {
  background-color: var(--black);
  border: 1px none;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privacy-policy-container {
  min-height: 500px;
  height: auto;
  z-index: 10;
  max-width: 1200px;
  gap: 25px;
  display: flex;
  flex-direction: column;

  margin: 30px 20px 80px 20px;
}
div.privacy-policy-container h4 {
  line-height: 35px;
}
.privacy-policy-div {
  display: flex;
  flex-direction: column;
  line-height: 25px;
}
.privacy-policy-email-link {
  color: #00d7c3;
}

@media screen and (max-width: 700px) {
  .privacy-policy-div {
    font-size: 12px !important;
    line-height: 15px;
  }
  div.privacy-policy-container h4 {
    font-size: 18 !important;
  }
  .privacy-policy-header {
    font-size: 32px !important;
  }
}
