.headline-body-2 {
  align-items: flex-start;
  align-self: stretch;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.web-development-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  white-space: pre-line;
}

.we-build-websites-an-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.headline-body-2 {
  box-shadow: unset;
}
@media screen and (max-width: 1290px) {
  .web-development-1 {
    white-space: normal;
  }
}

@media screen and (max-width: 700px) {
  .web-development-1 {
    display: flex !important;
    font-size: 24px !important;
    flex-wrap: wrap !important;
  }
  .we-build-websites-an-1 {
    font-size: 16px !important;
  }
  .headline-body-2 {
    gap: 5px;
  }
}
