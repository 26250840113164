.geomtris-top {
  background-image: url(../../static/img/ContactTopGeo.png);
  position: absolute;
  display: flex;
  width: 290px;
  background-repeat: no-repeat;
  height: 280px;

  top: 80px;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-bottom-conserv {
  background-image: url(../../static/img/GeomtrisRight.png);
  position: absolute;
  display: flex;
  width: 100%;
  height: 380px;

  top: 1550px;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .geomtris-top {
    background-image: url(../../static/img/ContactTopGeo.png);
    position: absolute;
    display: flex;
    width: 280px;
    background-repeat: no-repeat;
    height: 407px;
    left: 480px;
    top: 175px;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .geo-bottom-conserv {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    display: flex;
    width: 100%;
    height: 471.89px;
    left: 420px;
    top: 1775px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;

    background-repeat: no-repeat;
  }
}
@media (min-width: 992px) {
  .geomtris-top {
    background-image: url(../../static/img/ContactTopGeo.png);
    position: absolute;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    height: 577px;
    left: 622px;
    top: 100px;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .geo-bottom-conserv {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    display: flex;
    width: 100%;
    height: 471.89px;
    left: 670px;
    top: 1640px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;

    background-repeat: no-repeat;
  }
}
@media (min-width: 1200px) {
  .geomtris-top {
    background-image: url(../../static/img/ContactTopGeo.png);
    position: absolute;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    height: 500px;
    top: 130px;
    left: 720px;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-bottom-conserv {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    width: 100%;
    height: 441.89px;
    left: 0px;
    top: 1575px;
    display: flex;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
}
@media (min-width: 1400px) {
  .geomtris-top {
    background-image: url(../../static/img/ContactTopGeo.png);
    position: absolute;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    height: 517px;
    left: 1000px;
    top: 160px;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-bottom-conserv {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    display: flex;
    width: 100%;
    height: 501.89px;
    left: 0px;
    top: 1625px;

    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
}
@media (min-width: 1650px) {
  .geomtris-top {
    background-image: url(../../static/img/ContactTopGeo.png);
    position: absolute;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    height: 517px;
    left: 1180px;
    top: 160px;
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .geo-bottom-conserv {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    display: flex;
    width: 100%;
    height: 501.89px;
    left: 0px;
    top: 1625px;

    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
}
