.section-how-we-work-1 {
  height: 2050px;
  width: 100%;
  max-width: 1400px;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  scroll-snap-align: start;
}

.visual-graphiic {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 50;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 670px;
  width: 50%;
}
.group-300 {
  height: 271px;

  transform: translate(-25px, 0);
  width: 412px;
  z-index: 10;
}

.frame-268-1 {
  align-items: center;
  display: flex;
  height: 271px;
  position: relative;
  width: 412px;
}

.to-do-1-issue-8 {
  align-items: flex-start;
  background-color: var(--black-2);
  border: 1px solid;
  border-color: var(--white-6);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 16px 20px;
  position: relative;
  width: 318px;
}

.to-do-1-issue-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 34px;
  position: relative;
}

.frame-264-1 {
  align-items: flex-start;
  background-color: var(--white-2);
  border-radius: 6px;
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  position: relative;
  width: fit-content;
}

.to-do-1-issue-10,
.in-progress,
.review-1,
.done-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.8;
  position: relative;
  width: fit-content;
  flex-wrap: wrap;
}

.frame-263-1 {
  align-items: flex-start;
  background-color: var(--blue-chill);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 16px;
  position: relative;
  width: 286px;
}

.frame-262-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.start-a-project-with-alpha-encoded-team-1 {
  display: flex;
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  min-width: 254px;
  opacity: 0.8;
  padding: 0;
  position: relative;
  flex-wrap: wrap;
}

.start-a-project-with-alpha-encoded-team-1::placeholder {
  display: flex;
  color: #ffffff99;
  flex-wrap: wrap;
  white-space: pre-wrap;
}

.frame-261-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 12px;
  position: relative;
}

.ae-1-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;
}

.vector-right-3 {
  height: 2px;
  min-width: 20px;
  position: relative;
}

.group-2 {
  height: 74px;
  min-width: 74px;
  position: relative;
}

.overlap-group1-1 {
  height: 74px;
  position: relative;
  width: 76px;
}

.ellipse-163 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(17, 146, 145, 0) 0%,
    rgba(17, 146, 145, 0.8) 65.9%
  );
  border-radius: 37px/36.75px;

  height: 73px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 74px;
}

.overlap-group-10 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 73px;
  justify-content: flex-end;
  left: 0;
  min-width: 74px;
  padding: 22px 31px;
  position: absolute;
  top: 0;
}

.number-6 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 29px;
}

.group-299-1,
.group-302 {
  height: 113px;
  margin-left: 68px;
  transform: translate(25px, 0);
  width: 412px;
}

.frame-2-1 {
  align-items: center;
  display: flex;
  height: 113px;
  position: relative;
  width: 412px;
}

.to-do-1-issue-6 {
  align-items: flex-start;
  background-color: var(--black-2);
  border: 1px solid;
  border-color: var(--white-6);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  position: relative;
  width: 318px;
}

.to-do-1-issue-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.group-301 {
  height: 113px;
  transform: translate(-25px, 0);
  width: 412px;
}

.group-301.animate-enter9 {
  animation: animate-enter9-frames 1.5s ease-in-out 2s 1 normal forwards;
  transform: translate(-25px, 0);
}

.overlap-group-11 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 73px;
  justify-content: flex-end;
  left: 0;
  min-width: 74px;
  padding: 22px 29px;
  position: absolute;
  top: 0;
}

.group-302.animate-enter10 {
  animation: animate-enter10-frames 1.5s ease-in-out 2.5s 1 normal forwards;
  transform: translate(25px, 0);
}
/* SECOND COLUMN */
.flex-col-1 {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 100px;
  min-height: 890px;
  position: relative;

  height: 100%;
  width: 50%;
}
.jiraCardDiv {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 180px;
}
.frame-135 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  position: relative;
  transform: translate(0, 25px);
  width: 519px;
  margin-bottom: 20px;
}

.chip-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.how-we-create-1 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 170px;
}

.we-made-a-process-for-that-1,
.what-our-lovely-clients-say-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
}

.x-the-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.jiraScrollGroup {
  display: flex;
  flex-direction: row;
  padding-right: 70px;
  left: 600;
  gap: 200px;
  width: 2000px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.jiraScrollGroup::-webkit-scrollbar {
  display: none;
}

.jiraImageDiv {
  z-index: 10;
}
@media only screen and (max-width: 1200px) {
  .section-how-we-work-1 {
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: 120px;
    height: 3700px;
  }

  .flex-col-1 {
    width: 100%;
  }
  .visual-graphiic {
    width: 100%;
    position: relative;
  }
  .frame-135 {
    line-height: 40px;
  }
}
@media only screen and (max-width: 700px) {
  .jiraImage {
    z-index: 15;
    height: auto;
    width: 100%;
  }
  .flex-col-1 {
    height: 100%;
    margin-bottom: 100px;
    gap: 100px;
  }
  .section-how-we-work-1 {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 80px;
    margin-top: 10px;
    height: auto;
  }
  .we-made-a-process-for-that-1 {
    font-size: 32 !important;
    line-height: 40px;
  }
  .x-the-1 {
    font-size: var(--font-size-m) !important;
  }
  .frame-135 {
    width: 100%;
    max-width: 519px;
    gap: 10px;
    flex-wrap: wrap;
  }
  .visual-graphiic {
    width: 100%;
    height: auto;
  }
  .to-do-1-issue-8 {
    max-width: 318px;
    width: 100%;
    height: 189.14px;
  }
  .to-do-1-issue-9 {
    height: auto;
    gap: 15px;
  }

  .group-300,
  .frame-268-1 {
    max-width: 412px;
    width: 100%;
    height: 189.14px;
  }
  .frame-263-1 {
    max-width: 286px;
    width: 100%;
    height: 84.5px;
    padding: 8px 16px;
    gap: 5px;
    justify-content: space-between;
  }
  .start-a-project-with-alpha-encoded-team-1 {
    max-width: 254px;
    font-size: 10px !important;
    width: 100%;
    min-width: 0px;
    height: auto;
  }
  .vector-right-3 {
    max-width: 20px;
    width: 100%;
    height: auto;
  }

  .to-do-1-issue-6 {
    max-width: 318px;
    width: 100%;
    height: 78.86;
    gap: 0px;
    padding: 8px 16px;
  }
  .group-299-1,
  .group-302,
  .group-301 {
    max-width: 412px;
    width: 100%;
    height: auto;
  }
  .frame-2-1 {
    max-width: 412px;
    width: 100%;
    height: auto;
  }
  .frame-265-2 {
    height: 25px;
  }

  .to-do-1-issue-7 {
    height: 78px;
    gap: 5px;
  }
  .frame-265-1 {
    gap: 10px;
  }
  .to-do-1-issue-10,
  .in-progress,
  .review-1,
  .done-1,
  .create-issue-1,
  .ae-1-1 {
    font-size: 10px !important;
  }
  .we-are-software-agency-4,
  .frame-265-1,
  .frame-265-2,
  .frame-265-3,
  .frame-265-4 {
    width: 100%;
  }
  .vuesaxoutlineadd-1,
  .vuesaxboldtick-square-1 {
    height: 16px;
    width: 16px;
  }
  .frame-262-1 {
    gap: 10px;
  }
  .jiraCardDiv {
    gap: 150px;
  }
}

@media only screen and (max-width: 475px) {
  .frame-135 {
    line-height: 20px;
  }
  .to-do-1-issue-8 {
    gap: 0px;
  }
  .to-do-1-issue-6 {
    max-width: 222px;
  }
  .frame-262-1 {
    gap: 0px;
  }
  .start-a-project-with-alpha-encoded-team-1 {
    height: 58px;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 380px) {
  .group-299-1,
  .group-302,
  .group-301,
  .frame-2-1 {
    left: -10px;
  }
}
