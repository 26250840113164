.hero-section-1 {
  align-items: center;
  display: flex;
  gap: 43px;
  min-height: 700px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  max-width: 1400px;
  margin-top: 20px;
}

.insomnia-group {
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}
.insomnia-image {
  display: block;
  height: 646px;
  position: relative;
  top: 0;
  width: 665px;
}
.insomnia-group-photo {
  z-index: 10;
  justify-content: center;
  display: flex;
}

.content-5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 52px;
  justify-content: center;
  position: relative;
}
.heroWritingSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 50%;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: fit-content;
}

.we-are-software-agency-4,
.frame-265-1,
.frame-265-2,
.frame-265-3,
.frame-265-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.chip-1 {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.line {
  height: 1px;
  min-width: 32px;
  object-fit: cover;
  position: relative;
}

.from-design-and-deve-1 {
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  width: 520px;
}

.button-1 {
  align-items: center;
  background-color: var(--portage);
  border-radius: 12px;
  display: flex;
  gap: 10px;
  padding: 20px 56px;
  position: relative;
  width: fit-content;
  margin-top: 10px;
}

.lets-talk-2,
.view-more {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (max-width: 1360px) {
  .hero-section-1 {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .heroWritingSection {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
  }
  .group-298-1 {
    height: 100%;
    width: 100%;
    min-width: 500px;
  }
}

@media only screen and (max-width: 700px) {
  .hero-section-1 {
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    align-items: center;
    justify-content: center;
  }
  .heroWritingSection {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .group-298-1 {
    height: 100%;
    width: 100%;
    min-width: 500px;
  }
  .insomnia-group {
    width: 100%;
  }
  .insomnia-image {
    height: auto;
    width: 100%;
  }
  .from-design-and-deve-1 {
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    width: 100%;
    font-size: var(--font-size-m) !important;
  }
  .content-5 {
    gap: 30px;
  }
  .lets-talk-2,
  .view-more {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 420px) {
  .heroWritingSection {
    padding-right: 20px;
  }
}
