.cookie-consent-banner {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  border: 2px solid #00d7c3;
  margin: -1px;
  box-shadow: inset 0 0 10px #00d7c3, 0 0 10px 2px #00d7c3;
}

.accept {
  background-color: #00d7c3;
  color: #fff;
  border: none;
  padding: 5px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 12px;
}
.decline {
  background-color: rgba(0, 0, 0, 0.8);
  border: 3px solid #00d7c3;
  margin: -1px;
  box-shadow: inset 0 0 10px #00d7c3, 0 0 10px 2px #00d7c3;
  border-radius: 12px;
  color: #fff;
  border: none;
  padding: 5px 20px;
  margin: 12px;
  cursor: pointer;
  font-size: 14px;
}
