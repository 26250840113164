.vector-right-4 {
  height: 56px;

  position: absolute;
  top: 0;
}
.vectorDivTop {
  width: 100%;
  height: 56px;
  left: 342px;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.vector-right-5 {
  height: 85px;
  position: absolute;
  top: 0px;
}
.vectorDivBottom {
  height: 85px;
  width: 100%;
  left: 327px;
  position: absolute;
  top: 361px;

  overflow: hidden;
}
.animate-top {
  animation: effect-block 2s linear;
}
.animate-bottom {
  animation: effect-block 2s linear;
}

@keyframes effect-block {
  0% {
    width: 0%;
  }
  25% {
    width: 5%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
