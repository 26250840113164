.serviceSectionDiv {
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  height: 500px;
  background-color: var(--white-5);
  border: 2px solid;
  border-color: var(--shark);
  border-radius: 20px;
}
.sectionHeader {
  margin-bottom: 30px;
  letter-spacing: 0;
}
.sectionText {
  line-height: 30px;
}
.spin {
  height: 89px;
  width: 97px;
}
.spinContainer {
  position: absolute;
  height: 89px;
  width: 97px;
  right: 10px;
  top: 10px;
}
.leftSection {
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  padding: 40px 30px;
}
.rightSection {
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 500px;
  display: flex;
  overflow: visible;
  position: relative;
}
.design {
  width: 600px;
  height: 500px;
}
.marketing {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.macbook-canvas {
  top: -20px;
}
.hide-spin {
  display: none;
}
.bullet-icon {
  height: 20px;
  width: auto;
  margin-right: 10px;
  align-self: center;
}
.bullet-section {
  display: flex;
  flex-direction: row;
  width: fit-content;
  position: relative;
}
.li-div {
  width: 400px;
  overflow: hidden;
}
/* .bullet-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  height: 100px;
} */

.bullet-div {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  height: 100px;
  position: relative;
}
.bullet-div li {
  position: relative;
  padding-left: 30px;
}
.bullet-div li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5;
  width: 20px;
  height: 20px;
  background-image: url("../../static/img/Icons/bulletImage.png");
  background-size: cover;
}
@media screen and (max-width: 1050px) {
  .design {
    position: relative;
    right: -10px;
  }
  .rightSection {
    width: 550px;
  }
}
@media screen and (max-width: 1050px) {
  .serviceSectionDiv {
    height: fit-content;
    width: fit-content;
  }
  .rightSection {
    width: 45%;
  }
  .leftSection {
    width: 55%;
  }
  .spinContainer {
    height: 79px;
    width: 87px;
    right: 15px;
  }
  .spin {
    height: 79px;
    width: 87px;
  }
  .design {
    position: relative;
    width: 500px;
    height: 400px;
    right: 40px;
  }
}
@media screen and (max-width: 965px) {
  .design {
    width: 450px;
    height: 350px;
    right: 20px;
  }
}
@media screen and (max-width: 880px) {
  .design {
    width: 400px;
    height: 300px;
    right: 20px;
  }
}
@media screen and (max-width: 805px) {
  .serviceSectionDiv {
    flex-direction: column;
  }
  .rightSection {
    width: 100%;
    height: 400px;
  }
  .leftSection {
    width: 100%;
    margin-top: -25px;
  }
  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }
}

@media screen and (max-width: 700px) {
  .sectionHeader {
    font-size: 24px !important;
  }
  .sectionText {
    font-size: 16px !important;
  }
  .spinContainer {
    height: 69px;
    width: 77px;
    right: 15px;
  }
  .spin {
    height: 69px;
    width: 77px;
  }
}
@media screen and (max-width: 630px) {
  .serviceSectionDiv {
    height: fit-content;
    width: fit-content;
  }
  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }
  .bullet-div {
    height: 190px;
    width: 300px;
    overflow: hidden;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 430px) {
  .serviceSectionDiv {
    height: fit-content;
    width: fit-content;
    /* margin-bottom: 30px; */
  }
  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }
  .bullet-div {
    height: 220px;
    width: 210px;
    overflow: hidden;
    flex-wrap: nowrap;
  }
}
