.burgerMenuDiv {
  display: block;
  /* transform: translate(-100%, 60%); */
  cursor: pointer;
}
.burgerMenu {
  height: 36px;
  width: 35px;
}
.menu-navigation-1 {
  display: none;
}
.buttonDiv {
  display: none;
}
.nav-active {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  color: white;
  text-decoration: underline;
  font-family: "Riviera" !important;
  text-decoration-color: rgb(0, 215, 195);
  text-shadow: rgb(0, 215, 195) 0px 0px 10px inset rgb(0, 215, 195) 0px 0px 20px
    2px;
}
.navDropdown {
  display: none;
  animation: 1s ease;
}
.navDropdown.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1s ease forwards;
  margin-bottom: 35px;
  padding-bottom: 15px;
  background-color: rgba(37, 37, 37, 0.5);
  width: 100vw;
  z-index: 10;
  position: relative;
}
.navbar-1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 16px;
  position: relative;
  width: 100%;
  z-index: 10;
  gap: 30px;
}
.navDropdown.inactive {
  opacity: 1;
  animation: fadeOut 1s ease forwards;
}
.listItem {
  padding-top: 10px;
  font-family: "Riviera" !important;
}
.blurDiv {
  background: #00d7c3;
  filter: blur(150px);
  position: absolute;
  height: 0px;
  width: 0px;
  top: 100px;
  left: 20px;
}
.active-blur {
  animation: blur 0.7s linear;
  background: #00d7c3;
  filter: blur(150px);
  position: absolute;
  height: 200px;
  width: 200px;
  top: 80px;
  left: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes blur {
  0% {
    width: 0px;
  }
  25% {
    width: 75px;
  }
  50% {
    width: 150px;
  }
  100% {
    width: 200px;
  }
}

@media screen and (min-width: 960px) {
  .menu-navigation-1,
  .menu-navigation-2,
  .menu-navigation-3 {
    align-items: center;
    display: flex;
    gap: 40px;
    justify-content: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
  }

  .product-1,
  .features-1,
  .solutions-1,
  .company-1,
  .product-2,
  .features-2,
  .solutions-2,
  .company-2,
  .product-3,
  .features-3,
  .solutions-3,
  .company-3 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: white;
    font-family: "Riviera" !important;
  }

  .frame-50-1,
  .frame-50-2,
  .frame-50-3 {
    align-items: center;
    display: flex;
    gap: 4px;
    position: relative;
    width: fit-content;
  }

  .frame-8,
  .frame-9,
  .frame-10 {
    height: 16px;
    min-width: 16px;
    position: relative;
  }

  .navbar-1 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 40px 80px 16px;
    position: relative;
    width: 100%;
    z-index: 10;
  }
  .burgerMenuDiv {
    display: none;
  }
  .navDropdown {
    display: none;
    animation: 1s ease;
  }
  .listItem {
    padding-top: 10px;
    font-family: "Riviera" !important;
  }
  .buttonDiv {
    display: flex;
    position: relative;
    overflow: visible;

    width: 150px;
  }
  .nav-active .product-1 {
    text-decoration: underline;
    text-decoration-color: rgb(0, 215, 195);
    text-shadow: rgb(0, 215, 195) 0px 0px 10px inset rgb(0, 215, 195) 0px 0px
      20px 2px;
  }

  .nav-active {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: white;
    text-decoration: underline;
    font-family: "Riviera" !important;
    text-decoration-color: rgb(0, 215, 195);
    text-shadow: rgb(0, 215, 195) 0px 0px 10px inset rgb(0, 215, 195) 0px 0px
      20px 2px;
  }
}
