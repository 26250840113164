.frame-15-1-1 {
  align-items: flex-start;
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--shark);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  position: relative;
  width: 389px;
}

.frame-150-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  position: relative;
}

.ellipse-164-5 {
  height: 52px;
  width: 52px;
  object-fit: cover;
  position: relative;
  border-radius: 28px;
}

.frame-149-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 104px;
}

.name {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.ceo-bukalapak-2 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
}

.alpha-team-has-been-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.frame-15-1-1.frame-15-1-2 .ellipse-164-5,
.frame-15-1-1.frame-15-1-3 .ellipse-164-5,
.frame-15-1-1.frame-15-1 .ellipse-164-5 {
  height: 52px;
}
@media screen and (max-width: 700px) {
  .alpha-team-has-been-1 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 450px) {
  .frame-15-1-1 {
    width: 100%;
  }
}
