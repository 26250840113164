.group-29-2-1 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(17, 146, 145, 0) 0%,
    rgba(17, 146, 145, 0.8) 65.9%
  );
  border-radius: 37px/36.75px;
  display: flex;
  height: 73.49px;
  min-width: 74px;
  position: relative;
}

.group-297-2 {
  align-items: flex-start;
  display: flex;
  height: 73.49px;
  min-width: 76px;
}

.overlap-group-14 {
  align-items: flex-start;
  /* background-image: url(/static/img/rectangle-13@2x.png); */
  background-size: 100% 100%;
  display: flex;
  height: 73px;
  justify-content: flex-end;
  margin-top: 0;
  min-width: 74px;
  padding: 22px 29px;
}

.number-7 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 29px;
}
