.logo-5 {
  display: flex;
  height: 79.4px;
  object-fit: cover;
  width: 150px;
}
@media screen and (max-width: 475px) {
  .logo-5 {
    height: 49.4px;
    width: 120px;
  }
}
