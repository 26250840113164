.frame-216 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  gap: 40px;
  position: relative;
  width: 370px !important;
}
.servicesContainer {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
}
.container-overlay {
  position: relative;
  overflow: hidden; /* To avoid overflow issues */
  border-radius: 12px; /* Match image border radius */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 370px;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
  z-index: 1;
}

.container-overlay:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}

.image-14-1 {
  width: 370px;
  height: 395px;
  transition: transform 0.5s ease;
}

.container-overlay:hover .image-14-1 {
  transform: scale(1.1);
  z-index: 0;
}

.description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--font-family-switzer-thin);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
  color: white;
}

.container-overlay:hover .description {
  opacity: 1;
}

.frame-21-2.frame-21 .image-14-1 {
  height: 300px;
}
.frame-212 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white-2);
  border-radius: 12px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 32px 12px;
  position: relative;
}
.frame-21-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  position: relative;
}
.frame-212-1 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white-2);
  color: white;
  font-family: var(--font-family-switzer-regular);
  font-size: 14px;
  border-radius: 12px;
  display: flex;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 18px 10px;
  position: relative;
}
.project-name,
.project-year,
.place-4,
.services-6 {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .frame-216 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    gap: 40px;
    position: relative;
    width: 450px !important;
  }
  .frame-21-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  .container-overlay {
    position: relative;
    overflow: hidden; /* To avoid overflow issues */
    border-radius: 12px; /* Match image border radius */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 450px !important;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    z-index: 1;
  }

  .container-overlay:hover .overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  .image-14-1 {
    width: 450px !important;
    height: 395px;
    transition: transform 0.5s ease;
  }

  .container-overlay:hover .image-14-1 {
    transform: scale(1.1);
    z-index: 0;
  }

  .description {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
    color: white;
  }

  .container-overlay:hover .description {
    opacity: 1;
  }
  .frame-212 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    border-radius: 12px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 32px 12px;
    position: relative;
  }
  .frame-212-1 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    color: white;
    font-family: var(--font-family-switzer-regular);
    font-size: 16px;
    border-radius: 12px;
    display: flex;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 10px 18px 10px;
    position: relative;
  }
  .project-name,
  .project-year,
  .place-4,
  .services-6 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 24px;
    position: relative;
  }
  .frame-21-2.frame-21 .image-14-1 {
    height: 394px;
  }
}
@media screen and (min-width: 992px) {
  .frame-21-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  .frame-216 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    gap: 40px;
    position: relative;
    width: 450px !important;
  }

  /* For overlay */
  .container-overlay {
    position: relative;
    overflow: hidden; /* To avoid overflow issues */
    border-radius: 12px; /* Match image border radius */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 450 !important;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    z-index: 1;
  }

  .container-overlay:hover .overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  .image-14-1 {
    width: 450px !important;
    height: 395px;
    transition: transform 0.5s ease;
    border-radius: 12px;
  }

  .container-overlay:hover .image-14-1 {
    transform: scale(1.1);
    z-index: 0;
  }

  .description {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
    color: white;
  }

  .container-overlay:hover .description {
    opacity: 1;
  }
  .frame-212 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    border-radius: 12px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 32px 12px;
    position: relative;
  }
  .frame-212-1 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    border-radius: 12px;
    color: white;
    font-family: var(--font-family-switzer-regular);
    font-size: 16px;
    display: flex;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 10px 18px 10px;
    position: relative;
  }
  .project-name,
  .project-year,
  .place-4,
  .services-6 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 24px;
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  .frame-21-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }

  .frame-216 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
  }

  .frame-208 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    position: relative;
  }

  .frame-206 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
  }
  .servicesContainer {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 8px;
  }

  .project-name,
  .project-year,
  .place-4,
  .services-6 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .sales-management,
  .number-9 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    position: relative;
    white-space: nowrap;
  }

  .frame-20 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 97px;
  }
  /* For overlay */
  .container-overlay {
    position: relative;
    overflow: hidden; /* To avoid overflow issues */
    border-radius: 12px; /* Match image border radius */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 570px !important;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
    z-index: 1;
  }

  .container-overlay:hover .overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  .image-14-1 {
    width: 570px !important;
    height: 394px;
    transition: transform 0.5s ease;
  }

  .container-overlay:hover .image-14-1 {
    transform: scale(1.1);
    z-index: 0;
  }

  .description {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
    color: white;
  }

  .container-overlay:hover .description {
    opacity: 1;
  }
  .frame-215 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 32px;
    position: relative;
  }

  .frame-213 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 145px;
  }

  .frame-212 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    border-radius: 12px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 32px 12px;
    position: relative;
  }

  .place-5,
  .web-development-4 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-21-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 232px;
  }

  .frame-212-1 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white-2);
    color: white;
    font-family: var(--font-family-switzer-regular);
    font-size: 20px;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 10px 18px 10px;
    position: relative;
  }

  .frame-21-2.frame-21-4 .image-14-1 {
    height: 394px;
  }
}
