/* .purple-blur {
  background-color: #5025bf;
  position: absolute;
  width: 206.12px;
  height: 302.13px;
  right: 75.59px;
  top: -350;
  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.green-blur {
  background-color: #119291;
  position: absolute;
  width: 205.48px;
  height: 219.18px;
  right: 105px;
  top: -100px;
  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.left-blur {
  position: absolute;
  width: 206.48px;
  left: 48.4px;
  top: 175.06px;

  opacity: 0.7;
} */
.purple-blur2 {
  position: absolute;
  width: 200.92px;
  height: 251.78px;
  left: 924.31px;
  top: 1150.22px;

  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.green-blur2 {
  position: absolute;
  width: 493px;
  height: 350px;
  left: 850px;
  top: 990px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.gradient-blur {
  position: absolute;
  width: 1693px;
  height: 494px;
  left: -829px;
  top: 500px;
  display: flex;
  opacity: 0.7;
}

@media screen and (min-width: 368px) {
  /* .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 206.12px;
    height: 302.13px;
    right: 75.59px;
    top: -350;
    background: #5025bf;
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 205.48px;
    height: 219.18px;
    right: 105px;
    top: -100px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 206.48px;
    left: 48.4px;
    top: 175.06px;

    opacity: 0.7;
  } */
  .purple-blur2 {
    position: absolute;
    width: 200.92px;
    height: 251.78px;
    left: 924.31px;
    top: 1150.22px;

    background: #5025bf;
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 493px;
    height: 350px;
    left: 850px;
    top: 990px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 1693px;
    height: 494px;
    left: -829px;
    top: 500px;

    opacity: 0.7;
  }
}
@media screen and (min-width: 768px) {
  .purple-blur2 {
    position: absolute;
    width: 400.92px;
    height: 321.78px;
    left: 950.31px;
    top: 920.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 793px;
    height: 550px;
    left: 850px;
    top: 700px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 1693px;
    height: 494px;
    left: -629px;
    top: 500px;
    display: flex;
    opacity: 0.7;
  }
  .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 406.12px;
    height: 502.13px;
    right: 389.59px;
    top: -350;
    background: #5025bf;
    filter: blur(60px);

    transform: translate3d(0, 0, 0);
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 405.48px;
    height: 300.18px;
    right: 405px;
    top: 20;
    background: #119291;

    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 566.48px;
    left: 108.4px;
    top: 175.06px;

    opacity: 0.7;
  }
}
@media screen and (min-width: 992px) {
  .purple-blur2 {
    position: absolute;
    width: 350.92px;
    height: 321.78px;
    left: 924.31px;
    top: 920.22px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 993px;
    height: 500px;
    left: 800px;
    top: 690px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 993px;
    height: 494px;
    left: -429px;
    top: 500px;

    opacity: 0.7;
  }
  .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 406.12px;
    height: 420.13px;
    right: 489.59px;
    top: -350;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 405.48px;
    height: 219.18px;
    right: 495px;
    top: -40;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 666.48px;
    left: 108.4px;
    top: 175.06px;

    opacity: 0.7;
  }
}
@media screen and (min-width: 1200px) {
  .purple-blur2 {
    position: absolute;
    width: 350.92px;
    height: 321.78px;
    left: 1224.31px;
    top: 920.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 1200px;
    height: 600px;
    left: 850px;
    top: 600px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 1200px;
    height: 494px;
    left: -429px;
    top: 500px;
    display: flex;
    opacity: 0.7;
  }
  .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 426.12px;
    height: 352.13px;
    right: 449.59px;
    top: -290;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 325.48px;
    height: 219.18px;
    right: 495px;
    top: -30;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 666.48px;
    left: 108.4px;
    top: 175.06px;
    display: flex;
    opacity: 0.5;
  }
}
/* @media screen and (min-width: 1280px) {
  .purple-blur2 {
    position: absolute;
    width: 350.92px;
    height: 321.78px;
    left: 1224.31px;
    top: 920.22px;
    display: flex;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 1200px;
    height: 600px;
    left: 850px;
    top: 600px;
    display: flex;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 1200px;
    height: 494px;
    left: -429px;
    top: 500px;
    display: flex;
    opacity: 0.7;
  }
  .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 426.12px;
    height: 352.13px;
    right: 249.59px;
    top: -290;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
    display: flex;
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 325.48px;
    height: 219.18px;
    right: 495px;
    top: -30;
    display: flex;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 666.48px;
    left: 108.4px;
    top: 175.06px;
    display: flex;
    opacity: 0.5;
  }
} */
@media screen and (min-width: 1400px) {
  .purple-blur2 {
    position: absolute;
    width: 650.92px;
    height: 321.78px;
    left: 1124.31px;
    top: 920.22px;

    background: #5025bf;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur2 {
    position: absolute;
    width: 1093px;
    height: 600px;
    left: 950px;
    top: 600px;

    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }
  .gradient-blur {
    position: absolute;
    width: 1693px;
    height: 494px;
    left: -429px;
    top: 500px;
    display: flex;
    opacity: 0.7;
  }
  .purple-blur {
    background-color: #5025bf;
    position: absolute;
    width: 356.12px;
    height: 502.13px;
    right: 399.59px;
    top: -290;
    background: #5025bf;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur {
    background-color: #119291;
    position: absolute;
    width: 425.48px;
    height: 290px;
    right: 90px;
    top: -280;
    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }
  .left-blur {
    position: absolute;
    width: 566.48px;
    left: 108.4px;
    top: 175.06px;

    opacity: 0.8;
  }
}
