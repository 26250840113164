.bullet-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden; /* Hide overflow content */
  white-space: nowrap;
  width: 300px;
}

@media screen and (max-width: 620px) {
  .bullet-text {
    width: 180px;
    white-space: wrap;
  }
}
