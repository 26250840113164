.section-technologies-we-use {
  display: flex;

  height: 737px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  margin-top: 70px;
}

.technologies-we-use-1 {
  display: flex;

  flex-direction: column;
  gap: 52px;
  height: 388px;
  width: 50%;
  /* opacity: 0; */
  position: relative;
  transform: translate(-25px, 0);
  align-items: center;
  justify-content: center;
  top: -20px;
  z-index: 10;
}

.technologies-we-use-1.animate-enter12 {
  animation: animate-enter12-frames 2s ease-in-out 2s 1 normal forwards;
  /* opacity: 0; */
  transform: translate(-25px, 0);
}
.technologiesHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 52px;
}
.line2 {
  width: 350px;
  height: 0.7px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #696969;
}

.headline-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 528px;
}
.Tech {
  flex-direction: row;
  display: flex;
}
.figma {
  width: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-top: 5px;
  margin-left: -26px;
}
.mysql {
  width: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-top: 5px;
  margin-left: -28px;
}

.tech {
  width: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-top: 5px;
  margin-left: -28px;
}
.modal-row {
  padding-top: 5px;
  width: 100%;
}
.modalContainer {
  height: 400px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: rgb(0, 215, 195);
  -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--eerie-black);
}
.last {
  width: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -28px;
}
.stuff-we-geek-out-about-1 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
}

.hexagon-section {
  display: flex;
  height: 80%;
  width: 50%;
  position: relative;
}
.hexagonGroupContainer {
  width: 100%;
  position: relative;
  top: 60px;
}
@media only screen and (max-width: 1200px) {
  .section-technologies-we-use {
    flex-direction: column;
    height: fit-content;
  }
  .hexagonGroupContainer {
    height: 500px;
    width: 100%;
    top: 0px;
  }
  .technologies-we-use-1 {
    height: 600px;
    width: 100%;
  }
  .hexagon-section {
    width: 70%;
  }
}
@media only screen and (max-width: 850px) {
  .hexagon-section {
    width: 80%;
  }
  .line2 {
    width: 350px;
    height: 0.9px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
}

@media only screen and (max-width: 700px) {
  .technologiesHeader {
    gap: 20px !important;
  }
  .headline-body {
    width: 100%;
    gap: 10px;
  }
  .technologies-we-use-1 {
    display: flex;
    height: 450px;
    align-items: start;

    padding-right: 20px;
  }
  .stuff-we-geek-out-about-1 {
    font-size: 32px !important;
    line-height: 40px;
  }

  .section-technologies-we-use {
    padding-left: 20px;
    padding-right: 20px;
  }
  .hexagon-section {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .hexagon-section {
    left: -100px;
  }
  .line2 {
    width: 325px;
    height: 0.8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
}
@media only screen and (max-width: 450px) {
  .hexagon-section {
    left: -150px;
  }
  .line2 {
    width: 315px;
    height: 0.7px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
}
@media only screen and (max-width: 425px) {
  .line2 {
    width: 290px;
    height: 0.7px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
}
@media only screen and (max-width: 410px) {
  .line2 {
    width: 270px;
    height: 0.7px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
}
@media only screen and (max-width: 375px) {
  .line2 {
    width: 255px;
    height: 0.6px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
}
@media only screen and (max-width: 320px) {
  .line2 {
    width: 215px;
    height: 0.6px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgb(0, 215, 195);
    -webkit-box-shadow: inset rgb(0, 215, 195) 0px 0px 10px;
  }
}
