.card-web-developmeent {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white-5);
  border: 3px solid;
  border-color: var(--shark);
  border-radius: 20px;
  cursor: pointer;

  flex-direction: column;
  gap: 32px;

  padding: 40px 24px;
  position: relative;
  box-shadow: 0 0 0px 0 #00d7c3 inset, 0 0 0px 0px #00d7c3;
  transition: border 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
    scale 0.5s ease-in-out;
  width: 230px;
  box-sizing: content-box;
}

.card-web-developmeent:hover {
  transform: scale(1.1) !important;
  padding: 40px 24px;
  box-shadow: 0 0 10px 1px #00d7c3 inset, 0 0 20px 5px #00d7c3;
  border: 3px solid #00d7c3;
}

.icon-desktop-1 {
  height: 40px;
  min-width: 40px;
  position: relative;
}

@media screen and (max-width: 1290px) {
  .card-web-developmeent {
    width: 50%;
  }
}
@media screen and (max-width: 1055px) {
  .card-web-developmeent {
    width: 100%;

    flex-grow: 1;
  }
}
@media screen and (max-width: 700px) {
  .card-web-developmeent {
    gap: 5px;
  }
}
