.modal {
  /* Hide the modal by default */
  position: absolute; /* Stay in place */
  z-index: 1040; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  
}

/* Modal Content */
.modal-content {
  display: flex;
  background-color: #fefefe;
  margin: 8% auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 80%;
  width: fit-content;
  align-items: flex-start;
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--shark);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  position: relative;
  width: 389px;
}

.reviewModalHeading {
  margin-bottom: 20px;
}

/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #00d7c3;
  text-decoration: none;
  cursor: pointer;
}
