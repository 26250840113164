.not-found-screen {
  background-color: var(--black);
  border: 1px none;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found-container {
  min-height: 500px;
  height: auto;
  z-index: 10;
  max-width: 1200px;
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
