services-3 {
  align-items: flex-start;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  position: relative;
}

.section-our-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100%;
  position: relative;
  margin-bottom: 100px;
}
.line-1-2 {
  min-width: 32px;
  object-fit: cover;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.projects-2 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.overlap-group1-3 {
  height: 146px;
  position: relative;
  width: 145px;
}

.overlap-group-15 {
  height: 146px;
  left: 0;
  position: absolute;
  top: 0;
  width: 145px;
}

.services-5 {
  align-items: center;
  display: flex;
  gap: 40px;
  height: 356px;
  margin-top: 112px;
  position: relative;
  width: 350px;
}

.project {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.selected-project {
  align-items: center;
  display: flex;
  gap: 40px;
  position: relative;
  width: fit-content;
}

.number-8 {
  letter-spacing: 0;
  line-height: 74px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.selected-project-1 {
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  width: 89px;
}

.title-21 {
  align-self: center !important;
  flex-direction: row;
  margin-top: 60px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 355px;
}

.chip-6 {
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.see-projects-that-we {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 50px;
  position: relative;
  font-size: 32px !important;
}

.frame-224 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.vector-22-7 {
  height: 2px;
  min-width: 992px;
  position: relative;
}

.vector-23 {
  height: 2px;
  min-width: 992px;
  position: relative;
}
.frame-208 {
  margin-left: 10px;
}
@media screen and (min-width: 400px) {
  .title-21 {
    width: 375px;
  }
}
@media screen and (min-width: 768px) {
  services-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    position: relative;
    margin-bottom: 100px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .overlap-group1-3 {
    height: 146px;
    position: relative;
    width: 145px;
  }

  .overlap-group-15 {
    height: 146px;
    left: 0;
    position: absolute;
    top: 0;
    width: 145px;
  }

  .services-5 {
    align-items: center;
    display: flex;
    gap: 40px;
    height: 356px;
    margin-top: 112px;
    position: relative;
    width: 700px;
  }

  .project {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 140px;
    height: 100%;
    justify-content: center;

    position: relative;
    width: 700px;
  }

  .selected-project {
    align-items: center;
    display: flex;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .number-8 {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .selected-project-1 {
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 89px;
  }

  .title-21 {
    align-self: flex-start;
    flex-direction: row;
    gap: 20px;
    margin-top: -150px;
    opacity: 0;
    position: relative;
    transform: translate(25px, 0);
    width: 680px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-projects-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: 52 !important;
  }

  .frame-224 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: -60;
    position: relative;
    width: fit-content;
  }

  .vector-22-7 {
    height: 2px;
    min-width: 992px;
    position: relative;
  }

  .vector-23 {
    height: 2px;
    min-width: 992px;
    position: relative;
  }
  .title-21 {
    align-self: center;
    flex-direction: row;
    gap: 50px;
    opacity: 0;
    position: relative;
    margin-top: 100px;
    transform: translate(25px, 0);
    width: 450px;
    margin-right: 0px;
  }
}

@media screen and (min-width: 992px) {
  services-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    position: relative;
    margin-bottom: 100px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .overlap-group1-3 {
    height: 146px;
    position: relative;
    width: 145px;
  }

  .overlap-group-15 {
    height: 146px;
    left: 0;
    position: absolute;
    top: 0;
    width: 145px;
  }

  .services-5 {
    align-items: center;
    display: flex;
    gap: 40px;
    height: 356px;
    margin-top: 112px;
    position: relative;
    width: 900px;
  }

  .project {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 140px;
    height: 100%;
    justify-content: center;

    position: relative;
    width: 900px;
  }

  .selected-project {
    align-items: center;
    display: flex;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .number-8 {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .selected-project-1 {
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 89px;
  }

  .title-21 {
    align-self: flex-start;
    flex-direction: row;
    gap: 20px;
    opacity: 0;
    position: relative;
    margin-top: 100px;
    transform: translate(25px, 0);
    width: 100%;
    margin-right: 0px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-projects-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }

  .frame-224 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
    width: fit-content;
  }

  .vector-22-7 {
    height: 2px;
    min-width: 992px;
    position: relative;
  }

  .vector-23 {
    height: 2px;
    min-width: 992px;
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  services-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    position: relative;
    margin-bottom: 0px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .overlap-group1-3 {
    height: 146px;
    position: relative;
    width: 145px;
  }

  .overlap-group-15 {
    height: 146px;
    left: 0;
    position: absolute;
    top: 0;
    width: 145px;
  }

  .services-5 {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 356px;
    margin-top: 112px;
    position: relative;
    width: 1216px !important;
  }

  .project {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 140px;
    height: 2624px;
    justify-content: center;

    position: relative;
    width: 1183px !important;
  }

  .selected-project {
    align-items: center;
    display: flex;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .frame-205 {
    align-items: center;
    display: flex;
    gap: 12px;
    position: relative;
    width: fit-content;
  }

  .number-8 {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .selected-project-1 {
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 89px;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    position: relative;
    width: 100%;
    margin-top: -200px;
    margin-right: -200px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-projects-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    font-size: 52px !important;
  }

  .frame-224 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
    width: fit-content;
  }

  .vector-22-7 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }

  .vector-23 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  services-3 {
    align-items: flex-start;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: relative;
  }

  .section-our-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    position: relative;
    margin-bottom: 100px;
    margin-top: 75px;
  }
  .line-1-2 {
    min-width: 32px;
    object-fit: cover;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .overlap-group1-3 {
    height: 146px;
    position: relative;
    width: 145px;
  }

  .overlap-group-15 {
    height: 146px;
    left: 0;
    position: absolute;
    top: 0;
    width: 145px;
  }

  .services-5 {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 356px;
    margin-top: 112px;
    position: relative;
    width: 1216px !important;
  }

  .project {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 140px;
    height: 2624px;
    justify-content: center;

    position: relative;
    width: 1183px !important;
  }

  .selected-project {
    align-items: center;
    display: flex;
    gap: 40px;
    position: relative;
    width: fit-content;
  }

  .frame-205 {
    align-items: center;
    display: flex;
    gap: 12px;
    position: relative;
    width: fit-content;
  }

  .number-8 {
    letter-spacing: 0;
    line-height: 74px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .selected-project-1 {
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 89px;
  }

  .title-21 {
    align-self: flex-start;
    flex-direction: row;
    gap: 20px;
    opacity: 0;
    position: relative;
    transform: translate(25px, 0);
    width: 100% !important;
    margin-top: -200px;
    margin-right: 0px;
  }

  .chip-6 {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .see-projects-that-we {
    align-self: flex-start;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
  }

  .frame-224 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
    width: fit-content;
  }

  .vector-22-7 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }

  .vector-23 {
    height: 2px;
    min-width: 1183px;
    position: relative;
  }

  @keyframes animate-enter23-frames {
    from {
      opacity: 0;
      transform: translate(-25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter29-frames {
    from {
      opacity: 0;
      transform: translate(25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes animate-enter24-frames {
    from {
      opacity: 0;
      transform: translate(25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
