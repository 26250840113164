.section-testimonial-1 {
  display: flex;
  gap: 44px;
  height: 797px;
  max-width: 1500px;
  position: relative;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.frame-147 {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 52px;

  justify-content: center;
  /* opacity: 0; */
  position: relative;
  transform: translate(-25px, 0);
  width: 40%;
}

.frame-147.animate-enter13 {
  animation: animate-enter13-frames 2s ease-in-out 2s 1 normal forwards;
  /* opacity: 0; */
  transform: translate(-25px, 0);
}

.testimonial {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 80%;
}

.testimonial-1 {
  align-items: flex-start;
  display: flex;
  height: 605px;
  margin-top: 96px;
  overflow: hidden;

  width: 60%;
}

.overlap-group-13 {
  height: auto;
  margin-top: -167px;
  position: relative;

  flex-direction: row;
}

.rectangle-174 {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 64.58%
  );
  height: 561px;
  left: 0;
  position: absolute;
  top: 167px;
  width: 810px;
}
.scrollGroup {
  gap: 28px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 28px;
}
.scroll-animation {
  animation: animate-scrollY 40s linear infinite;
}
.scroll-animation-mobile {
  animation: animate-scrollY 70s linear infinite;
}

.leftScrollColumn {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  width: fit-content;
}

.rightScrollColumn {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  left: 421px;
  position: absolute;
  width: fit-content;
}

.rectangle-173 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 80.91%);
  height: 62px;
  left: 0px;
  position: absolute;
  top: 166px;
  width: 810px;
}

.rectangle-175-1 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 80.91%);
  height: 80px;
  left: 0px;
  position: absolute;
  top: 692px;
  width: 810px;
}
.chip-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

@keyframes animate-scrollY {
  from {
    left: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@media only screen and (max-width: 1420px) {
  .section-testimonial-1 {
    gap: 20px;
  }
  .frame-147 {
    width: 30%;
  }
  .testimonial-1 {
    width: 70%;
  }
}
@media only screen and (max-width: 1200px) {
  .section-testimonial-1 {
    flex-direction: column;
    margin-bottom: 20px;
    height: fit-content;
    margin-top: 60px;
  }
  .testimonial {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .testimonial-1,
  .frame-147 {
    width: 100%;
    justify-content: center;
  }
  .leftScrollColumn {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    position: relative;
    width: fit-content;
  }

  .rightScrollColumn {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 20px;
    position: relative;
    width: fit-content;
  }
  .overlap-group-13 {
    display: flex;
  }
  .chip-22 {
    align-self: center;
  }
  .what-our-lovely-clients-say-1 {
    align-self: center !important;
  }
}
@media only screen and (max-width: 950px) {
  .leftScrollColumn {
    left: -10px;
  }
  .rightScrollColumn {
    left: 10px;
  }
  .rectangle-173 {
    left: -10px;
  }
  .rectangle-175-1 {
    left: -10px;
  }
}
@media only screen and (max-width: 810px) {
  .rectangle-173 {
    left: -100px;
  }
  .rectangle-175-1 {
    left: -100px;
  }
  .leftScrollColumn {
    align-self: center;
    left: 0;
    /* top: 500px; */
  }
}
@media only screen and (max-width: 700px) {
  .testimonial {
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
  }
  .chip-22 {
    align-self: flex-start;
  }
  .what-our-lovely-clients-say-1 {
    align-self: flex-start !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .section-testimonial-1 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -30px;
  }
  .testimonial-1 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 390px) and (-webkit-device-pixel-ratio: 3) {
  .testimonial {
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
  }
  .chip-22 {
    align-self: flex-start;
  }
  .what-our-lovely-clients-say-1 {
    align-self: flex-start !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .section-testimonial-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .testimonial-1 {
    margin-top: 40px;
  }
}
