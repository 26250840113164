.footer-1 {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--black);
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-left: 30px;
  padding-right: 30px;
}
.menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.our-email {
  margin-top: 10px;
  margin-bottom: 10px;
}
.logo-3 {
  height: 76.8px;
  background-size: contain;
  width: 115px;
  background-image: url(../../static/img/AlphaLogo.png);
  margin-right: 20px;
}
.wmc-div {
  height: 76.8px;
  width: 100px;
}
.wmc-image {
  width: auto;
  height: 76.8px;
}
.logoContainer {
  flex-direction: row;
  display: flex;
}
.footerList {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  flex-direction: row;
}
.footerListItem {
  color: var(--silver);
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}
.tel {
  text-decoration: none;
  font-size: 18px !important;
}
.bottomDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.vector-22-2 {
  align-self: stretch;
  height: 2px;
  width: 100%;
  position: relative;
}
.copyright {
  width: 100%;
}
.gradients {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}
.services-2 {
  -webkit-text-stroke: 2px var(--white);
  letter-spacing: 0;
  line-height: 28px;
  margin-left: -2px;
  margin-top: -3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.address-1,
.terms-condition-1,
.privacy-policy {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: var(--silver);
  text-decoration: none;
}
.no-decoration {
  text-decoration: none;
  z-index: 10;
}
.termsDiv {
  /* align-items: flex-end; */
  display: flex;
  gap: 40px;
  position: relative;
}
.bottom-blur {
  position: absolute;
  width: 1269px;
  height: 601px;
  top: 641px;
  opacity: 0.2;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.purple-bottom {
  position: absolute;
  width: 93%;
  height: 621.78px;
  top: 359.22px;
  display: flex;
  opacity: 0.2;

  background: #5025bf;
  filter: blur(70px);
}
.green-bottom {
  position: absolute;
  width: 93%;
  height: 504.19px;
  top: 335px;

  left: 10px;
  opacity: 0.3;
  background: #119291;
  filter: blur(70px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
@media screen and (max-width: 1800px) {
  .bottom-blur {
    position: absolute;
    width: 1269px;
    height: 601px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 241px;

    opacity: 0.3;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .purple-bottom {
    position: absolute;
    width: 95%;
    height: 621.78px;

    top: 360.22px;
    opacity: 0.1;
    background: #5025bf;
    filter: blur(60px);
  }
  .green-bottom {
    position: absolute;
    width: 100%;
    height: 504.19px;
    top: 291px;
    left: -20px;
    display: flex;

    background: #119291;

    opacity: 0.24;
    filter: blur(70px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media screen and (max-width: 1200px) {
  .bottom-blur {
    position: absolute;
    width: 1269px;
    height: 601px;
    left: 1328px;
    top: 901px;

    opacity: 0.9;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .purple-bottom {
    position: absolute;
    width: 960.92px;
    height: 221.78px;
    left: -40.31px;
    top: 320.22px;
    opacity: 0.1;
    background: #5025bf;
    filter: blur(70px);
  }
  .green-bottom {
    position: absolute;
    width: 1269px;
    height: 204.19px;
    left: -40px;
    top: 260px;
    opacity: 0.24;
    background: #119291;
    filter: blur(70px);
  }
}
@media screen and (max-width: 852px) {
  .menu {
    flex-direction: column;
  }
  .footerList {
    justify-content: center;
    width: 100%;
  }
  .logoDiv {
    width: 100%;
    align-self: flex-start;
    margin-bottom: 30px;
  }
  .logoContainer {
    justify-content: flex-start;
  }
  .footer-1 {
    height: 400px;
  }
  .bottomDiv {
    flex-direction: column-reverse;
  }
  .addressDiv {
    align-self: center;
    margin-top: 20px;
  }
  .termsDiv {
    justify-content: space-between;
  }
  .bottom-blur {
    position: absolute;
    width: 1269px;
    height: 601px;
    left: 800px;
    top: 901px;

    opacity: 0.2;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .purple-bottom {
    position: absolute;
    width: 960.92px;
    height: 221.78px;
    left: 0px;
    top: 540.22px;

    background: #5025bf;
    filter: blur(70px);
  }
  .green-bottom {
    position: absolute;
    width: 1269px;
    height: 204.19px;
    left: -20px;
    top: 320px;
    opacity: 0.24;
    background: #119291;
    filter: blur(70px);
  }
}
@media screen and (max-width: 700px) {
  .footerListItem,
  .address-1,
  .terms-condition-1,
  .privacy-policy {
    font-size: 12px;
  }
  .our-email {
    font-size: 12px !important;
  }
  .logo-3 {
    width: 48px;
    height: 32px;
    margin-right: 8px;
  }
  .wmc-div {
    width: 48px;
    height: 32px;
    justify-content: flex-start;
    display: flex;
  }
  .wmc-image {
    width: auto;
    height: 40px;
  }
  .footer-1 {
    height: 276px;
    gap: 15px;
  }
}
@media screen and (max-width: 699px) {
  .bottom-blur {
    position: absolute;
    width: 1269px;
    height: 601px;
    left: 800px;
    top: 201px;

    opacity: 0.6;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .purple-bottom {
    position: absolute;
    width: 150px;
    height: 204.78px;
    left: 100px;
    top: 295px;
    background: #5025bf;
    filter: blur(70px);
  }
  .green-bottom {
    position: absolute;
    width: 700px;
    height: 204.19px;
    left: -100px;
    top: 200px;
    opacity: 0.24;
    background: #119291;
    filter: blur(70px);
  }
  .tel {
    text-decoration: none;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 450px) {
  .footerList {
    gap: 10px;
  }
}
