@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Space+Grotesk:400,700,500|Space+Mono:400,700|Inter:500";
body::-webkit-scrollbar {
  display: none;
}

body {
  scrollbar-width: none;
  contain: paint;
  width: 100%;
  height: 100%;
}

.home-page-option-1 {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.geo-top-home {
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("HomeGeoTop.0500d94e.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 497px;
  height: 471.89px;
  position: absolute;
  top: 0;
  left: 0;
}

.geo-home-right {
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("HomeGeoBottom.0fc7a18b.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 717.39px;
  height: 681.14px;
  position: absolute;
  top: 200px;
}

.geo-bottom-right {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  z-index: 5;
  background-image: url("HomeRightGeo2.6cf77192.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 997px;
  height: 871.89px;
  position: absolute;
  top: -90px;
}

.home-top {
  opacity: .9;
  width: 1135.59px;
  height: 691.75px;
  display: flex;
  position: absolute;
  top: -100px;
  left: 2397.45px;
  transform: matrix(-.87, .5, .5, .87, 0, 0);
}

.purple-blur-top {
  filter: blur(70px);
  background: #5025bf;
  width: 706.12px;
  height: 502.13px;
  position: absolute;
  top: -1455px;
  left: 2850.7px;
  transform: translate3d(0, 0, 0);
}

.green-blur-top {
  filter: blur(70px);
  background: #119291;
  width: 852.95px;
  height: 350.6px;
  position: absolute;
  top: -1330px;
  left: 2050.7px;
  transform: translate3d(0, 0, 0);
}

.home-purple {
  filter: blur(70px);
  background: #5025bf;
  width: 949.9px;
  height: 350.09px;
  display: flex;
  position: absolute;
  top: 82.62px;
  left: 1482.7px;
  transform: translate3d(0, 0, 0);
}

.home-green {
  filter: blur(70px);
  background: #119291;
  width: 900.59px;
  height: 257.61px;
  position: absolute;
  top: -80px;
  left: 1600.45px;
  transform: translate3d(0, 0, 0);
}

.home-right {
  opacity: .7;
  width: 1135.59px;
  height: 691.75px;
  display: flex;
  position: absolute;
  top: -350px;
  left: 1300px;
  transform: matrix(-.87, .5, .5, .87, 0, 0);
}

.home-left {
  opacity: .7;
  width: 652px;
  height: 401px;
  display: flex;
  position: absolute;
  top: -50px;
  left: 790px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.home-purple2 {
  filter: blur(70px);
  background: #5025bf;
  width: 529px;
  height: 330.78px;
  position: absolute;
  top: 400.22px;
  left: 875.18px;
  transform: matrix(-.53, -.85, -.85, .53, 0, 0);
}

.home-green2 {
  filter: blur(70px);
  background: #119291;
  width: 552px;
  height: 325.19px;
  position: absolute;
  top: 100px;
  left: 875px;
  transform: translate3d(0, 0, 0);
}

.home-right2 {
  opacity: .7;
  width: 752px;
  height: 601px;
  display: flex;
  position: absolute;
  top: -170px;
  left: 1400px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.home-purple3 {
  filter: blur(70px);
  background: #5025bf;
  width: 540.43px;
  height: 250.78px;
  position: absolute;
  top: 380.22px;
  left: 1550.18px;
  transform: translate3d(0, 0, 0);
}

.home-green3 {
  filter: blur(70px);
  background: #119291;
  width: 520px;
  height: 250px;
  position: absolute;
  top: 180px;
  left: 1592px;
  transform: translate3d(0, 0, 0);
}

.we-are-a-digital-agency, .what-we-offer-1, .technologies-we-use-2, .testimonials-1 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.frame-92 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 519px;
  height: 272px;
  display: flex;
  position: relative;
}

.discovery-lets-chat-1 {
  width: 405px;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
}

.span0-1 {
  letter-spacing: 1.5px;
}

.span-2 {
  letter-spacing: 0;
}

.strategy-we-make-th {
  color: #0000;
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  width: 405px;
  margin-top: -1px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.span0-2 {
  color: #119291;
  letter-spacing: 1.5px;
  font-weight: 700;
}

.x4 {
  align-items: center;
  gap: 16px;
  width: 477px;
  height: 72px;
  margin-top: 32px;
  display: flex;
  position: relative;
}

.testing-qa-we-test {
  letter-spacing: 0;
  width: 405px;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
}

@media screen and (width <= 1450px) {
  .home-left {
    left: 990px;
  }

  .geo-bottom-right {
    left: 1000px;
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 529px;
    height: 330.78px;
    position: absolute;
    top: 400.22px;
    left: 1025.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 552px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 1025px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1360px) {
  .geo-bottom-right {
    left: 900px;
  }

  .geo-home-right {
    top: 650px;
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 529px;
    height: 330.78px;
    position: absolute;
    top: 400.22px;
    left: 1025.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 552px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 1025px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1280px) {
  .home-left {
    left: 990px;
  }

  .geo-bottom-right {
    top: -3000px;
    left: 700px;
  }

  .home-top {
    opacity: .7;
    width: 935.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(60px);
    background: #5025bf;
    width: 706.12px;
    height: 502.13px;
    position: absolute;
    top: -1385px;
    left: 2700.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(60px);
    background: #119291;
    width: 822.95px;
    height: 350.6px;
    position: absolute;
    top: -1330px;
    left: 2000.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 529px;
    height: 330.78px;
    position: absolute;
    top: 440.22px;
    left: 1185px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 552px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 1200px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(60px);
    background: #5025bf;
    width: 949.9px;
    height: 300.09px;
    display: flex;
    position: absolute;
    top: 52.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(60px);
    background: #119291;
    width: 1000.59px;
    height: 257.61px;
    position: absolute;
    top: -110px;
    left: 1570.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .9;
    width: 1135.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -400px;
    left: 1400px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .9;
    width: 652px;
    height: 401px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 1100px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (width <= 1200px) {
  .home-left {
    left: 990px;
  }

  .geo-bottom-right {
    top: -3000px;
    left: 700px;
  }

  .home-top {
    opacity: .7;
    width: 935.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(60px);
    background: #5025bf;
    width: 706.12px;
    height: 502.13px;
    position: absolute;
    top: -1385px;
    left: 2700.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(70px);
    background: #119291;
    width: 822.95px;
    height: 350.6px;
    position: absolute;
    top: -1330px;
    left: 2000.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 529px;
    height: 330.78px;
    position: absolute;
    top: 400.22px;
    left: 975.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 552px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 975px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 949.9px;
    height: 300.09px;
    display: flex;
    position: absolute;
    top: 52.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(90px);
    background: #119291;
    width: 1000.59px;
    height: 257.61px;
    position: absolute;
    top: -110px;
    left: 1570.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .9;
    width: 1135.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -400px;
    left: 1400px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .9;
    width: 652px;
    height: 401px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 1100px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (width <= 1050px) {
  .geo-bottom-right {
    top: -3000px;
    left: 500px;
  }

  .home-top {
    opacity: .7;
    width: 635.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -50px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(70px);
    background: #5025bf;
    width: 506.12px;
    height: 502.13px;
    position: absolute;
    top: -1405px;
    left: 2480.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(70px);
    background: #119291;
    width: 852.95px;
    height: 300.6px;
    position: absolute;
    top: -1330px;
    left: 1650.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 949.9px;
    height: 280.09px;
    display: flex;
    position: absolute;
    top: 52.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(90px);
    background: #119291;
    width: 900.59px;
    height: 227.61px;
    position: absolute;
    top: -80px;
    left: 1550.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .7;
    width: 1135.59px;
    height: 491.75px;
    display: flex;
    position: absolute;
    top: -350px;
    left: 1200px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .7;
    width: 652px;
    height: 401px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 1050px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (width <= 850px) {
  .geo-bottom-right {
    top: -3000px;
    left: 400px;
  }

  .home-top {
    opacity: .7;
    width: 435.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -50px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(75px);
    background: #5025bf;
    width: 306.12px;
    height: 502.13px;
    position: absolute;
    top: -1355px;
    left: 2280.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(70px);
    background: #119291;
    width: 452.95px;
    height: 300.6px;
    position: absolute;
    top: -1300px;
    left: 1850.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 900.9px;
    height: 180.09px;
    display: flex;
    position: absolute;
    top: 92.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(90px);
    background: #119291;
    width: 850.59px;
    height: 180.61px;
    position: absolute;
    top: -120px;
    left: 1450.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .7;
    width: 600.59px;
    height: 491.75px;
    display: flex;
    position: absolute;
    top: -390px;
    left: 1500px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .7;
    width: 652px;
    height: 401px;
    display: flex;
    position: absolute;
    top: -100px;
    left: 1000px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (width <= 820px) {
  .geo-home-right {
    left: 250px;
  }

  .geo-top-home {
    width: 250px;
  }
}

@media screen and (width <= 700px) {
  .we-are-a-digital-agency, .what-we-offer-1, .technologies-we-use-2, .testimonials-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
    font-size: 14px !important;
  }

  .geo-home-right {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 797px;
    height: 571.89px;
    top: 400px;
  }

  .home-right {
    top: -600px;
    left: 1200px;
  }

  .home-left {
    top: -250px;
    left: 800px;
  }

  .geo-bottom-right {
    top: -2500px;
    left: 200px;
  }

  .home-top {
    opacity: .7;
    width: 435.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -50px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(60px);
    background: #5025bf;
    width: 306.12px;
    height: 502.13px;
    position: absolute;
    top: -1485px;
    left: 2280.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(60px);
    background: #119291;
    width: 292.95px;
    height: 200.6px;
    position: absolute;
    top: -1230px;
    left: 2000.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 300.9px;
    height: 230.09px;
    display: flex;
    position: absolute;
    top: 52.62px;
    left: 1482.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(70px);
    background: #119291;
    width: 630.59px;
    height: 110.61px;
    position: absolute;
    top: -20px;
    left: 1350.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .7;
    width: 600.59px;
    height: 491.75px;
    display: flex;
    position: absolute;
    top: -500px;
    left: 1400px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .7;
    width: 300px;
    height: 301px;
    display: flex;
    position: absolute;
    top: -180px;
    left: 1200px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 500px;
    height: 330.78px;
    position: absolute;
    top: 400.22px;
    left: 975.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 500px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 975px;
    transform: translate3d(0, 0, 0);
  }

  .home-right2 {
    opacity: .7;
    width: 352px;
    height: 201px;
    display: flex;
    position: absolute;
    top: -250px;
    left: 1700px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-purple3 {
    filter: blur(70px);
    background: #5025bf;
    width: 369.43px;
    height: 280.78px;
    position: absolute;
    top: 301.22px;
    left: 1520.18px;
    transform: translate3d(0, 0, 0);
  }

  .home-green3 {
    filter: blur(70px);
    background: #119291;
    width: 352px;
    height: 300.19px;
    position: absolute;
    top: 120px;
    left: 1572px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 500px) {
  .geo-bottom-right {
    top: -2500px;
    left: 100px;
  }

  .home-right2 {
    top: -250px;
  }

  .home-top {
    opacity: .9;
    width: 435.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -50px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(60px);
    background: #5025bf;
    width: 306.12px;
    height: 502.13px;
    position: absolute;
    top: -1445px;
    left: 2280.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(60px);
    background: #119291;
    width: 298.95px;
    height: 200.6px;
    position: absolute;
    top: -1230px;
    left: 2000.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 300.9px;
    height: 220.09px;
    display: flex;
    position: absolute;
    top: 52.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(90px);
    background: #119291;
    width: 630.59px;
    height: 140.61px;
    position: absolute;
    top: -80px;
    left: 1150.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .7;
    width: 600.59px;
    height: 491.75px;
    display: flex;
    position: absolute;
    top: -400px;
    left: 1200px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .7;
    width: 200px;
    height: 201px;
    display: flex;
    position: absolute;
    top: -180px;
    left: 1200px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-right2 {
    opacity: .7;
    width: 352px;
    height: 201px;
    display: flex;
    position: absolute;
    top: -250px;
    left: 1700px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-purple3 {
    filter: blur(60px);
    background: #5025bf;
    width: 369.43px;
    height: 150.78px;
    position: absolute;
    top: 391.22px;
    left: 1520.18px;
    transform: translate3d(0, 0, 0);
  }

  .home-green3 {
    filter: blur(70px);
    background: #119291;
    width: 352px;
    height: 250.19px;
    position: absolute;
    top: 150px;
    left: 1572px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 400px;
    height: 330.78px;
    position: absolute;
    top: 400.22px;
    left: 975.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 402px;
    height: 325.19px;
    position: absolute;
    top: 100px;
    left: 975px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 450px) {
  .geo-bottom-right {
    width: 697px;
    height: 571.89px;
    top: -2500px;
    left: 100px;
  }

  .geo-top-home {
    width: 187px;
    height: 341.89px;
  }

  .home-right {
    width: 900px;
    height: 200px;
    top: -700px;
    left: 1100px;
  }

  .geo-home-right {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 697px;
    height: 371.89px;
    top: 580px;
  }

  .home-top {
    opacity: .7;
    width: 435.59px;
    height: 691.75px;
    display: flex;
    position: absolute;
    top: -50px;
    left: 2397.45px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .purple-blur-top {
    filter: blur(60px);
    background: #5025bf;
    width: 306.12px;
    height: 452.13px;
    position: absolute;
    top: -1445px;
    left: 2200.7px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur-top {
    filter: blur(60px);
    background: #119291;
    width: 292.95px;
    height: 200.6px;
    position: absolute;
    top: -1230px;
    left: 2000.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    filter: blur(60px);
    background: #5025bf;
    width: 300.9px;
    height: 175.09px;
    display: flex;
    position: absolute;
    top: -20.62px;
    left: 1382.7px;
    transform: translate3d(0, 0, 0);
  }

  .home-green {
    filter: blur(70px);
    background: #119291;
    width: 350.59px;
    height: 150.61px;
    position: absolute;
    top: -109px;
    left: 1360.45px;
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    opacity: .7;
    width: 600.59px;
    height: 491.75px;
    display: flex;
    position: absolute;
    top: -440px;
    left: 1200px;
    transform: matrix(-.87, .5, .5, .87, 0, 0);
  }

  .home-left {
    opacity: .7;
    width: 200px;
    height: 201px;
    display: flex;
    position: absolute;
    top: -180px;
    left: 1200px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-right2 {
    opacity: .7;
    width: 352px;
    height: 201px;
    display: flex;
    position: absolute;
    top: -250px;
    left: 1700px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .home-purple3 {
    filter: blur(60px);
    background: #5025bf;
    width: 369.43px;
    height: 250.78px;
    position: absolute;
    top: 331.22px;
    left: 1520.18px;
    transform: translate3d(0, 0, 0);
  }

  .home-green3 {
    filter: blur(70px);
    background: #119291;
    width: 352px;
    height: 280.19px;
    position: absolute;
    top: 150px;
    left: 1572px;
    transform: translate3d(0, 0, 0);
  }

  .home-purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 400px;
    height: 280.78px;
    position: absolute;
    top: 400.22px;
    left: 995.18px;
    transform: matrix(-.53, -.85, -.85, .53, 0, 0);
  }

  .home-green2 {
    filter: blur(70px);
    background: #119291;
    width: 452px;
    height: 405.19px;
    position: absolute;
    top: 180px;
    left: 955px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1055px) {
  .services-1 {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .section-services-1, .section-how-we-work-1 {
    height: auto;
  }
}

.marquee {
  white-space: nowrap;
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  letter-spacing: 0;
  justify-content: flex-start;
  width: 60%;
  margin-bottom: 12px;
  font-size: 52px;
  font-weight: 400;
  line-height: 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.marquee h2 {
  padding-left: 15px;
  font-family: Space;
  animation: 15s linear infinite marquee;
  display: inline-block;
  font-weight: normal !important;
}

@keyframes marquee {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.mainHeading {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  letter-spacing: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 52px;
  font-weight: 700;
  line-height: 70px;
  display: flex;
  position: relative;
}

.bottomJumbotron {
  background-color: var(--black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 414px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-1 {
  background-color: var(--portage);
  border-radius: 12px;
  align-items: center;
  gap: 10px;
  padding: 20px 56px;
  display: flex;
  position: relative;
}

.smallText {
  letter-spacing: 0;
  flex-wrap: wrap;
  margin-bottom: 30px;
  line-height: 24px;
  position: relative;
}

.view-more {
  letter-spacing: 0;
  white-space: nowrap;
  flex-direction: row;
  margin-top: -1.5px;
  line-height: 24px;
  position: relative;
}

.left-gradient {
  opacity: .7;
  align-content: flex-start;
  width: 100%;
  height: 414px;
  position: absolute;
  left: -457px;
}

.right-gradient {
  opacity: .7;
  align-content: flex-end;
  width: 100%;
  height: 414px;
  position: absolute;
  left: 1807px;
}

.purple {
  filter: blur(60px);
  background: #5025bf;
  width: 694px;
  height: 374px;
  position: absolute;
  top: 200px;
  transform: translate3d(0, 0, 0);
}

.green {
  filter: blur(60px);
  background: #119291;
  width: 659px;
  height: 414px;
  position: absolute;
  top: -160px;
  transform: translate3d(0, 0, 0);
}

.purple2 {
  filter: blur(60px);
  background: #5025bf;
  width: 694px;
  height: 374px;
  position: absolute;
  top: 200px;
  left: -100px;
  transform: translate3d(0, 0, 0);
}

.green2 {
  filter: blur(60px);
  background: #119291;
  width: 519px;
  height: 314px;
  position: absolute;
  top: -100px;
  left: -100px;
  transform: translate3d(0, 0, 0);
}

.geomtris-jumbo-right {
  opacity: .52;
  background-image: url("JumboRight.65d53986.png");
  background-repeat: no-repeat;
  width: 497px;
  height: 471.89px;
  position: absolute;
  top: 20px;
  right: -42px;
}

.geomtris-left {
  opacity: .52;
  background-image: url("JumboLeft.c43a9255.png");
  background-repeat: no-repeat;
  width: 497px;
  height: 471.89px;
  position: absolute;
  top: -1px;
  left: 0;
}

@media screen and (width <= 1750px) {
  .left-gradient {
    opacity: .7;
    align-content: flex-start;
    width: 100%;
    height: 414px;
    position: absolute;
    left: -457px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 1557px;
  }

  .purple {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(60px);
    background: #119291;
    width: 659px;
    height: 414px;
    position: absolute;
    top: -160px;
    transform: translate3d(0, 0, 0);
  }

  .purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    left: -100px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 314px;
    position: absolute;
    top: -100px;
    left: -100px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1500px) {
  .left-gradient {
    opacity: .7;
    align-content: flex-start;
    width: 100%;
    height: 414px;
    position: absolute;
    left: -407px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 1157px;
  }

  .purple {
    filter: blur(60px);
    background: #5025bf;
    width: 654px;
    height: 374px;
    position: absolute;
    top: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    transform: translate3d(0, 0, 0);
  }

  .purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 354px;
    position: absolute;
    top: 200px;
    left: 130px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    left: 240px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1400px) {
  .left-gradient {
    opacity: .7;
    align-content: flex-start;
    width: 100%;
    height: 414px;
    position: absolute;
    left: -407px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 1157px;
  }

  .purple {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    transform: translate3d(0, 0, 0);
  }

  .purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    left: -50px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    left: 50px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 1200px) {
  .left-gradient {
    opacity: .7;
    align-content: flex-start;
    width: 100%;
    height: 414px;
    position: absolute;
    left: -407px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 857px;
  }

  .purple {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    transform: translate3d(0, 0, 0);
  }

  .purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    left: -50px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 414px;
    position: absolute;
    top: -120px;
    left: 50px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 992px) {
  .left-gradient {
    opacity: .7;
    align-content: flex-start;
    width: 100%;
    height: 414px;
    position: absolute;
    left: -407px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 650px;
  }
}

@media screen and (width <= 855px) {
  .mainHeading {
    line-height: 40px;
    font-size: 32px !important;
  }

  .bottomJumbotron {
    gap: 0;
    height: 250px;
  }

  .right-gradient {
    opacity: .7;
    align-content: flex-end;
    width: 100%;
    height: 414px;
    position: absolute;
    left: 750px;
  }

  .purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 100px;
    left: -200px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -120px;
    left: -100px;
    transform: translate3d(0, 0, 0);
  }

  .purple {
    filter: blur(60px);
    background: #5025bf;
    width: 694px;
    height: 374px;
    position: absolute;
    top: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(60px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -120px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 700px) {
  .mainHeading {
    text-align: center;
    margin-bottom: 5px;
    line-height: 35px;
    align-self: center !important;
    font-size: 28px !important;
  }

  .bottomJumbotron {
    height: 170px;
  }

  .button-1 {
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 38px;
  }

  .view-more {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    font-size: 14px !important;
  }

  .smallText {
    margin-bottom: 4px;
    display: flex;
    font-size: 14px !important;
  }

  .right-gradient {
    left: 597px;
  }

  .purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 394px;
    height: 274px;
    position: absolute;
    top: 20px;
    left: -60px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(70px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -120px;
    left: 0;
    transform: translate3d(0, 0, 0);
  }

  .purple {
    filter: blur(50px);
    background: #5025bf;
    width: 654px;
    height: 274px;
    position: absolute;
    top: 100px;
    left: -45px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(70px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -100px;
    left: 40px;
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (width <= 550px) {
  .right-gradient {
    left: 537px;
  }

  .purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 394px;
    height: 274px;
    position: absolute;
    top: 50px;
    left: -90px;
    transform: translate3d(0, 0, 0);
  }

  .green2 {
    filter: blur(70px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -120px;
    left: -20px;
    transform: translate3d(0, 0, 0);
  }

  .purple {
    filter: blur(50px);
    background: #5025bf;
    width: 654px;
    height: 274px;
    position: absolute;
    top: 100px;
    left: -45px;
    transform: translate3d(0, 0, 0);
  }

  .green {
    filter: blur(70px);
    background: #119291;
    width: 519px;
    height: 214px;
    position: absolute;
    top: -100px;
    left: 40px;
    transform: translate3d(0, 0, 0);
  }

  .geomtris-jumbo-right {
    opacity: .52;
    background-image: url("JumboRight.65d53986.png");
    background-repeat: no-repeat;
    width: 497px;
    height: 471.89px;
    position: absolute;
    top: -20px;
    right: -150px;
  }

  .geomtris-left {
    opacity: .52;
    background-image: url("JumboLeft.c43a9255.png");
    background-repeat: no-repeat;
    width: 497px;
    height: 471.89px;
    position: absolute;
    top: 0;
    left: -50px;
  }
}

@media screen and (width <= 500px) {
  .bottomJumbotron {
    background-image: url("JumbotronBack.d3dd91ea.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .mainHeading {
    font-size: 22px !important;
  }

  .marquee {
    width: 80%;
  }

  .marquee h2 {
    padding-left: 8px;
  }

  .right-gradient, .left-gradient, .purple2, .green2, .purple, .green, .geomtris-jumbo-right, .geomtris-left {
    display: none;
  }
}

@media screen and (width <= 360px) {
  .mainHeading {
    font-size: 20px !important;
  }
}

.burgerMenuDiv {
  cursor: pointer;
  display: block;
}

.burgerMenu {
  width: 35px;
  height: 36px;
}

.menu-navigation-1, .buttonDiv {
  display: none;
}

.nav-active {
  letter-spacing: 0;
  white-space: nowrap;
  color: #fff;
  text-shadow: #00d7c3 0px 0px 10px inset #00d7c3 0px 0px 20px 2px;
  width: fit-content;
  margin-top: -1px;
  line-height: 28px;
  text-decoration: underline #00d7c3;
  position: relative;
  font-family: Riviera !important;
}

.navDropdown {
  animation: none;
  display: none;
}

.navDropdown.active {
  z-index: 10;
  background-color: #25252580;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-bottom: 35px;
  padding-bottom: 15px;
  animation: 1s forwards fadeIn;
  display: flex;
  position: relative;
}

.navbar-1 {
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 40px 20px 16px;
  display: flex;
  position: relative;
}

.navDropdown.inactive {
  opacity: 1;
  animation: 1s forwards fadeOut;
}

.listItem {
  padding-top: 10px;
  font-family: Riviera !important;
}

.blurDiv {
  filter: blur(150px);
  background: #00d7c3;
  width: 0;
  height: 0;
  position: absolute;
  top: 100px;
  left: 20px;
}

.active-blur {
  filter: blur(150px);
  background: #00d7c3;
  width: 200px;
  height: 200px;
  animation: .7s linear blur;
  position: absolute;
  top: 80px;
  left: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes blur {
  0% {
    width: 0;
  }

  25% {
    width: 75px;
  }

  50% {
    width: 150px;
  }

  100% {
    width: 200px;
  }
}

@media screen and (width >= 960px) {
  .menu-navigation-1, .menu-navigation-2, .menu-navigation-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin-left: 30px;
    display: flex;
    position: relative;
  }

  .product-1, .features-1, .solutions-1, .company-1, .product-2, .features-2, .solutions-2, .company-2, .product-3, .features-3, .solutions-3, .company-3 {
    letter-spacing: 0;
    white-space: nowrap;
    color: #fff;
    width: fit-content;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
    font-family: Riviera !important;
  }

  .frame-50-1, .frame-50-2, .frame-50-3 {
    align-items: center;
    gap: 4px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .frame-8, .frame-9, .frame-10 {
    min-width: 16px;
    height: 16px;
    position: relative;
  }

  .navbar-1 {
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 40px 80px 16px;
    display: flex;
    position: relative;
  }

  .burgerMenuDiv {
    display: none;
  }

  .navDropdown {
    animation: none;
    display: none;
  }

  .listItem {
    padding-top: 10px;
    font-family: Riviera !important;
  }

  .buttonDiv {
    width: 150px;
    display: flex;
    position: relative;
    overflow: visible;
  }

  .nav-active .product-1 {
    text-shadow: #00d7c3 0px 0px 10px inset #00d7c3 0px 0px 20px 2px;
    text-decoration: underline #00d7c3;
  }

  .nav-active {
    letter-spacing: 0;
    white-space: nowrap;
    color: #fff;
    text-shadow: #00d7c3 0px 0px 10px inset #00d7c3 0px 0px 20px 2px;
    width: fit-content;
    margin-top: -1px;
    line-height: 28px;
    text-decoration: underline #00d7c3;
    position: relative;
    font-family: Riviera !important;
  }
}

.logo-5 {
  object-fit: cover;
  width: 150px;
  height: 79.4px;
  display: flex;
}

@media screen and (width <= 475px) {
  .logo-5 {
    width: 120px;
    height: 49.4px;
  }
}

.section-technologies-we-use {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 737px;
  margin-top: 70px;
  display: flex;
  position: relative;
}

.technologies-we-use-1 {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 52px;
  width: 50%;
  height: 388px;
  display: flex;
  position: relative;
  top: -20px;
  transform: translate(-25px);
}

.technologies-we-use-1.animate-enter12 {
  animation: 2s ease-in-out 2s forwards animate-enter12-frames;
  transform: translate(-25px);
}

.technologiesHeader {
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
  display: flex;
}

.line2 {
  background-color: #696969;
  width: 350px;
  height: .7px;
  margin: 5px auto;
}

.headline-body {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 528px;
  display: flex;
  position: relative;
}

.Tech {
  flex-direction: row;
  display: flex;
}

.figma {
  flex-direction: row;
  align-items: flex-end;
  width: 14px;
  margin-left: -26px;
  padding-top: 5px;
  display: flex;
}

.mysql, .tech {
  flex-direction: row;
  align-items: flex-end;
  width: 20px;
  margin-left: -28px;
  padding-top: 5px;
  display: flex;
}

.modal-row {
  width: 100%;
  padding-top: 5px;
}

.modalContainer {
  height: 400px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

::-webkit-scrollbar-thumb {
  background-color: #00d7c3;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 10px #00d7c3;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: var(--eerie-black);
}

.last {
  flex-direction: row;
  align-items: flex-end;
  width: 20px;
  margin-left: -28px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.stuff-we-geek-out-about-1 {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 52px;
  font-weight: 700;
  line-height: 70px;
  position: relative;
}

.hexagon-section {
  width: 50%;
  height: 80%;
  display: flex;
  position: relative;
}

.hexagonGroupContainer {
  width: 100%;
  position: relative;
  top: 60px;
}

@media only screen and (width <= 1200px) {
  .section-technologies-we-use {
    flex-direction: column;
    height: fit-content;
  }

  .hexagonGroupContainer {
    width: 100%;
    height: 500px;
    top: 0;
  }

  .technologies-we-use-1 {
    width: 100%;
    height: 600px;
  }

  .hexagon-section {
    width: 70%;
  }
}

@media only screen and (width <= 850px) {
  .hexagon-section {
    width: 80%;
  }

  .line2 {
    background-color: #696969;
    width: 350px;
    height: .9px;
    margin: 5px auto;
  }
}

@media only screen and (width <= 700px) {
  .technologiesHeader {
    gap: 20px !important;
  }

  .headline-body {
    gap: 10px;
    width: 100%;
  }

  .technologies-we-use-1 {
    align-items: start;
    height: 450px;
    padding-right: 20px;
    display: flex;
  }

  .stuff-we-geek-out-about-1 {
    line-height: 40px;
    font-size: 32px !important;
  }

  .section-technologies-we-use {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hexagon-section {
    width: 100%;
  }
}

@media only screen and (width <= 575px) {
  .hexagon-section {
    left: -100px;
  }

  .line2 {
    background-color: #696969;
    width: 325px;
    height: .8px;
    margin: 5px auto;
  }
}

@media only screen and (width <= 450px) {
  .hexagon-section {
    left: -150px;
  }

  .line2 {
    background-color: #696969;
    width: 315px;
    height: .7px;
    margin: 5px auto;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #0000004d;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00d7c3;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: #00d7c3;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }
}

@media only screen and (width <= 425px) {
  .line2 {
    background-color: #696969;
    width: 290px;
    height: .7px;
    margin: 5px auto;
  }
}

@media only screen and (width <= 410px) {
  .line2 {
    background-color: #696969;
    width: 270px;
    height: .7px;
    margin: 5px auto;
  }
}

@media only screen and (width <= 375px) {
  .line2 {
    background-color: #696969;
    width: 255px;
    height: .6px;
    margin: 5px auto;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #0000004d;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00d7c3;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: #00d7c3;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }
}

@media only screen and (width <= 320px) {
  .line2 {
    background-color: #696969;
    width: 215px;
    height: .6px;
    margin: 5px auto;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #0000004d;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00d7c3;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: #00d7c3;
    -webkit-box-shadow: inset 0 0 10px #00d7c3;
  }
}

.vector-right-4 {
  height: 56px;
  position: absolute;
  top: 0;
}

.vectorDivTop {
  width: 100%;
  height: 56px;
  position: absolute;
  top: 0;
  left: 342px;
  overflow: hidden;
}

.vector-right-5 {
  height: 85px;
  position: absolute;
  top: 0;
}

.vectorDivBottom {
  width: 100%;
  height: 85px;
  position: absolute;
  top: 361px;
  left: 327px;
  overflow: hidden;
}

.animate-top, .animate-bottom {
  animation: 2s linear effect-block;
}

@keyframes effect-block {
  0% {
    width: 0%;
  }

  25% {
    width: 5%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

.hexagon-container {
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  display: flex;
  position: relative;
}

@media screen and (width <= 700px) {
  .hexagonImage {
    width: 108.25px;
    height: 95.42px;
  }

  .hexagonImage.small {
    width: 75.48px;
    height: 66.54px;
  }
}

@media only screen and (width <= 700px) {
  .vectorDivBottom {
    top: 250px !important;
  }
}

.modal {
  z-index: 1040;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-content {
  background-color: #fefefe;
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--shark);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 389px;
  max-width: 80%;
  margin: 8% auto;
  padding: 24px;
  display: flex;
  position: relative;
}

.reviewModalHeading {
  margin-bottom: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #00d7c3;
  cursor: pointer;
  text-decoration: none;
}

.section-how-we-work-1 {
  scroll-snap-align: start;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1400px;
  height: 2050px;
  display: flex;
  position: relative;
}

.visual-graphiic {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50%;
  height: 670px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.group-300 {
  z-index: 10;
  width: 412px;
  height: 271px;
  transform: translate(-25px);
}

.frame-268-1 {
  align-items: center;
  width: 412px;
  height: 271px;
  display: flex;
  position: relative;
}

.to-do-1-issue-8 {
  background-color: var(--black-2);
  border: 1px solid;
  border-color: var(--white-6);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 318px;
  padding: 16px 16px 20px;
  display: flex;
  position: relative;
}

.to-do-1-issue-9 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 34px;
  display: flex;
  position: relative;
}

.frame-264-1 {
  background-color: var(--white-2);
  border-radius: 6px;
  align-items: flex-start;
  gap: 10px;
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.to-do-1-issue-10, .in-progress, .review-1, .done-1 {
  letter-spacing: 0;
  opacity: .8;
  flex-wrap: wrap;
  width: fit-content;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.frame-263-1 {
  background-color: var(--blue-chill);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 286px;
  padding: 12px 16px;
  display: flex;
  position: relative;
}

.frame-262-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  position: relative;
}

.start-a-project-with-alpha-encoded-team-1 {
  letter-spacing: 0;
  opacity: .8;
  background-color: #0000;
  border: 0;
  flex-wrap: wrap;
  min-width: 254px;
  height: 52px;
  margin-top: -1px;
  padding: 0;
  line-height: 24px;
  display: flex;
  position: relative;
}

.start-a-project-with-alpha-encoded-team-1::placeholder {
  color: #fff9;
  white-space: pre-wrap;
  flex-wrap: wrap;
  display: flex;
}

.frame-261-1 {
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.ae-1-1 {
  letter-spacing: 0;
  opacity: .8;
  white-space: nowrap;
  flex: 1;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
}

.vector-right-3 {
  min-width: 20px;
  height: 2px;
  position: relative;
}

.group-2 {
  min-width: 74px;
  height: 74px;
  position: relative;
}

.overlap-group1-1 {
  width: 76px;
  height: 74px;
  position: relative;
}

.ellipse-163 {
  backdrop-filter: blur(10px) brightness();
  background: radial-gradient(50% 50%, #11929100 0%, #119291cc 65.9%);
  border-radius: 37px / 36.75px;
  width: 74px;
  height: 73px;
  position: absolute;
  top: 1px;
  left: 0;
}

.overlap-group-10 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 74px;
  height: 73px;
  padding: 22px 31px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.number-6 {
  letter-spacing: 0;
  min-height: 29px;
  line-height: normal;
}

.group-299-1, .group-302 {
  width: 412px;
  height: 113px;
  margin-left: 68px;
  transform: translate(25px);
}

.frame-2-1 {
  align-items: center;
  width: 412px;
  height: 113px;
  display: flex;
  position: relative;
}

.to-do-1-issue-6 {
  background-color: var(--black-2);
  border: 1px solid;
  border-color: var(--white-6);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 318px;
  padding: 16px;
  display: flex;
  position: relative;
}

.to-do-1-issue-7 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.group-301 {
  width: 412px;
  height: 113px;
  transform: translate(-25px);
}

.group-301.animate-enter9 {
  animation: 1.5s ease-in-out 2s forwards animate-enter9-frames;
  transform: translate(-25px);
}

.overlap-group-11 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 74px;
  height: 73px;
  padding: 22px 29px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.group-302.animate-enter10 {
  animation: 1.5s ease-in-out 2.5s forwards animate-enter10-frames;
  transform: translate(25px);
}

.flex-col-1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 50%;
  height: 100%;
  min-height: 890px;
  display: flex;
  position: relative;
}

.jiraCardDiv {
  flex-direction: column;
  gap: 180px;
  height: 100%;
  display: flex;
  position: relative;
}

.frame-135 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 519px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  transform: translate(0, 25px);
}

.chip-2 {
  align-self: stretch;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.how-we-create-1 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: 170px;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.we-made-a-process-for-that-1, .what-our-lovely-clients-say-1 {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 70px;
  position: relative;
}

.x-the-1 {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 24px;
  position: relative;
}

.jiraScrollGroup {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: row;
  gap: 200px;
  width: 2000px;
  padding-right: 70px;
  display: flex;
  left: 600px;
}

.jiraScrollGroup::-webkit-scrollbar {
  display: none;
}

.jiraImageDiv {
  z-index: 10;
}

@media only screen and (width <= 1200px) {
  .section-how-we-work-1 {
    flex-direction: column-reverse;
    justify-content: center;
    height: 3700px;
    margin-top: 120px;
  }

  .flex-col-1 {
    width: 100%;
  }

  .visual-graphiic {
    width: 100%;
    position: relative;
  }

  .frame-135 {
    line-height: 40px;
  }
}

@media only screen and (width <= 700px) {
  .jiraImage {
    z-index: 15;
    width: 100%;
    height: auto;
  }

  .flex-col-1 {
    gap: 100px;
    height: 100%;
    margin-bottom: 100px;
  }

  .section-how-we-work-1 {
    height: auto;
    margin-top: 10px;
    margin-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .we-made-a-process-for-that-1 {
    line-height: 40px;
    font-size: 32px !important;
  }

  .x-the-1 {
    font-size: var(--font-size-m) !important;
  }

  .frame-135 {
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    max-width: 519px;
  }

  .visual-graphiic {
    width: 100%;
    height: auto;
  }

  .to-do-1-issue-8 {
    width: 100%;
    max-width: 318px;
    height: 189.14px;
  }

  .to-do-1-issue-9 {
    gap: 15px;
    height: auto;
  }

  .group-300, .frame-268-1 {
    width: 100%;
    max-width: 412px;
    height: 189.14px;
  }

  .frame-263-1 {
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    max-width: 286px;
    height: 84.5px;
    padding: 8px 16px;
  }

  .start-a-project-with-alpha-encoded-team-1 {
    width: 100%;
    min-width: 0;
    max-width: 254px;
    height: auto;
    font-size: 10px !important;
  }

  .vector-right-3 {
    width: 100%;
    max-width: 20px;
    height: auto;
  }

  .to-do-1-issue-6 {
    gap: 0;
    width: 100%;
    max-width: 318px;
    height: 78.86px;
    padding: 8px 16px;
  }

  .group-299-1, .group-302, .group-301, .frame-2-1 {
    width: 100%;
    max-width: 412px;
    height: auto;
  }

  .frame-265-2 {
    height: 25px;
  }

  .to-do-1-issue-7 {
    gap: 5px;
    height: 78px;
  }

  .frame-265-1 {
    gap: 10px;
  }

  .to-do-1-issue-10, .in-progress, .review-1, .done-1, .create-issue-1, .ae-1-1 {
    font-size: 10px !important;
  }

  .we-are-software-agency-4, .frame-265-1, .frame-265-2, .frame-265-3, .frame-265-4 {
    width: 100%;
  }

  .vuesaxoutlineadd-1, .vuesaxboldtick-square-1 {
    width: 16px;
    height: 16px;
  }

  .frame-262-1 {
    gap: 10px;
  }

  .jiraCardDiv {
    gap: 150px;
  }
}

@media only screen and (width <= 475px) {
  .frame-135 {
    line-height: 20px;
  }

  .to-do-1-issue-8 {
    gap: 0;
  }

  .to-do-1-issue-6 {
    max-width: 222px;
  }

  .frame-262-1 {
    gap: 0;
  }

  .start-a-project-with-alpha-encoded-team-1 {
    height: 58px;
    margin-top: -10px;
  }
}

@media only screen and (width <= 380px) {
  .group-299-1, .group-302, .group-301, .frame-2-1 {
    left: -10px;
  }
}

.vuesaxboldtick-square-1 {
  background-image: url("vuesax-bold-tick-square.df732136.svg");
  background-size: 100% 100%;
  min-width: 24px;
  height: 24px;
  position: relative;
}

.frame-266-1 {
  align-self: stretch;
  align-items: center;
  gap: 4px;
  display: flex;
  position: relative;
}

.vuesaxoutlineadd-1 {
  min-width: 24px;
  height: 24px;
  position: relative;
}

.create-issue-1 {
  letter-spacing: 0;
  opacity: .8;
  flex: 1;
  line-height: normal;
  position: relative;
}

.group-29-2-1 {
  backdrop-filter: blur(10px) brightness();
  background: radial-gradient(50% 50%, #11929100 0%, #119291cc 65.9%);
  border-radius: 37px / 36.75px;
  min-width: 74px;
  height: 73.49px;
  display: flex;
  position: relative;
}

.group-297-2 {
  align-items: flex-start;
  min-width: 76px;
  height: 73.49px;
  display: flex;
}

.overlap-group-14 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 74px;
  height: 73px;
  margin-top: 0;
  padding: 22px 29px;
  display: flex;
}

.number-7 {
  letter-spacing: 0;
  min-height: 29px;
  line-height: normal;
}

.x3-1 {
  align-items: center;
  gap: 16px;
  width: fit-content;
  margin-top: 100px;
  display: flex;
  position: relative;
}

.design-then-our-desi-1 {
  letter-spacing: 0;
  max-width: 280px;
  line-height: 24px;
  position: relative;
}

.header-p {
  flex-direction: column;
  display: flex;
}

.x3-1.x3-2 {
  width: 477px;
  height: 56px;
  margin-top: 30px;
}

.span0-1 {
  letter-spacing: 1.5px;
  margin-bottom: 15px;
}

.mainCard {
  flex-direction: column;
}

.lineDiv {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

@media screen and (width <= 700px) {
  .span0-1 {
    font-size: 28px !important;
  }

  .x3-1.x3-2 {
    width: 100%;
  }

  .design-then-our-desi-1 {
    font-size: 16px !important;
  }
}

.icon-container {
  text-shadow: .05em 0 #00fffc, -.03em -.04em #fc00ff, .025em .04em #fffc00;
  height: fit-content;
  animation: .725s infinite glitch;
  position: relative;
  overflow: visible;
}

.innerIconDiv {
  overflow: visible;
}

.icon-container .icon-test {
  position: absolute;
  top: 0;
  left: 0;
}

.icon-size {
  font-size: 120px;
}

.icon-container .icon-test-one {
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  opacity: .75;
  animation: .5s infinite glitch;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-.02em, -.01em);
}

.icon-container .icon-test-two {
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  opacity: .75;
  animation: .375s infinite glitch;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(.02em, .01em);
}

@keyframes glitch {
  0% {
    text-shadow: .05em 0 #00fffc, -.03em -.04em #fc00ff, .025em .04em #fffc00;
  }

  15% {
    text-shadow: .05em 0 #00fffc, -.03em -.04em #fc00ff, .025em .04em #fffc00;
  }

  16% {
    text-shadow: -.05em -.025em #00fffc, .025em .035em #fc00ff, -.05em -.05em #fffc00;
  }

  49% {
    text-shadow: -.05em -.025em #00fffc, .025em .035em #fc00ff, -.05em -.05em #fffc00;
  }

  50% {
    text-shadow: .05em .035em #00fffc, .03em 0 #fc00ff, 0 -.04em #fffc00;
  }

  99% {
    text-shadow: .05em .035em #00fffc, .03em 0 #fc00ff, 0 -.04em #fffc00;
  }

  100% {
    text-shadow: -.05em 0 #00fffc, -.025em -.04em #fc00ff, -.04em -.025em #fffc00;
  }
}

@media screen and (width <= 700px) {
  .icon-size {
    font-size: 80px;
  }
}

.hero-section-1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 43px;
  width: 100%;
  max-width: 1400px;
  min-height: 700px;
  margin-top: 20px;
  display: flex;
}

.insomnia-group {
  justify-content: center;
  width: 50%;
  display: flex;
  position: relative;
}

.insomnia-image {
  width: 665px;
  height: 646px;
  display: block;
  position: relative;
  top: 0;
}

.insomnia-group-photo {
  z-index: 10;
  justify-content: center;
  display: flex;
}

.content-5 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 52px;
  display: flex;
  position: relative;
}

.heroWritingSection {
  flex-direction: column;
  align-items: center;
  width: 50%;
  display: flex;
  position: relative;
}

.container {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: fit-content;
  display: flex;
  position: relative;
}

.we-are-software-agency-4, .frame-265-1, .frame-265-2, .frame-265-3, .frame-265-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: fit-content;
  display: flex;
  position: relative;
}

.chip-1 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.line {
  object-fit: cover;
  min-width: 32px;
  height: 1px;
  position: relative;
}

.from-design-and-deve-1 {
  letter-spacing: 0;
  width: 520px;
  line-height: 30px;
  position: relative;
}

.button-1 {
  background-color: var(--portage);
  border-radius: 12px;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-top: 10px;
  padding: 20px 56px;
  display: flex;
  position: relative;
}

.lets-talk-2, .view-more {
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  line-height: 24px;
  position: relative;
}

@media only screen and (width <= 1360px) {
  .hero-section-1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .heroWritingSection {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 20px;
  }

  .group-298-1 {
    width: 100%;
    min-width: 500px;
    height: 100%;
  }
}

@media only screen and (width <= 700px) {
  .hero-section-1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
  }

  .heroWritingSection {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .group-298-1 {
    width: 100%;
    min-width: 500px;
    height: 100%;
  }

  .insomnia-group {
    width: 100%;
  }

  .insomnia-image {
    width: 100%;
    height: auto;
  }

  .from-design-and-deve-1 {
    letter-spacing: 0;
    width: 100%;
    line-height: 30px;
    position: relative;
    font-size: var(--font-size-m) !important;
  }

  .content-5 {
    gap: 30px;
  }

  .lets-talk-2, .view-more {
    font-size: 14px !important;
  }
}

@media only screen and (width <= 420px) {
  .heroWritingSection {
    padding-right: 20px;
  }
}

.lets-build-cool-sht-together-1 {
  color: var(--white);
  font-family: var(--font-family-switzer-extrabold);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  z-index: 10;
  line-height: 74px;
  position: relative;
  font-weight: 400 !important;
}

@media screen and (width <= 700px) {
  .lets-build-cool-sht-together-1 {
    font-size: 32px;
    line-height: 40px;
  }
}

#word-span {
  padding: 5px;
}

#curlySpan {
  width: 100%;
  margin-left: 12px;
  display: inline-flex;
  position: absolute;
  top: -5px;
}

#curlySpan-mobile {
  z-index: -1;
  width: 100%;
  margin-left: 12px;
  display: inline-flex;
  position: absolute;
  top: -17px;
  left: 145px;
}

.lets-build-cool-sht-together-1 {
  color: var(--white);
  font-family: var(--font-family-switzer-extrabold);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  z-index: 10;
  font-weight: 400;
  line-height: 74px;
  position: relative;
}

@media screen and (width <= 700px) {
  .lets-build-cool-sht-together-1 {
    font-size: 32px;
    line-height: 40px;
  }
}

.glitch {
  text-shadow: .05em 0 #00fffc, -.03em -.04em #fc00ff, .025em .04em #fffc00;
  height: fit-content;
  animation: .725s infinite glitch;
  position: relative;
  overflow: visible;
}

.glitch .test {
  position: absolute;
  top: 3px;
  left: 4px;
}

.glitch .test:first-child {
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  opacity: .75;
  animation: .5s infinite glitch;
  transform: translate(-.04em, -.03em);
}

.glitch .test:last-child {
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  opacity: .75;
  animation: .375s infinite glitch;
  transform: translate(.04em, .03em);
}

.animatedTextGif {
  position: relative;
  top: -14px;
  left: -6px;
}

.section-testimonial-1 {
  flex-direction: row;
  justify-content: center;
  gap: 44px;
  width: 100%;
  max-width: 1500px;
  height: 797px;
  display: flex;
  position: relative;
}

.frame-147 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 52px;
  width: 40%;
  display: flex;
  position: relative;
  transform: translate(-25px);
}

.frame-147.animate-enter13 {
  animation: 2s ease-in-out 2s forwards animate-enter13-frames;
  transform: translate(-25px);
}

.testimonial {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 80%;
  display: flex;
  position: relative;
}

.testimonial-1 {
  align-items: flex-start;
  width: 60%;
  height: 605px;
  margin-top: 96px;
  display: flex;
  overflow: hidden;
}

.overlap-group-13 {
  flex-direction: row;
  height: auto;
  margin-top: -167px;
  position: relative;
}

.rectangle-174 {
  background: linear-gradient(#0000 0% 64.58%);
  width: 810px;
  height: 561px;
  position: absolute;
  top: 167px;
  left: 0;
}

.scrollGroup {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  padding-bottom: 28px;
  display: flex;
  position: relative;
}

.scroll-animation {
  animation: 40s linear infinite animate-scrollY;
}

.scroll-animation-mobile {
  animation: 70s linear infinite animate-scrollY;
}

.leftScrollColumn {
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  display: flex;
  position: absolute;
  left: 0;
}

.rightScrollColumn {
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  display: flex;
  position: absolute;
  left: 421px;
}

.rectangle-173 {
  background: linear-gradient(0deg, #0000 0%, #000 80.91%);
  width: 810px;
  height: 62px;
  position: absolute;
  top: 166px;
  left: 0;
}

.rectangle-175-1 {
  background: linear-gradient(#0000 0%, #000 80.91%);
  width: 810px;
  height: 80px;
  position: absolute;
  top: 692px;
  left: 0;
}

.chip-22 {
  align-self: stretch;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

@keyframes animate-scrollY {
  from {
    left: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@media only screen and (width <= 1420px) {
  .section-testimonial-1 {
    gap: 20px;
  }

  .frame-147 {
    width: 30%;
  }

  .testimonial-1 {
    width: 70%;
  }
}

@media only screen and (width <= 1200px) {
  .section-testimonial-1 {
    flex-direction: column;
    height: fit-content;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .testimonial {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .testimonial-1, .frame-147 {
    justify-content: center;
    width: 100%;
  }

  .leftScrollColumn {
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .rightScrollColumn {
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    display: flex;
    position: relative;
    left: 20px;
  }

  .overlap-group-13 {
    display: flex;
  }

  .chip-22 {
    align-self: center;
  }

  .what-our-lovely-clients-say-1 {
    align-self: center !important;
  }
}

@media only screen and (width <= 950px) {
  .leftScrollColumn {
    left: -10px;
  }

  .rightScrollColumn {
    left: 10px;
  }

  .rectangle-173, .rectangle-175-1 {
    left: -10px;
  }
}

@media only screen and (width <= 810px) {
  .rectangle-173, .rectangle-175-1 {
    left: -100px;
  }

  .leftScrollColumn {
    align-self: center;
    left: 0;
  }
}

@media only screen and (width <= 700px) {
  .testimonial {
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
  }

  .chip-22 {
    align-self: flex-start;
  }

  .what-our-lovely-clients-say-1 {
    align-self: flex-start !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .section-testimonial-1 {
    margin-top: -30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .testimonial-1 {
    margin-top: 40px;
  }
}

@media only screen and (width <= 390px) and (-webkit-device-pixel-ratio: 3) {
  .testimonial {
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
  }

  .chip-22 {
    align-self: flex-start;
  }

  .what-our-lovely-clients-say-1 {
    align-self: flex-start !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .section-testimonial-1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .testimonial-1 {
    margin-top: 40px;
  }
}

.frame-15-1-1 {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--shark);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 389px;
  padding: 24px;
  display: flex;
  position: relative;
}

.frame-150-3 {
  align-self: stretch;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.ellipse-164-5 {
  object-fit: cover;
  border-radius: 28px;
  width: 52px;
  height: 52px;
  position: relative;
}

.frame-149-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 104px;
  display: flex;
  position: relative;
}

.name {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 26px;
  position: relative;
}

.ceo-bukalapak-2 {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.alpha-team-has-been-1 {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.frame-15-1-1.frame-15-1-2 .ellipse-164-5, .frame-15-1-1.frame-15-1-3 .ellipse-164-5, .frame-15-1-1.frame-15-1 .ellipse-164-5 {
  height: 52px;
}

@media screen and (width <= 700px) {
  .alpha-team-has-been-1 {
    font-size: 12px !important;
  }
}

@media screen and (width <= 450px) {
  .frame-15-1-1 {
    width: 100%;
  }
}

.section-services-1 {
  flex-direction: column;
  align-items: center;
  gap: 72px;
  width: 100%;
  height: 862px;
  padding: 96px 112px;
  display: flex;
  position: relative;
}

.title-1 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
  transform: translate(0, 25px);
}

.title-1.animate-enter2 {
  animation: 1.5s ease-in-out 1.5s forwards animate-enter2-frames;
  transform: translate(0, 25px);
}

.turning-visions-into-reality-1 {
  letter-spacing: 0;
  text-align: center;
  justify-content: center;
  line-height: 70px;
  position: relative;
}

.services-1 {
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 356px;
  display: flex;
  position: relative;
}

.speaker-1-1 {
  object-fit: cover;
  min-width: 40px;
  height: 40px;
  position: relative;
}

@media screen and (width <= 1306px) {
  .section-services-1 {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  .services-1 {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
  }
}

@media screen and (width <= 1055px) {
  .services-1 {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .section-services-1 {
    height: auto;
  }
}

@media screen and (width <= 880px) {
  .turning-visions-into-reality-1 {
    text-align: start;
    justify-content: flex-start;
    align-items: start;
    line-height: 70px;
    display: flex;
  }

  .title-1 {
    text-align: start;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
  }
}

@media screen and (width <= 700px) {
  .section-services-1 {
    gap: 40px !important;
  }

  .turning-visions-into-reality-1 {
    align-items: start;
    line-height: 40px;
    display: flex;
    font-size: 32px !important;
  }

  .title-1 {
    text-align: start;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .section-services-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (width <= 400px) {
  .section-services-1 {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.card-web-developmeent {
  background-color: var(--white-5);
  border: 3px solid;
  border-color: var(--shark);
  cursor: pointer;
  box-sizing: content-box;
  border-radius: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 230px;
  padding: 40px 24px;
  transition: border .5s ease-in-out, box-shadow .5s ease-in-out, scale .5s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 #00d7c3, 0 0 #00d7c3;
}

.card-web-developmeent:hover {
  border: 3px solid #00d7c3;
  padding: 40px 24px;
  box-shadow: inset 0 0 10px 1px #00d7c3, 0 0 20px 5px #00d7c3;
  transform: scale(1.1) !important;
}

.icon-desktop-1 {
  min-width: 40px;
  height: 40px;
  position: relative;
}

@media screen and (width <= 1290px) {
  .card-web-developmeent {
    width: 50%;
  }
}

@media screen and (width <= 1055px) {
  .card-web-developmeent {
    flex-grow: 1;
    width: 100%;
  }
}

@media screen and (width <= 700px) {
  .card-web-developmeent {
    gap: 5px;
  }
}

.headline-body-2 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.web-development-1 {
  letter-spacing: 0;
  white-space: pre-line;
  align-self: stretch;
  margin-top: -1px;
  line-height: 44px;
  position: relative;
}

.we-build-websites-an-1 {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 24px;
  position: relative;
}

.headline-body-2 {
  box-shadow: unset;
}

@media screen and (width <= 1290px) {
  .web-development-1 {
    white-space: normal;
  }
}

@media screen and (width <= 700px) {
  .web-development-1 {
    flex-wrap: wrap !important;
    font-size: 24px !important;
    display: flex !important;
  }

  .we-build-websites-an-1 {
    font-size: 16px !important;
  }

  .headline-body-2 {
    gap: 5px;
  }
}

.about-page {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  min-width: 355px;
  display: flex;
  position: relative;
}

.top-grad {
  opacity: .7;
  width: 400.03px;
  height: 510.44px;
  position: absolute;
  top: 0;
  left: 0;
}

.purple-about {
  filter: blur(70px);
  background: #5025bf;
  width: 256.4px;
  height: 296.51px;
  position: absolute;
  top: 350px;
  left: 150px;
  transform: translate3d(0, 0, 0);
}

.green-about {
  filter: blur(70px);
  background: #119291;
  width: 450.03px;
  height: 290.43px;
  position: absolute;
  top: 170px;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.about-purple {
  filter: blur(70px);
  background: #5025bf;
  width: 350.92px;
  height: 271.78px;
  position: absolute;
  top: 527.22px;
  left: 1214.31px;
  transform: translate3d(0, 0, 0);
}

.about-green {
  filter: blur(70px);
  background: #119291;
  width: 400px;
  height: 254.19px;
  position: absolute;
  top: 338px;
  left: 1100px;
  transform: translate3d(0, 0, 0);
}

.about-left {
  opacity: .7;
  width: 400px;
  height: 241px;
  position: absolute;
  top: 1200px;
  left: 972px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about-right {
  opacity: .7;
  width: 330px;
  height: 301px;
  position: absolute;
  top: 1651px;
  left: 1802px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about-purple2 {
  filter: blur(70px);
  background: #5025bf;
  width: 304.22px;
  height: 201.78px;
  position: absolute;
  top: 1350.22px;
  left: 1703.07px;
  transform: translate3d(0, 0, 0);
}

.about-green2 {
  filter: blur(70px);
  background: #119291;
  width: 430px;
  height: 250.19px;
  position: absolute;
  top: 1151px;
  left: 1652px;
  transform: translate3d(0, 0, 0);
}

.geo-about-top {
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("GeoTopRight.01a22c99.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 188px;
  height: 301.89px;
  position: absolute;
  top: 11.11px;
}

.geo-about-left {
  z-index: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("GeoBotLeft.01f229db.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 207px;
  height: 671.89px;
  position: absolute;
  top: 209.43px;
  left: 0;
}

.geo-about-bottom {
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("aboutLeftGeo.79a05db3.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 297px;
  height: 871.89px;
  position: absolute;
  top: 1150.11px;
  left: 0;
}

.geo-about-right {
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("aboutGeoRight.7e1dff4b.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 225px;
  height: 305.89px;
  position: absolute;
  top: 1973.43px;
  left: 0;
}

.hero-section-2 {
  min-width: 350px;
  height: 979px;
  position: relative;
}

.flex-col-3 {
  flex-direction: column;
  align-items: center;
  width: 768px;
  min-height: 1216px;
  display: flex;
  position: relative;
  top: -1px;
}

.logo-4 {
  gap: 2277px;
  min-width: 71.04px;
  height: 46.8px;
  display: flex;
  position: relative;
}

.bg-white-4 {
  width: 71.04px;
  height: 46.8px;
}

.about-us {
  opacity: 10px;
  width: fit-content;
  margin-top: 76px;
  margin-left: 1px;
  position: relative;
  transform: translate(0, 25px);
}

.about-us.animate-enter14 {
  opacity: 0;
  animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
  transform: translate(0, 25px);
}

.chip-3 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-left: 120px;
  display: flex;
  position: relative;
}

.line-2 {
  object-fit: cover;
  min-width: 32px;
  height: 1px;
  position: relative;
}

.about-us-1 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.frame-196 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: fit-content;
  display: flex;
  position: relative;
}

.image-people {
  background-color: var(--white);
  border-radius: 20px;
  width: 100px;
  min-width: 150px;
  height: 204px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.image-people .image-14 {
  z-index: 99;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.gif {
  background-color: var(--eerie-black);
  z-index: 10;
  width: 150px;
  height: 204px;
}

.image-people:hover .image-14 {
  cursor: pointer;
  object-fit: cover;
  width: 150px;
  height: 204px;
  transition: all .2s;
  display: inline;
}

.image-14:hover, .image-people:hover {
  transform: scale(1.1);
}

.we-are-alpha-encoded {
  letter-spacing: 0;
  text-align: center;
  width: 375px;
  margin-top: 20px;
  line-height: 54px;
  position: relative;
  font-size: 32px !important;
}

.define {
  letter-spacing: 0;
  text-align: center;
  width: 375px;
  margin-top: -25px;
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.we-are-a-digital-age-2 {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  opacity: 0;
  text-align: center;
  width: 350px;
  margin-top: -40px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  transform: translate(0, 25px);
}

.we-are-confident-tha-2 {
  letter-spacing: 0;
  text-align: center;
  width: 375px;
  line-height: 24px;
  position: relative;
}

.image {
  cursor: pointer;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  object-position: cover;
  background-size: cover;
  border-radius: 16px;
  width: 352px;
  height: 197px;
  margin-top: 32px;
  transition: all .2s;
}

.image:hover {
  transform: scale(1.1);
}

.frame-24-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 180px;
  display: flex;
  position: relative;
}

.names-mobile {
  flex-direction: row;
  display: flex;
}

.name-3 {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 32px;
  position: relative;
  font-size: 20px !important;
}

.ceo-and-founder {
  letter-spacing: 0;
  white-space: nowrap;
  white-space: pre-wrap;
  align-self: stretch;
  line-height: 28px;
  position: relative;
  font-size: 18px !important;
}

.group-307 {
  align-self: center;
  min-width: 1216px;
  height: 234px;
  margin-top: -380px;
  margin-bottom: 90px;
  display: flex;
  position: relative;
}

.content-6 {
  flex-direction: column;
  align-items: center;
  gap: 100px;
  width: 1216px;
  height: 434px;
  display: flex;
  position: relative;
}

.we-are-a-digital-age {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  opacity: 0;
  text-align: center;
  width: 350px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  transform: translate(0, 25px);
}

.section-our-team {
  flex-direction: column;
  align-items: center;
  gap: 0;
  min-width: 375px;
  height: 2350px;
  padding-top: 80px;
  display: flex;
  position: relative;
}

.our-team-1 {
  letter-spacing: 0;
  text-align: center;
  opacity: 0;
  white-space: nowrap;
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: fit-content;
  height: 74px;
  margin-top: 248px;
  margin-bottom: 40px;
  line-height: 74px;
  display: flex;
  transform: translate(0, 25px);
}

.our-team-1.animate-enter16 {
  opacity: 0;
  animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
  transform: translate(0, 25px);
}

.profile-team {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 350px;
  height: 62px;
  margin-left: 25px;
  display: flex;
  position: relative;
}

.profile-team-item {
  display: none;
}

.profile-team-item-mobile {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 25px;
  display: flex;
  position: relative;
}

.frame-2-2-1 {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  gap: 18px;
  width: 100px;
  display: flex;
  position: relative;
}

.frame-247 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 359px;
  display: flex;
  position: relative;
}

.names {
  display: none;
}

.balduino {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 32px;
  position: relative;
}

.creative-director {
  color: var(--white);
  font-family: var(--font-family-eudoxus_sans-regular);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

@keyframes animate-enter14-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes animate-enter15-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes animate-enter16-frames {
  from {
    opacity: 0;
    transform: translate(0, 25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@media screen and (width >= 368px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 375px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .7;
    width: 400.03px;
    height: 510.44px;
    position: absolute;
    top: 50px;
    left: 0;
  }

  .purple-about {
    filter: blur(60px);
    background: #5025bf;
    width: 256.4px;
    height: 296.51px;
    position: absolute;
    top: 180px;
    left: 60px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 403.03px;
    height: 290.43px;
    position: absolute;
    top: 100px;
    left: 25px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(60px);
    background: #5025bf;
    width: 350.92px;
    height: 271.78px;
    position: absolute;
    top: 527.22px;
    left: 1114.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(70px);
    background: #119291;
    width: 400px;
    height: 254.19px;
    position: absolute;
    top: 338px;
    left: 1100px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 400px;
    height: 241px;
    position: absolute;
    top: 1200px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 330px;
    height: 301px;
    position: absolute;
    top: 1651px;
    left: 1802px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 304.22px;
    height: 201.78px;
    position: absolute;
    top: 1350.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(70px);
    background: #119291;
    width: 430px;
    height: 250.19px;
    position: absolute;
    top: 1151px;
    left: 1652px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 188px;
    height: 301.89px;
    position: absolute;
    top: 11.11px;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 207px;
    height: 671.89px;
    position: absolute;
    top: 209.43px;
    left: 0;
  }

  .geo-about-bottom {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 297px;
    height: 671.89px;
    position: absolute;
    top: 1150.11px;
    left: 0;
  }

  .geo-about-right {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 225px;
    height: 305.89px;
    position: absolute;
    top: 1973.43px;
    left: 0;
  }

  .hero-section-2 {
    min-width: 375px;
    height: 979px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 768px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 35px;
    margin-left: 1px;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 100px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .image-people {
    background-color: var(--white);
    border-radius: 20px;
    width: 100px;
    min-width: 150px;
    height: 204px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .image-people .image-14 {
    z-index: 99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gif {
    background-color: var(--eerie-black);
    width: 150px;
    height: 204px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    object-fit: cover;
    width: 150px;
    height: 204px;
    transition: all .2s;
    display: inline;
  }

  .image-14:hover, .image-people:hover {
    transform: scale(1.1);
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 375px;
    margin-top: 20px;
    line-height: 54px;
    position: relative;
    font-size: 32px !important;
  }

  .define {
    letter-spacing: 0;
    text-align: center;
    width: 375px;
    margin-top: -25px;
    margin-bottom: 5px;
    line-height: 24px;
    position: relative;
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 370px;
    margin-top: -80px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 375px;
    line-height: 24px;
    position: relative;
  }

  .image {
    cursor: pointer;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    object-position: cover;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 385px;
    height: 300px;
    margin-top: 20px;
    transition: all .2s;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .frame-24-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 180px;
    display: flex;
    position: relative;
  }

  .names-mobile {
    flex-direction: row;
    display: flex;
  }

  .name-3 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
    font-size: 20px !important;
  }

  .ceo-and-founder {
    letter-spacing: 0;
    white-space: nowrap;
    white-space: pre-wrap;
    align-self: stretch;
    line-height: 28px;
    position: relative;
    font-size: 18px !important;
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -350px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 350px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    align-items: center;
    gap: 0;
    min-width: 375px;
    height: 2000px;
    padding-top: 80px;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: fit-content;
    height: 74px;
    margin-bottom: 40px;
    line-height: 74px;
    display: flex;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 350px;
    height: 62px;
    margin-left: 25px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    display: none;
  }

  .profile-team-item-mobile {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 25px;
    display: flex;
    position: relative;
  }

  .frame-2-2-1 {
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    gap: 18px;
    width: 100px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .names {
    display: none;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 768px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 768px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .7;
    width: 783.03px;
    height: 510.44px;
    position: absolute;
    top: 250px;
    left: 0;
  }

  .purple-about {
    filter: blur(70px);
    background: #5025bf;
    width: 450.4px;
    height: 296.51px;
    position: absolute;
    top: 200px;
    left: 200px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 703.03px;
    height: 340.43px;
    position: absolute;
    top: 50px;
    left: 50px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 730.92px;
    height: 205.78px;
    position: absolute;
    top: 527.22px;
    left: 1114.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(70px);
    background: #119291;
    width: 669px;
    height: 254.19px;
    position: absolute;
    top: 338px;
    left: 1052px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 469px;
    height: 241px;
    position: absolute;
    top: 1050px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 930px;
    height: 601px;
    position: absolute;
    top: 1101px;
    left: 1702px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 454.22px;
    height: 301.78px;
    position: absolute;
    top: 1220.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(70px);
    background: #119291;
    width: 530px;
    height: 354.19px;
    position: absolute;
    top: 981px;
    left: 1652px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 771.89px;
    position: absolute;
    top: 11.11px;
    left: 0;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 871.89px;
    position: absolute;
    top: 109.43px;
    left: 3px;
  }

  .geo-about-bottom {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 650px;
    position: absolute;
    top: 980.11px;
  }

  .geo-about-right {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 671.89px;
    position: absolute;
    top: 1353.43px;
    left: 1163px;
  }

  .hero-section-2 {
    min-width: 768px;
    height: 1279px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 768px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 76px;
    margin-left: 1px;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 220px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 700px;
    margin-top: -80px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 74px;
    position: relative;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 605px;
    line-height: 24px;
    position: relative;
  }

  .frame-2-2-1 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 18px;
    width: 200px;
    display: flex;
    position: relative;
  }

  .image-people {
    background-color: var(--white);
    border-radius: 20px;
    width: 130px;
    min-width: 229px;
    height: 304px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .image-people .image-14 {
    z-index: 99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gif {
    width: 229px;
    height: 304px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    object-fit: cover;
    width: 229px;
    height: 304px;
    transition: all .2s;
    display: inline;
  }

  .image-14:hover, .image-people:hover {
    transform: scale(1.1);
  }

  .image {
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 700px;
    height: 456px;
    margin-top: 82px;
    transition: all .2s;
  }

  .names-mobile {
    display: none;
  }

  .frame-24-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 282px;
    display: flex;
    position: relative;
  }

  .names {
    flex-direction: row;
    display: flex;
  }

  .name-3 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
    font-size: 28px !important;
  }

  .ceo-and-founder {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    line-height: 28px;
    position: relative;
    font-size: 20px !important;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -250px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 700px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    align-items: center;
    gap: 56px;
    min-width: 900px;
    height: 1600px;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: fit-content;
    height: 74px;
    margin-top: -50px;
    margin-bottom: 0;
    line-height: 74px;
    display: flex;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 52px;
    width: 768px;
    height: 2162px;
    margin-left: 25px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    align-self: stretch;
    align-items: center;
    gap: 50px;
    display: flex;
    position: relative;
  }

  .profile-team-item-mobile {
    display: none;
  }

  .frame-250 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 992px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 992px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .7;
    width: 983.03px;
    height: 510.44px;
    position: absolute;
    top: 200px;
    left: 88px;
  }

  .purple-about {
    filter: blur(70px);
    background: #5025bf;
    width: 506.4px;
    height: 256.51px;
    position: absolute;
    top: 240px;
    left: 150px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 750.03px;
    height: 395.43px;
    position: absolute;
    top: 84px;
    left: 40px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 730.92px;
    height: 271.78px;
    position: absolute;
    top: 527.22px;
    left: 1114.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(70px);
    background: #119291;
    width: 969px;
    height: 254.19px;
    position: absolute;
    top: 338px;
    left: 992px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 569px;
    height: 241px;
    position: absolute;
    top: 958px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 930px;
    height: 601px;
    position: absolute;
    top: 1051px;
    left: 1902px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 504.22px;
    height: 301.78px;
    position: absolute;
    top: 1320.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(70px);
    background: #119291;
    width: 730px;
    height: 404.19px;
    position: absolute;
    top: 1081px;
    left: 1652px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 771.89px;
    position: absolute;
    top: 11.11px;
    left: 0;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 871.89px;
    position: absolute;
    top: 109.43px;
    left: 3px;
  }

  .geo-about-bottom {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 650px;
    position: absolute;
    top: 1110.11px;
  }

  .geo-about-right {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 671.89px;
    position: absolute;
    top: 1453.43px;
    left: 1163px;
  }

  .hero-section-2 {
    min-width: 1440px;
    height: 1279px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 980px;
    margin-top: -80px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 76px;
    margin-left: 1px;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 225px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 74px;
    position: relative;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 605px;
    line-height: 24px;
    position: relative;
  }

  .names-mobile {
    display: none;
  }

  .frame-24-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 312px;
    display: flex;
    position: relative;
  }

  .names {
    flex-direction: row;
    display: flex;
  }

  .name-3 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
    font-size: 28px !important;
  }

  .ceo-and-founder {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    line-height: 28px;
    position: relative;
    font-size: 20px !important;
  }

  .image {
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 850px;
    height: 496px;
    margin-top: 82px;
    transition: all .2s;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -250px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 980px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    align-items: center;
    gap: 56px;
    min-width: 900px;
    height: 1450px;
    padding-top: 0;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    width: fit-content;
    height: 74px;
    margin-top: -50px;
    margin-bottom: 0;
    line-height: 74px;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 52px;
    width: 992px;
    height: 2162px;
    margin-left: 112px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    align-self: stretch;
    align-items: center;
    gap: 80px;
    display: flex;
    position: relative;
  }

  .profile-team-item2 {
    display: none;
  }

  .frame-250 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 1200px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .8;
    width: 1283.03px;
    height: 510.44px;
    position: absolute;
    top: 150px;
    left: 58px;
  }

  .purple-about {
    filter: blur(70px);
    background: #5025bf;
    width: 606.4px;
    height: 406.51px;
    position: absolute;
    top: 300px;
    left: 50px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 1050.03px;
    height: 440.43px;
    position: absolute;
    top: 94px;
    left: 30px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 530.92px;
    height: 271.78px;
    position: absolute;
    top: 527.22px;
    left: 1214.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(70px);
    background: #119291;
    width: 869px;
    height: 254.19px;
    position: absolute;
    top: 338px;
    left: 992px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 769px;
    height: 241px;
    position: absolute;
    top: 958px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 930px;
    height: 601px;
    position: absolute;
    top: 1151px;
    left: 2202px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 554.22px;
    height: 301.78px;
    position: absolute;
    top: 1220.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(70px);
    background: #119291;
    width: 730px;
    height: 354.19px;
    position: absolute;
    top: 981px;
    left: 1652px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 771.89px;
    position: absolute;
    top: 11.11px;
    left: 0;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 871.89px;
    position: absolute;
    top: 109.43px;
    left: 3px;
  }

  .geo-about-bottom {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 650px;
    position: absolute;
    top: 1110.11px;
  }

  .geo-about-right {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 671.89px;
    position: absolute;
    top: 1353.43px;
    left: 1163px;
  }

  .hero-section-2 {
    min-width: 1440px;
    height: 1279px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 86px;
    margin-left: 1px;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 225px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 0;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 74px;
    position: relative;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 605px;
    line-height: 24px;
    position: relative;
  }

  .image {
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 1026px;
    height: 496px;
    margin-top: 50px;
    transition: all .2s;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -280px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .frame-2-2-1 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1200px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1216px;
    margin-top: -80px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    gap: 56px;
    min-width: 1440px;
    height: 1600px;
    padding-top: 0;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    width: fit-content;
    height: 74px;
    margin-top: -50px;
    margin-bottom: 0;
    line-height: 74px;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 72px;
    width: 920px;
    height: 2162px;
    margin-left: 112px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    align-self: stretch;
    align-items: center;
    gap: 70px;
    display: flex;
    position: relative;
  }

  .frame-250 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  .image-people {
    background-color: var(--white);
    border-radius: 20px;
    width: 130px;
    min-width: 229px;
    height: 304px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .image-people .image-14 {
    z-index: 99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gif {
    width: 229px;
    height: 304px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    object-fit: cover;
    width: 229px;
    height: 304px;
    transition: all .2s;
    display: inline;
  }

  .image-14:hover, .image-people:hover {
    transform: scale(1.1);
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 1280px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .7;
    width: 1283.03px;
    height: 510.44px;
    position: absolute;
    top: 150px;
    left: 58px;
  }

  .purple-about {
    filter: blur(70px);
    background: #5025bf;
    width: 606.4px;
    height: 206.51px;
    position: absolute;
    top: 300px;
    left: 400px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 1000px;
    height: 440.43px;
    position: absolute;
    top: 125px;
    left: 180px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(60px);
    background: #5025bf;
    width: 530.92px;
    height: 271.78px;
    position: absolute;
    top: 527.22px;
    left: 1214.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(60px);
    background: #119291;
    width: 869px;
    height: 254.19px;
    position: absolute;
    top: 338px;
    left: 1092px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 769px;
    height: 241px;
    position: absolute;
    top: 958px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 930px;
    height: 601px;
    position: absolute;
    top: 1151px;
    left: 2202px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(60px);
    background: #5025bf;
    width: 554.22px;
    height: 251.78px;
    position: absolute;
    top: 1120.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(50px);
    background: #119291;
    width: 730px;
    height: 354.19px;
    position: absolute;
    top: 981px;
    left: 1652px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 771.89px;
    position: absolute;
    top: 11.11px;
    left: 0;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 871.89px;
    position: absolute;
    top: 109.43px;
    left: 3px;
  }

  .geo-about-bottom {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 650px;
    position: absolute;
    top: 1110.11px;
  }

  .geo-about-right {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 671.89px;
    position: absolute;
    top: 1353.43px;
    left: 1163px;
  }

  .hero-section-2 {
    min-width: 1440px;
    height: 1279px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 86px;
    margin-left: 0;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 220px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 0;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 74px;
    position: relative;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 605px;
    line-height: 24px;
    position: relative;
  }

  .image {
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 1026px;
    height: 496px;
    margin-top: 50px;
    transition: all .2s;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -280px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .frame-2-2-1 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1200px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1216px;
    margin-top: -40px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    gap: 56px;
    min-width: 1440px;
    height: 1800px;
    padding-top: 0;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    width: fit-content;
    height: 74px;
    margin-top: -50px;
    margin-bottom: 0;
    line-height: 74px;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 72px;
    width: 920px;
    height: 2162px;
    margin-left: 112px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    align-self: stretch;
    align-items: center;
    gap: 70px;
    display: flex;
    position: relative;
  }

  .frame-250 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  .image-people {
    background-color: var(--white);
    border-radius: 20px;
    width: 130px;
    min-width: 229px;
    height: 304px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .image-people .image-14 {
    z-index: 99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gif {
    width: 229px;
    height: 304px;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    object-fit: cover;
    width: 229px;
    height: 304px;
    transition: all .2s;
    display: inline;
  }

  .image-14:hover, .image-people:hover {
    transform: scale(1.1);
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 1600px) {
  .about-page {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: center;
    min-width: 1440px;
    display: flex;
    position: relative;
  }

  .top-grad {
    opacity: .7;
    width: 1283.03px;
    height: 510.44px;
    position: absolute;
    top: 150px;
    left: 200px;
  }

  .purple-about {
    filter: blur(70px);
    background: #5025bf;
    width: 656.4px;
    height: 496.51px;
    position: absolute;
    top: 280px;
    left: 390px;
    transform: translate3d(0, 0, 0);
  }

  .green-about {
    filter: blur(70px);
    background: #119291;
    width: 1000.03px;
    height: 425.43px;
    position: absolute;
    top: 120px;
    left: 200px;
    transform: translate3d(0, 0, 0);
  }

  .about-purple {
    filter: blur(70px);
    background: #5025bf;
    width: 630.92px;
    height: 251.78px;
    position: absolute;
    top: 527.22px;
    left: 1214.31px;
    transform: translate3d(0, 0, 0);
  }

  .about-green {
    filter: blur(70px);
    background: #119291;
    width: 640px;
    height: 254.19px;
    position: absolute;
    top: 288px;
    left: 1250px;
    transform: translate3d(0, 0, 0);
  }

  .about-left {
    opacity: .7;
    width: 769px;
    height: 241px;
    position: absolute;
    top: 958px;
    left: 972px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-right {
    opacity: .7;
    width: 930px;
    height: 601px;
    position: absolute;
    top: 1151px;
    left: 2602px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .about-purple2 {
    filter: blur(70px);
    background: #5025bf;
    width: 654.22px;
    height: 301.78px;
    position: absolute;
    top: 1280.22px;
    left: 1703.07px;
    transform: translate3d(0, 0, 0);
  }

  .about-green2 {
    filter: blur(70px);
    background: #119291;
    width: 830px;
    height: 354.19px;
    position: absolute;
    top: 921px;
    left: 1602px;
    transform: translate3d(0, 0, 0);
  }

  .geo-about-top {
    z-index: 1;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoTopRight.01a22c99.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 771.89px;
    position: absolute;
    top: 11.11px;
    left: 0;
  }

  .geo-about-left {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeoBotLeft.01f229db.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 871.89px;
    position: absolute;
    top: 109.43px;
    left: 3px;
  }

  .geo-about-bottom {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutLeftGeo.79a05db3.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 697px;
    height: 650px;
    position: absolute;
    top: 1110.11px;
  }

  .geo-about-right {
    z-index: 0;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("aboutGeoRight.7e1dff4b.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 497px;
    height: 671.89px;
    position: absolute;
    top: 1353.43px;
    left: 1163px;
  }

  .hero-section-2 {
    min-width: 1440px;
    height: 1279px;
    position: relative;
  }

  .flex-col-3 {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    min-height: 1216px;
    display: flex;
    position: relative;
    top: -1px;
  }

  .logo-4 {
    gap: 2277px;
    min-width: 71.04px;
    height: 46.8px;
    display: flex;
    position: relative;
  }

  .bg-white-4 {
    width: 71.04px;
    height: 46.8px;
  }

  .about-us {
    opacity: 0;
    width: fit-content;
    margin-top: 76px;
    margin-left: 1px;
    position: relative;
    transform: translate(0, 25px);
  }

  .about-us.animate-enter14 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter14-frames;
    transform: translate(0, 25px);
  }

  .chip-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-left: 225px;
    display: flex;
    position: relative;
  }

  .line-2 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .about-us-1 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .frame-196 {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-alpha-encoded {
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: 74px;
    position: relative;
    font-size: 54px !important;
  }

  .we-are-confident-tha-2 {
    letter-spacing: 0;
    text-align: center;
    width: 605px;
    line-height: 24px;
    position: relative;
  }

  .image {
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 16px;
    width: 1026px;
    height: 496px;
    margin-top: 82px;
    transition: all .2s;
  }

  .image:hover {
    transform: scale(1.1);
  }

  .group-307 {
    align-self: center;
    min-width: 1216px;
    height: 234px;
    margin-top: -250px;
    margin-bottom: 35px;
    display: flex;
    position: relative;
  }

  .content-6 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    width: 1216px;
    height: 434px;
    display: flex;
    position: relative;
  }

  .number-statistic {
    align-items: flex-end;
    gap: 132px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .we-are-a-digital-age {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1216px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .we-are-a-digital-age-2 {
    color: var(--white);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    opacity: 0;
    text-align: center;
    width: 1216px;
    margin-top: -80px;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    transform: translate(0, 25px);
  }

  .section-our-team {
    flex-direction: column;
    gap: 56px;
    min-width: 1440px;
    height: 1850px;
    padding-top: 0;
    display: flex;
    position: relative;
  }

  .our-team-1 {
    letter-spacing: 0;
    text-align: center;
    opacity: 0;
    white-space: nowrap;
    flex-direction: row;
    width: fit-content;
    height: 74px;
    margin-top: -60px;
    margin-bottom: 0;
    line-height: 74px;
    transform: translate(0, 25px);
  }

  .our-team-1.animate-enter16 {
    opacity: 0;
    animation: 1.5s ease-in-out 1s forwards animate-enter16-frames;
    transform: translate(0, 25px);
  }

  .profile-team {
    flex-direction: column;
    align-items: flex-start;
    gap: 72px;
    width: 1217px;
    height: 2162px;
    margin-left: 112px;
    display: flex;
    position: relative;
  }

  .profile-team-item {
    align-self: stretch;
    align-items: flex-start;
    gap: 70px;
    display: flex;
    position: relative;
  }

  .frame-250 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 28px;
    display: flex;
    position: relative;
  }

  .frame-247 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 359px;
    display: flex;
    position: relative;
  }

  .balduino {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
  }

  .creative-director {
    color: var(--white);
    font-family: var(--font-family-eudoxus_sans-regular);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }

  .profile-team-item-mobile {
    display: none;
  }

  .image-people {
    background-color: var(--white);
    border-radius: 20px;
    width: 130px;
    min-width: 329px;
    height: 394px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .image-people .image-14 {
    z-index: 99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gif {
    width: 329px;
    height: 394px;
  }

  .names-mobile {
    display: none;
  }

  .frame-24-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 400px;
    display: flex;
    position: relative;
  }

  .names {
    flex-direction: row;
    display: flex;
  }

  .name-3 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 32px;
    position: relative;
    font-size: 28px !important;
  }

  .modal .ceo-and-founder {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    line-height: 28px;
    position: relative;
    font-size: 20px !important;
  }

  .image-people:hover .image-14 {
    cursor: pointer;
    object-fit: cover;
    width: 329px;
    height: 394px;
    transition: all .2s;
    display: inline;
  }

  .image-14:hover, .image-people:hover {
    transform: scale(1.1);
  }

  @keyframes animate-enter14-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter15-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter16-frames {
    from {
      opacity: 0;
      transform: translate(0, 25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

.contact-us-4 {
  background-color: var(--black);
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-button {
  text-align: center;
  font-family: var(--font-family-switzer-thin);
  color: #00d7c3;
  margin-top: 20px;
}

.modal-button:hover {
  text-decoration: underline;
}

.hero-seccction {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  display: flex;
}

.flex-col-4 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  display: flex;
  position: relative;
}

.headline {
  opacity: 0;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 330px;
  position: relative;
  top: 0;
  transform: translate(-25px);
}

.headline.animate-enter17 {
  opacity: 0;
  animation: 1.5s ease-in-out 1s forwards animate-enter17-frames;
  transform: translate(-25px);
}

.chip-4 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  display: flex;
  position: relative;
}

.line-1-1 {
  object-fit: cover;
  min-width: 32px;
  height: 1px;
  position: relative;
}

.contact-us-5 {
  color: var(--portage);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  position: relative;
}

.contactInfo {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
  display: flex;
}

.enter-your-email {
  background-color: var(--white-2);
  border: none;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 30%;
  margin-top: 40px;
  padding: 20px 29px 22px;
  display: flex;
  overflow: hidden;
}

.labelName {
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  margin-bottom: -185px;
  display: flex;
}

.company-name-space {
  background-color: var(--white-2);
  border: none;
  border-radius: 20px;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 30%;
  margin-top: -20px;
  padding: 20px 24px 22px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.label-company {
  align-items: flex-start;
  width: 70%;
  margin-bottom: 40px;
  position: relative;
}

.label-name {
  align-items: flex-start;
  gap: 10px;
  width: 70%;
  margin-bottom: -45px;
  position: relative;
}

.enter-your-name {
  background-color: var(--white-2);
  border: none;
  border-radius: 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 30%;
  padding: 20px 24px 22px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.label-name {
  padding-bottom: 20px;
}

.tell-us-about-your-project-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 620px;
  height: 200px;
  display: flex;
  position: relative;
}

.input-field-2 {
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 45%;
  display: flex;
  position: relative;
}

.tell-us-about-your-project-2 {
  background-color: var(--white-2);
  border: none;
  border-radius: 20px;
  gap: 20px;
  width: 300px;
  height: 350px;
  position: relative;
}

textarea, textarea:focus {
  text-indent: 10px;
  outline: none;
  padding-top: 5px;
}

.lets-talk-about-you {
  letter-spacing: 0;
  line-height: 54px;
  font-family: var(--font-family-switzer-bold);
  width: 100%;
  position: relative;
  color: var(--white) !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.section-form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
  height: 1580px;
  display: flex;
  position: relative;
}

.form-contact-us {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 375px;
  height: 1334px;
  margin-top: -150px;
  display: flex;
  position: relative;
}

.data, .tell-us-about-your-project {
  background-color: var(--black-3);
  opacity: 0;
  border-radius: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 60px 40px;
  position: relative;
  transform: translate(25px);
}

.content-8 {
  flex-direction: column;
  gap: 44px;
  width: 1000px;
  display: flex;
  position: relative;
}

.content-9 {
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  display: flex;
  position: relative;
}

.modal-text {
  text-align: center;
  line-height: 28px;
}

.types-of-work {
  background-color: var(--black-3);
  opacity: 0;
  border-radius: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 500px;
  padding: 60px 40px;
  position: relative;
  transform: translate(-25px);
}

.content-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 317px;
  display: flex;
  position: relative;
}

.types-of-work-1 {
  letter-spacing: 0;
  overflow-wrap: break-word;
  align-self: stretch;
  margin-top: -1px;
  line-height: 28px;
  position: relative;
}

.service-choice, .frame-238 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 22px;
  display: flex;
  position: relative;
}

.mobile-app-development-2 {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px 22px;
  display: flex;
  position: relative;
}

.web-development-2 {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 63px 22px;
  display: flex;
  position: relative;
}

.buttonActive {
  color: #000;
  background-color: #fff;
}

.web-development-3 {
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  white-space: nowrap;
  width: 300px;
  height: 50px;
  margin-top: -1px;
  font-weight: 500;
  line-height: 28px;
  position: relative;
}

.service-choice-item {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  padding: 20px 118px 22px;
  display: flex;
  position: relative;
}

.marketing-1 {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  padding: 20px 102px 22px;
  display: flex;
  position: relative;
}

.mobile-app-development-2, .marketing-1, .design-1, .text-8, .text-11 {
  letter-spacing: 0;
  white-space: nowrap;
  align-items: center;
  width: 300px;
  height: 50px;
  margin-top: -1px;
  line-height: 28px;
  position: relative;
}

small {
  text-indent: 0;
  font-size: 75%;
  font-family: var(--font-family-switzer-thin);
  font-weight: lighter;
}

.frame-240 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 686px;
  display: flex;
  position: relative;
}

.button-6 {
  background-color: var(--portage);
  opacity: 0;
  border-radius: 12px;
  align-items: center;
  width: 200px;
  height: 64px;
  margin-top: 30px;
  display: flex;
  position: relative;
}

.send-request {
  white-space: nowrap;
  align-self: center;
  width: fit-content;
  margin-top: -1.5px;
  padding-left: 75px;
  line-height: 24px;
}

.geomtris-right {
  opacity: .42;
  filter: drop-shadow(0 4px 4px #00000040);
  background-image: url("GeomtrisRight.2c44c687.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 471.89px;
  position: absolute;
  top: 1657.43px;
  left: 1165px;
}

@media screen and (width >= 768px) {
  .contact-us-4 {
    background-color: var(--black);
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    border: 1px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .hero-seccction {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    display: flex;
  }

  .flex-col-4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    display: flex;
    position: relative;
  }

  .headline {
    opacity: 0;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    position: relative;
    top: 0;
    transform: translate(-25px);
    font-size: 32px !important;
  }

  .chip-4 {
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .line-1-1 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    letter-spacing: 0;
    white-space: nowrap;
    width: 100%;
    margin-top: -1px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .contactInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0;
    display: flex;
  }

  .enter-your-email {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 60%;
    margin-top: 40px;
    padding: 20px 29px 22px;
    display: flex;
    overflow: hidden;
  }

  .labelName {
    flex-direction: column;
    align-items: flex-start;
    gap: 120px;
    margin-bottom: -185px;
    display: flex;
  }

  .company-name-space {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    width: 60%;
    margin-top: -20px;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-company {
    align-items: flex-start;
    width: 70%;
    margin-bottom: 40px;
    position: relative;
  }

  .label-name {
    align-items: flex-start;
    gap: 10px;
    width: 70%;
    margin-bottom: -45px;
    position: relative;
  }

  .enter-your-name {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 60%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 620px;
    height: 200px;
    display: flex;
    position: relative;
  }

  .input-field-2 {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    display: flex;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    gap: 20px;
    width: 620px;
    height: 300px;
    position: relative;
  }

  textarea, textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    width: 716px;
    line-height: 74px;
    position: relative;
    color: var(--white) !important;
    font-family: var(--font-family-switzer-bold) !important;
    font-size: var(--font-size-xxl) !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }

  .section-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: 1680px;
    display: flex;
    position: relative;
  }

  .form-contact-us {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 700px;
    height: 1334px;
    margin-top: -200px;
    display: flex;
    position: relative;
  }

  .data, .tell-us-about-your-project {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px);
  }

  .content-8 {
    flex-direction: column;
    gap: 44px;
    width: 1000px;
    display: flex;
    position: relative;
  }

  .content-9 {
    align-self: stretch;
    align-items: flex-start;
    gap: 14px;
    display: flex;
    position: relative;
  }

  .types-of-work {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    height: 500px;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px);
  }

  .content-10 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 517px;
    display: flex;
    position: relative;
  }

  .types-of-work-1 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .service-choice, .frame-238 {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 22px;
    display: flex;
    position: relative;
  }

  .mobile-app-development-2, .web-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .buttonActive {
    color: #000;
    background-color: #fff;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    height: 60px;
    margin-top: -1px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
    width: 620px !important;
  }

  .service-choice-item, .marketing-1 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    display: flex;
    position: relative;
    width: 620px !important;
  }

  .mobile-app-development-2, .marketing-1, .design-1, .text-8, .text-11 {
    letter-spacing: 0;
    white-space: nowrap;
    height: 60px;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
    width: 620px !important;
  }

  small {
    text-indent: 0;
    font-size: 75%;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }

  .frame-240 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 686px;
    display: flex;
    position: relative;
  }

  .button-6 {
    background-color: var(--portage);
    opacity: 0;
    border-radius: 12px;
    align-items: center;
    width: 200px;
    height: 64px;
    margin-top: 30px;
    margin-left: 505px;
    display: flex;
    position: relative;
  }

  .send-request {
    white-space: nowrap;
    align-self: center;
    width: fit-content;
    margin-top: -1.5px;
    padding-left: 75px;
    line-height: 24px;
  }

  .geomtris-right {
    opacity: .42;
    filter: drop-shadow(0 4px 4px #00000040);
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 471.89px;
    position: absolute;
    top: 1657.43px;
    left: 1165px;
  }
}

@media screen and (width >= 992px) {
  .contact-us-4 {
    background-color: var(--black);
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    border: 1px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .hero-seccction {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    display: flex;
  }

  .flex-col-4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
    display: flex;
    position: relative;
  }

  .headline {
    opacity: 0;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 50px;
    position: relative;
    top: 0;
    transform: translate(-25px);
  }

  .chip-4 {
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .line-1-1 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    letter-spacing: 0;
    white-space: nowrap;
    width: 100%;
    margin-top: -1px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .contactInfo {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0;
    display: flex;
  }

  .enter-your-email {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    margin-top: 0;
    padding: 20px 29px 22px;
    display: flex;
    overflow: hidden;
  }

  .labelName {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
    display: flex;
  }

  .mobile-app-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    padding: 20px 25px 22px !important;
  }

  .company-name-space {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }

  .label-name {
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    position: relative;
  }

  .enter-your-name {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 1000px;
    height: 200px;
    display: flex;
    position: relative;
  }

  .input-field-2 {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    display: flex;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    gap: 20px;
    width: 800px;
    height: 300px;
    position: relative;
  }

  textarea, textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    width: 990px;
    line-height: 74px;
    position: relative;
    color: var(--white) !important;
    font-family: var(--font-family-switzer-bold) !important;
    font-size: 54px !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }

  .section-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: 1580px;
    display: flex;
    position: relative;
  }

  .form-contact-us {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 900px;
    height: 1334px;
    margin-top: -300px;
    display: flex;
    position: relative;
  }

  .data, .tell-us-about-your-project {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px);
  }

  .content-8 {
    flex-direction: column;
    gap: 44px;
    width: 800px;
    display: flex;
    position: relative;
  }

  .content-9 {
    align-self: stretch;
    align-items: flex-start;
    gap: 64px;
    display: flex;
    position: relative;
  }

  .types-of-work {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    height: 250px;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px);
  }

  .content-10 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 800px;
    display: flex;
    position: relative;
  }

  .types-of-work-1 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .service-choice, .frame-238 {
    flex-direction: row;
    align-self: stretch;
    align-items: flex-start;
    gap: 5px;
    display: flex;
    position: relative;
  }

  .web-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: 253px;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .buttonActive {
    color: #000;
    background-color: #fff;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    width: fit-content;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .mobile-app-development-2, .marketing-1, .design-1, .text-8, .text-11 {
    letter-spacing: 0;
    white-space: nowrap;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
    width: fit-content !important;
  }

  .frame-240 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 700px;
    display: flex;
    position: relative;
  }

  .button-6 {
    background-color: var(--portage);
    opacity: 0;
    border-radius: 12px;
    align-items: center;
    width: 200px;
    height: 64px;
    margin-top: -300px;
    margin-left: 675px;
    display: flex;
    position: relative;
  }

  .send-request {
    white-space: nowrap;
    align-self: center;
    width: fit-content;
    margin-top: -1.5px;
    padding-left: 75px;
    line-height: 24px;
  }

  .geomtris-right {
    opacity: .42;
    filter: drop-shadow(0 4px 4px #00000040);
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 471.89px;
    position: absolute;
    top: 1657.43px;
    left: 1165px;
  }
}

@media screen and (width >= 1200px) {
  .contact-us-4 {
    background-color: var(--black);
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    border: 1px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .hero-seccction {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    display: flex;
  }

  .flex-col-4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 150px;
    display: flex;
    position: relative;
  }

  .headline {
    opacity: 0;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    position: relative;
    top: 0;
    transform: translate(-25px);
  }

  .chip-4 {
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .mobile-app-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px 35px 22px;
    display: flex;
    position: relative;
  }

  .line-1-1 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .contact-us-5 {
    letter-spacing: 0;
    white-space: nowrap;
    width: 100%;
    margin-top: -1px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .contactInfo {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0;
    display: flex;
  }

  .enter-your-email {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    margin-top: 0;
    padding: 20px 29px 22px;
    display: flex;
    overflow: hidden;
  }

  .labelName {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
    display: flex;
  }

  .company-name-space {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }

  .label-name {
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    position: relative;
  }

  .enter-your-name {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 1000px;
    height: 200px;
    display: flex;
    position: relative;
  }

  .input-field-2 {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    display: flex;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    gap: 20px;
    width: 1000px;
    height: 300px;
    position: relative;
  }

  textarea, textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    width: 1216px;
    line-height: 74px;
    position: relative;
  }

  .section-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: 1540px;
    margin-top: 20px;
    display: flex;
    position: relative;
  }

  .form-contact-us {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 1080px;
    height: 1334px;
    margin-top: -300px;
    display: flex;
    position: relative;
  }

  .data, .tell-us-about-your-project {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px);
  }

  .content-8 {
    flex-direction: column;
    gap: 44px;
    width: 1000px;
    display: flex;
    position: relative;
  }

  .content-9 {
    align-self: stretch;
    align-items: flex-start;
    gap: 64px;
    display: flex;
    position: relative;
  }

  .types-of-work {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    height: 250px;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px);
  }

  .content-10 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 917px;
    display: flex;
    position: relative;
  }

  .types-of-work-1 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .service-choice, .frame-238 {
    flex-direction: row;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .web-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: 253px;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .buttonActive {
    color: #000;
    background-color: #fff;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    width: fit-content;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .mobile-app-development-2, .marketing-1, .design-1, .text-8, .text-11 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .frame-240 {
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    width: 886px;
    display: flex;
    position: relative;
  }

  .button-6 {
    background-color: var(--portage);
    opacity: 0;
    border-radius: 12px;
    align-items: center;
    width: 200px;
    height: 64px;
    margin-top: -300px;
    margin-left: 875px;
    display: flex;
    position: relative;
  }

  .send-request {
    white-space: nowrap;
    align-self: center;
    width: fit-content;
    margin-top: -1.5px;
    padding-left: 75px;
    line-height: 24px;
  }

  .geomtris-right {
    opacity: .42;
    filter: drop-shadow(0 4px 4px #00000040);
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 471.89px;
    position: absolute;
    top: 1657.43px;
    left: 1165px;
  }

  small {
    text-indent: 0;
    font-size: 75%;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }

  @keyframes animate-enter19-frames {
    from {
      opacity: 0;
      transform: translate(-25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

@media screen and (width >= 1400px) {
  .contact-us-4 {
    background-color: var(--black);
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    border: 1px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .hero-seccction {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    min-height: 250px;
    margin-top: 75px;
    display: flex;
  }

  .flex-col-4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    display: flex;
    position: relative;
  }

  .headline {
    opacity: 0;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    position: relative;
    top: 0;
    transform: translate(-25px);
  }

  .chip-4 {
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .line-1-1 {
    object-fit: cover;
    min-width: 32px;
    height: 1px;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    letter-spacing: 0;
    white-space: nowrap;
    width: 100%;
    margin-top: -1px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .contactInfo {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0;
    display: flex;
  }

  .enter-your-email {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    margin-top: 0;
    padding: 20px 29px 22px;
    display: flex;
    overflow: hidden;
  }

  .labelName {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
    display: flex;
  }

  .company-name-space {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }

  .label-name {
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    position: relative;
  }

  .enter-your-name {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 48.5%;
    padding: 20px 24px 22px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .label-name {
    padding-bottom: 20px;
    font-family: var(--font-family-space_grotesk) !important;
  }

  .tell-us-about-your-project-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 1000px;
    height: 200px;
    display: flex;
    position: relative;
  }

  .input-field-2 {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    display: flex;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    background-color: var(--white-2);
    border: none;
    border-radius: 20px;
    gap: 20px;
    width: 1000px;
    height: 300px;
    position: relative;
  }

  textarea, textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    width: 1216px;
    line-height: 74px;
    position: relative;
  }

  .section-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: 1580px;
    display: flex;
    position: relative;
  }

  .form-contact-us {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 1080px;
    height: 1334px;
    margin-top: -300px;
    display: flex;
    position: relative;
  }

  .data, .tell-us-about-your-project {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px);
  }

  .content-8 {
    flex-direction: column;
    gap: 44px;
    width: 1000px;
    display: flex;
    position: relative;
  }

  .content-9 {
    align-self: stretch;
    align-items: flex-start;
    gap: 64px;
    display: flex;
    position: relative;
  }

  .types-of-work {
    background-color: var(--black-3);
    opacity: 0;
    border-radius: 20px;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px);
  }

  .content-10 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 917px;
    display: flex;
    position: relative;
  }

  .types-of-work-1 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .service-choice, .frame-238 {
    flex-direction: row;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .web-development-2 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: 253px;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .buttonActive {
    color: #000;
    background-color: #fff;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    justify-content: center;
    width: fit-content;
    padding: 20px 32px 22px;
    display: flex;
    position: relative;
  }

  .mobile-app-development-2, .marketing-1, .design-1, .text-8, .text-11 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .frame-240 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 886px;
    display: flex;
    position: relative;
  }

  .button-6 {
    background-color: var(--portage);
    opacity: 0;
    border-radius: 12px;
    align-items: center;
    width: 200px;
    height: 64px;
    margin-top: -300px;
    margin-left: 875px;
    display: flex;
    position: relative;
  }

  .send-request {
    white-space: nowrap;
    align-self: center;
    width: fit-content;
    margin-top: -1.5px;
    padding-left: 75px;
    line-height: 24px;
  }

  .geomtris-right {
    opacity: .42;
    filter: drop-shadow(0 4px 4px #00000040);
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 471.89px;
    position: absolute;
    top: 1657.43px;
    left: 1165px;
  }

  small {
    text-indent: 0;
    font-size: 75%;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }
}

.purple-blur2 {
  filter: blur(70px);
  background: #5025bf;
  width: 200.92px;
  height: 251.78px;
  position: absolute;
  top: 1150.22px;
  left: 924.31px;
  transform: translate3d(0, 0, 0);
}

.green-blur2 {
  filter: blur(70px);
  background: #119291;
  width: 493px;
  height: 350px;
  position: absolute;
  top: 990px;
  left: 850px;
  transform: translate3d(0, 0, 0);
}

.gradient-blur {
  opacity: .7;
  width: 1693px;
  height: 494px;
  display: flex;
  position: absolute;
  top: 500px;
  left: -829px;
}

@media screen and (width >= 368px) {
  .purple-blur2 {
    filter: blur(50px);
    background: #5025bf;
    width: 200.92px;
    height: 251.78px;
    position: absolute;
    top: 1150.22px;
    left: 924.31px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur2 {
    filter: blur(70px);
    background: #119291;
    width: 493px;
    height: 350px;
    position: absolute;
    top: 990px;
    left: 850px;
    transform: translate3d(0, 0, 0);
  }

  .gradient-blur {
    opacity: .7;
    width: 1693px;
    height: 494px;
    position: absolute;
    top: 500px;
    left: -829px;
  }
}

@media screen and (width >= 768px) {
  .purple-blur2 {
    filter: blur(70px);
    background: #5025bf;
    width: 400.92px;
    height: 321.78px;
    position: absolute;
    top: 920.22px;
    left: 950.31px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur2 {
    filter: blur(70px);
    background: #119291;
    width: 793px;
    height: 550px;
    position: absolute;
    top: 700px;
    left: 850px;
    transform: translate3d(0, 0, 0);
  }

  .gradient-blur {
    opacity: .7;
    width: 1693px;
    height: 494px;
    display: flex;
    position: absolute;
    top: 500px;
    left: -629px;
  }

  .purple-blur {
    filter: blur(60px);
    background: #5025bf;
    width: 406.12px;
    height: 502.13px;
    position: absolute;
    top: -350px;
    right: 389.59px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur {
    filter: blur(70px);
    background: #119291;
    width: 405.48px;
    height: 300.18px;
    position: absolute;
    top: 20px;
    right: 405px;
    transform: translate3d(0, 0, 0);
  }

  .left-blur {
    opacity: .7;
    width: 566.48px;
    position: absolute;
    top: 175.06px;
    left: 108.4px;
  }
}

@media screen and (width >= 992px) {
  .purple-blur2 {
    filter: blur(60px);
    background: #5025bf;
    width: 350.92px;
    height: 321.78px;
    position: absolute;
    top: 920.22px;
    left: 924.31px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur2 {
    filter: blur(70px);
    background: #119291;
    width: 993px;
    height: 500px;
    position: absolute;
    top: 690px;
    left: 800px;
    transform: translate3d(0, 0, 0);
  }

  .gradient-blur {
    opacity: .7;
    width: 993px;
    height: 494px;
    position: absolute;
    top: 500px;
    left: -429px;
  }

  .purple-blur {
    filter: blur(70px);
    background: #5025bf;
    width: 406.12px;
    height: 420.13px;
    position: absolute;
    top: -350px;
    right: 489.59px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur {
    filter: blur(70px);
    background: #119291;
    width: 405.48px;
    height: 219.18px;
    position: absolute;
    top: -40px;
    right: 495px;
    transform: translate3d(0, 0, 0);
  }

  .left-blur {
    opacity: .7;
    width: 666.48px;
    position: absolute;
    top: 175.06px;
    left: 108.4px;
  }
}

@media screen and (width >= 1200px) {
  .purple-blur2 {
    filter: blur(70px);
    background: #5025bf;
    width: 350.92px;
    height: 321.78px;
    position: absolute;
    top: 920.22px;
    left: 1224.31px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur2 {
    filter: blur(70px);
    background: #119291;
    width: 1200px;
    height: 600px;
    position: absolute;
    top: 600px;
    left: 850px;
    transform: translate3d(0, 0, 0);
  }

  .gradient-blur {
    opacity: .7;
    width: 1200px;
    height: 494px;
    display: flex;
    position: absolute;
    top: 500px;
    left: -429px;
  }

  .purple-blur {
    filter: blur(70px);
    background: #5025bf;
    width: 426.12px;
    height: 352.13px;
    position: absolute;
    top: -290px;
    right: 449.59px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur {
    filter: blur(70px);
    background: #119291;
    width: 325.48px;
    height: 219.18px;
    position: absolute;
    top: -30px;
    right: 495px;
    transform: translate3d(0, 0, 0);
  }

  .left-blur {
    opacity: .5;
    width: 666.48px;
    display: flex;
    position: absolute;
    top: 175.06px;
    left: 108.4px;
  }
}

@media screen and (width >= 1400px) {
  .purple-blur2 {
    filter: blur(90px);
    background: #5025bf;
    width: 650.92px;
    height: 321.78px;
    position: absolute;
    top: 920.22px;
    left: 1124.31px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur2 {
    filter: blur(90px);
    background: #119291;
    width: 1093px;
    height: 600px;
    position: absolute;
    top: 600px;
    left: 950px;
    transform: translate3d(0, 0, 0);
  }

  .gradient-blur {
    opacity: .7;
    width: 1693px;
    height: 494px;
    display: flex;
    position: absolute;
    top: 500px;
    left: -429px;
  }

  .purple-blur {
    filter: blur(90px);
    background: #5025bf;
    width: 356.12px;
    height: 502.13px;
    position: absolute;
    top: -290px;
    right: 399.59px;
    transform: translate3d(0, 0, 0);
  }

  .green-blur {
    filter: blur(90px);
    background: #119291;
    width: 425.48px;
    height: 290px;
    position: absolute;
    top: -280px;
    right: 90px;
    transform: translate3d(0, 0, 0);
  }

  .left-blur {
    opacity: .8;
    width: 566.48px;
    position: absolute;
    top: 175.06px;
    left: 108.4px;
  }
}

.geomtris-top {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("ContactTopGeo.8625bc8a.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 290px;
  height: 280px;
  display: flex;
  position: absolute;
  top: 80px;
}

.geo-bottom-conserv {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-image: url("GeomtrisRight.2c44c687.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 380px;
  display: flex;
  position: absolute;
  top: 1550px;
}

@media (width >= 768px) {
  .geomtris-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("ContactTopGeo.8625bc8a.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 280px;
    height: 407px;
    display: flex;
    position: absolute;
    top: 175px;
    left: 480px;
  }

  .geo-bottom-conserv {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 471.89px;
    display: flex;
    position: absolute;
    top: 1775px;
    left: 420px;
  }
}

@media (width >= 992px) {
  .geomtris-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("ContactTopGeo.8625bc8a.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 577px;
    display: flex;
    position: absolute;
    top: 100px;
    left: 622px;
  }

  .geo-bottom-conserv {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 471.89px;
    display: flex;
    position: absolute;
    top: 1640px;
    left: 670px;
  }
}

@media (width >= 1200px) {
  .geomtris-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("ContactTopGeo.8625bc8a.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 500px;
    display: flex;
    position: absolute;
    top: 130px;
    left: 720px;
  }

  .geo-bottom-conserv {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 441.89px;
    display: flex;
    position: absolute;
    top: 1575px;
    left: 0;
  }
}

@media (width >= 1400px) {
  .geomtris-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("ContactTopGeo.8625bc8a.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 517px;
    display: flex;
    position: absolute;
    top: 160px;
    left: 1000px;
  }

  .geo-bottom-conserv {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 501.89px;
    display: flex;
    position: absolute;
    top: 1625px;
    left: 0;
  }
}

@media (width >= 1650px) {
  .geomtris-top {
    z-index: 5;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("ContactTopGeo.8625bc8a.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 517px;
    display: flex;
    position: absolute;
    top: 160px;
    left: 1180px;
  }

  .geo-bottom-conserv {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-image: url("GeomtrisRight.2c44c687.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 501.89px;
    display: flex;
    position: absolute;
    top: 1625px;
    left: 0;
  }
}

.services-3 {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.purple-blur {
  filter: blur(100px);
  background: #5025bf;
  width: 306.12px;
  height: 302.13px;
  position: absolute;
  top: -350px;
  right: 289.59px;
  transform: translate3d(0, 0, 0);
}

.green-blur {
  filter: blur(100px);
  background: #119291;
  width: 305.48px;
  height: 219.18px;
  position: absolute;
  top: 0;
  right: 305px;
  transform: translate3d(0, 0, 0);
}

.left-blur {
  opacity: .96;
  width: 356.48px;
  position: absolute;
  top: 175.06px;
  left: 148.4px;
}

.hero-section-3 {
  width: 100%;
  max-width: 1440px;
  height: 1200px;
  margin-bottom: 120px;
  position: relative;
}

.flex-col-services {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1440px;
  min-height: 866px;
  display: flex;
  position: relative;
  top: -1px;
}

.flex-row {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  margin-top: 102px;
  display: flex;
}

.healine {
  opacity: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate(-25px);
}

.healine.animate-enter23 {
  opacity: 0;
  animation: 1.5s ease-in-out 1s forwards animate-enter23-frames;
  transform: translate(-25px);
}

.chip-5 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  display: flex;
  position: relative;
}

.line-1-2 {
  object-fit: cover;
  min-width: 32px;
  height: 1px;
  position: relative;
}

.services-4, .projects-2 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.we-prioritize-the-se {
  letter-spacing: 0;
  width: 90%;
  line-height: 74px;
  position: relative;
}

.element-1 {
  opacity: 0;
  z-index: 10;
  align-self: baseline;
  width: 147px;
  height: 146px;
  margin-top: 30px;
  transform: translate(25px);
}

.element-1.animate-enter24 {
  opacity: 0;
  animation: 1.5s ease-in-out 1s forwards animate-enter24-frames;
  transform: translate(25px);
}

.ellipse-163-1 {
  backdrop-filter: blur(10px) brightness();
  background: radial-gradient(50% 50%, #82a8f400 17.45%, #82a8f4cc 77.16%);
  border-radius: 72.5px;
  width: 145px;
  height: 145px;
  position: absolute;
  top: 1px;
  left: 0;
}

.rectangle-10 {
  width: 145px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 0;
}

.text-12 {
  color: var(--white);
  font-family: var(--font-family-clash_grotesk-regular);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 60px;
  font-weight: 400;
  line-height: 96px;
  position: absolute;
  top: 25px;
  left: 33px;
  transform: rotate(15deg);
}

.serviceSection {
  flex-direction: column;
  align-items: center;
  gap: 70px;
  width: 100%;
  max-width: 1200px;
  height: 356px;
  margin-top: 80px;
  display: flex;
  position: relative;
}

.serviceButtons {
  z-index: 15;
  flex-direction: row;
  flex-shrink: 1;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  display: flex;
}

.singleButton {
  background-color: var(--white-5);
  border: 2px solid;
  border-color: var(--shark);
  cursor: pointer;
  box-sizing: content-box;
  border-radius: 20px;
  align-self: stretch;
  align-items: center;
  padding: 15px 35px;
  transition: all .15s ease-in-out;
  display: flex;
}

.singleButton.pressed {
  border: 3px solid #00d7c3;
  margin: -1px;
  box-shadow: inset 0 0 10px #00d7c3, 0 0 20px 2px #00d7c3;
}

.sectionIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

@keyframes animate-enter23-frames {
  from {
    opacity: 0;
    transform: translate(-25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes animate-enter29-frames {
  from {
    opacity: 0;
    transform: translate(25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes animate-enter24-frames {
  from {
    opacity: 0;
    transform: translate(25px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@media screen and (width <= 1225px) {
  .hero-section-3 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .healine {
    width: 80%;
  }

  .hero-section-3 {
    height: 1300px;
  }

  .serviceButtons {
    padding: 15px;
    gap: 0 !important;
  }
}

@media screen and (width <= 1075px) {
  .sectionIcon {
    width: 30px;
    height: 30px;
  }

  .service-btn-tag {
    font-size: 18px !important;
  }

  .serviceSection {
    gap: 40px;
  }
}

@media screen and (width <= 1000px) {
  .hero-section-3 {
    height: 1300px;
  }

  .serviceButtons {
    gap: 0;
  }
}

@media screen and (width <= 905px) {
  .serviceButtons {
    justify-content: center;
    gap: 0;
  }

  .singleButton {
    margin: 10px;
  }

  .hero-section-3 {
    height: 1350px;
  }

  .serviceSection {
    gap: 20px;
  }

  .flex-col-services {
    gap: 10px;
  }
}

@media screen and (width <= 805px) {
  .serviceSection {
    margin-top: 50px;
  }

  .hero-section-3 {
    height: 1600px;
  }
}

@media screen and (width <= 775px) {
  .serviceButtons {
    flex-wrap: wrap;
  }

  .singleButton {
    height: 55px;
    margin: 10px;
  }

  .flex-row {
    margin-top: 40px;
  }
}

@media screen and (width <= 700px) {
  .hero-section-3 {
    height: 1400px;
  }

  .we-prioritize-the-se {
    line-height: 40px;
    font-size: 32px !important;
  }

  .rectangle-10, .ellipse-163-1, .overlap-group-15 {
    width: 90px;
    height: 90px;
    top: 15px;
    left: 5px;
  }

  .overlap-group1-3 {
    width: 90px;
    height: 90px;
  }

  .text-12 {
    font-size: 32px;
  }
}

@media screen and (width <= 640px) {
  .rectangle-10, .ellipse-163-1, .overlap-group-15, .overlap-group1-3, .text-12, .element-1 {
    width: 0%;
    display: none;
  }

  .healine, .we-prioritize-the-se {
    width: 100%;
  }

  .singleButton {
    width: 160px;
  }

  .hero-section-3 {
    height: 1650px;
  }
}

@media screen and (width <= 500px) {
  .service-btn-tag {
    font-size: 16px !important;
  }

  .singleButton {
    width: 160px;
  }

  .sectionIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .serviceButtons {
    padding: 15px 0;
  }

  .hero-section-3 {
    height: 1650px;
  }
}

@media screen and (width <= 480px) {
  .hero-section-3 {
    height: 1650px;
  }

  .singleButton {
    width: 135px;
    margin: 3px;
    padding: 8px;
  }
}

@media screen and (width <= 400px) {
  .hero-section-3 {
    height: 1750px;
  }
}

@media screen and (width <= 368px) {
  .serviceButtons {
    width: 110%;
  }
}

@media screen and (width <= 340px) {
  .serviceButtons {
    width: 120%;
  }
}

.serviceSectionDiv {
  background-color: var(--white-5);
  border: 2px solid;
  border-color: var(--shark);
  border-radius: 20px;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: 500px;
  display: flex;
}

.sectionHeader {
  letter-spacing: 0;
  margin-bottom: 30px;
}

.sectionText {
  line-height: 30px;
}

.spin {
  width: 97px;
  height: 89px;
}

.spinContainer {
  width: 97px;
  height: 89px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.leftSection {
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  padding: 40px 30px;
}

.rightSection {
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 500px;
  display: flex;
  position: relative;
  overflow: visible;
}

.design {
  width: 600px;
  height: 500px;
}

.marketing {
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
}

.macbook-canvas {
  top: -20px;
}

.hide-spin {
  display: none;
}

.bullet-icon {
  align-self: center;
  width: auto;
  height: 20px;
  margin-right: 10px;
}

.bullet-section {
  flex-direction: row;
  width: fit-content;
  display: flex;
  position: relative;
}

.li-div {
  width: 400px;
  overflow: hidden;
}

.bullet-div {
  flex-flow: column wrap;
  height: 100px;
  margin-top: 20px;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
}

.bullet-div li {
  padding-left: 30px;
  position: relative;
}

.bullet-div li:before {
  content: "";
  background-image: url("bulletImage.f80e8cd8.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 0;
}

@media screen and (width <= 1050px) {
  .design {
    position: relative;
    right: -10px;
  }

  .serviceSectionDiv {
    width: fit-content;
    height: fit-content;
  }

  .rightSection {
    width: 45%;
  }

  .leftSection {
    width: 55%;
  }

  .spinContainer {
    width: 87px;
    height: 79px;
    right: 15px;
  }

  .spin {
    width: 87px;
    height: 79px;
  }

  .design {
    width: 500px;
    height: 400px;
    position: relative;
    right: 40px;
  }
}

@media screen and (width <= 965px) {
  .design {
    width: 450px;
    height: 350px;
    right: 20px;
  }
}

@media screen and (width <= 880px) {
  .design {
    width: 400px;
    height: 300px;
    right: 20px;
  }
}

@media screen and (width <= 805px) {
  .serviceSectionDiv {
    flex-direction: column;
  }

  .rightSection {
    width: 100%;
    height: 400px;
  }

  .leftSection {
    width: 100%;
    margin-top: -25px;
  }

  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }
}

@media screen and (width <= 700px) {
  .sectionHeader {
    font-size: 24px !important;
  }

  .sectionText {
    font-size: 16px !important;
  }

  .spinContainer {
    width: 77px;
    height: 69px;
    right: 15px;
  }

  .spin {
    width: 77px;
    height: 69px;
  }
}

@media screen and (width <= 630px) {
  .serviceSectionDiv {
    width: fit-content;
    height: fit-content;
  }

  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }

  .bullet-div {
    flex-wrap: nowrap;
    width: 300px;
    height: 190px;
    overflow: hidden;
  }
}

@media screen and (width <= 430px) {
  .serviceSectionDiv {
    width: fit-content;
    height: fit-content;
  }

  .design {
    width: 100%;
    height: auto;
    left: 15px;
  }

  .bullet-div {
    flex-wrap: nowrap;
    width: 210px;
    height: 220px;
    overflow: hidden;
  }
}

.bullet-text {
  white-space: nowrap;
  flex-flow: wrap;
  width: 300px;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 620px) {
  .bullet-text {
    white-space: wrap;
    width: 180px;
  }
}

.frame-216 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  display: flex;
  position: relative;
  width: 370px !important;
}

.servicesContainer {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.container-overlay {
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.overlay {
  z-index: 1;
  background: none;
  width: 370px;
  height: 100%;
  transition: background .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.container-overlay:hover .overlay {
  background: #000000b3;
}

.image-14-1 {
  width: 370px;
  height: 395px;
  transition: transform .5s;
}

.container-overlay:hover .image-14-1 {
  z-index: 0;
  transform: scale(1.1);
}

.description {
  font-family: var(--font-family-switzer-thin);
  opacity: 0;
  z-index: 2;
  color: #fff;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-overlay:hover .description {
  opacity: 1;
}

.frame-21-2.frame-21 .image-14-1 {
  height: 300px;
}

.frame-212 {
  background-color: var(--white-2);
  border-radius: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px 32px 12px;
  display: flex;
  position: relative;
}

.frame-21-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32px;
  display: flex;
  position: relative;
}

.frame-212-1 {
  background-color: var(--white-2);
  color: #fff;
  font-family: var(--font-family-switzer-regular);
  border-radius: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  padding: 10px 18px;
  font-size: 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-name, .project-year, .place-4, .services-6 {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  margin-bottom: 10px;
  line-height: 24px;
  position: relative;
}

@media screen and (width >= 768px) {
  .frame-216 {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 40px;
    display: flex;
    position: relative;
    width: 450px !important;
  }

  .frame-21-2 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .container-overlay {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }

  .overlay {
    z-index: 1;
    background: none;
    height: 100%;
    transition: background .5s;
    position: absolute;
    top: 0;
    left: 0;
    width: 450px !important;
  }

  .container-overlay:hover .overlay {
    background: #000000b3;
  }

  .image-14-1 {
    height: 395px;
    transition: transform .5s;
    width: 450px !important;
  }

  .container-overlay:hover .image-14-1 {
    z-index: 0;
    transform: scale(1.1);
  }

  .description {
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    z-index: 2;
    color: #fff;
    transition: opacity .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container-overlay:hover .description {
    opacity: 1;
  }

  .frame-212 {
    background-color: var(--white-2);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    padding: 10px 32px 12px;
    display: flex;
    position: relative;
  }

  .frame-212-1 {
    background-color: var(--white-2);
    color: #fff;
    font-family: var(--font-family-switzer-regular);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    padding: 10px 18px;
    font-size: 16px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .project-name, .project-year, .place-4, .services-6 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 24px;
    position: relative;
  }

  .frame-21-2.frame-21 .image-14-1 {
    height: 394px;
  }
}

@media screen and (width >= 992px) {
  .frame-21-2 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .frame-216 {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 40px;
    display: flex;
    position: relative;
    width: 450px !important;
  }

  .container-overlay {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }

  .overlay {
    z-index: 1;
    background: none;
    height: 100%;
    transition: background .5s;
    position: absolute;
    top: 0;
    left: 0;
    width: 450px !important;
  }

  .container-overlay:hover .overlay {
    background: #000000b3;
  }

  .image-14-1 {
    border-radius: 12px;
    height: 395px;
    transition: transform .5s;
    width: 450px !important;
  }

  .container-overlay:hover .image-14-1 {
    z-index: 0;
    transform: scale(1.1);
  }

  .description {
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    z-index: 2;
    color: #fff;
    transition: opacity .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container-overlay:hover .description {
    opacity: 1;
  }

  .frame-212 {
    background-color: var(--white-2);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    padding: 10px 32px 12px;
    display: flex;
    position: relative;
  }

  .frame-212-1 {
    background-color: var(--white-2);
    color: #fff;
    font-family: var(--font-family-switzer-regular);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    padding: 10px 18px;
    font-size: 16px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .project-name, .project-year, .place-4, .services-6 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 24px;
    position: relative;
  }
}

@media screen and (width >= 1400px) {
  .frame-21-2 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .frame-216 {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 40px;
    display: flex;
    position: relative;
  }

  .frame-208 {
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  .frame-206 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    display: flex;
  }

  .servicesContainer {
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    display: flex;
  }

  .project-name, .project-year, .place-4, .services-6 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    margin-top: -1px;
    line-height: 24px;
    position: relative;
  }

  .sales-management, .number-9 {
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    line-height: 44px;
    position: relative;
  }

  .frame-20 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 97px;
    display: flex;
    position: relative;
  }

  .container-overlay {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }

  .overlay {
    z-index: 1;
    background: none;
    height: 100%;
    transition: background .5s;
    position: absolute;
    top: 0;
    left: 0;
    width: 570px !important;
  }

  .container-overlay:hover .overlay {
    background: #000000b3;
  }

  .image-14-1 {
    height: 394px;
    transition: transform .5s;
    width: 570px !important;
  }

  .container-overlay:hover .image-14-1 {
    z-index: 0;
    transform: scale(1.1);
  }

  .description {
    font-family: var(--font-family-switzer-thin);
    opacity: 0;
    z-index: 2;
    color: #fff;
    transition: opacity .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container-overlay:hover .description {
    opacity: 1;
  }

  .frame-215 {
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    display: flex;
    position: relative;
  }

  .frame-213 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 145px;
    display: flex;
    position: relative;
  }

  .frame-212 {
    background-color: var(--white-2);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    padding: 10px 32px 12px;
    display: flex;
    position: relative;
  }

  .place-5, .web-development-4 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 28px;
    position: relative;
  }

  .frame-21-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 232px;
    display: flex;
    position: relative;
  }

  .frame-212-1 {
    background-color: var(--white-2);
    color: #fff;
    font-family: var(--font-family-switzer-regular);
    border-radius: 12px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 10px;
    padding: 10px 18px;
    font-size: 20px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .frame-21-2.frame-21-4 .image-14-1 {
    height: 394px;
  }
}

@media screen and (width >= 0) {
  .frame-22 {
    gap: 37px;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }

  .mobile-show {
    background-color: #696969;
    width: 1000px;
    height: .4px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (width >= 768px) {
  .frame-22 {
    gap: 37px;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }

  .mobile-show {
    background-color: #696969;
    width: 1000px;
    height: .1px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (width >= 992px) {
  .frame-22 {
    align-items: flex-start;
    gap: 37px;
    width: 940px;
    display: flex;
    flex-direction: row !important;
  }

  .mobile-show {
    display: none;
  }
}

@media screen and (width >= 1400px) {
  .frame-22 {
    align-items: flex-start;
    gap: 97px;
    width: 1183px;
    display: flex;
    position: relative;
    flex-direction: row !important;
  }

  .mobile-show {
    display: none;
  }
}

services-3 {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100%;
  display: flex;
  position: relative;
}

.section-our-projects {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  margin-bottom: 100px;
  display: flex;
  position: relative;
}

.projects-2 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.overlap-group1-3 {
  width: 145px;
  height: 146px;
  position: relative;
}

.overlap-group-15 {
  width: 145px;
  height: 146px;
  position: absolute;
  top: 0;
  left: 0;
}

.project {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.selected-project {
  align-items: center;
  gap: 40px;
  width: fit-content;
  display: flex;
  position: relative;
}

.number-8 {
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 74px;
  position: relative;
}

.selected-project-1 {
  letter-spacing: 0;
  width: 89px;
  line-height: 28px;
  position: relative;
}

.title-21 {
  opacity: 0;
  flex-direction: row;
  width: 355px;
  margin-top: 60px;
  position: relative;
  transform: translate(25px);
  align-self: center !important;
}

.see-projects-that-we {
  letter-spacing: 0;
  align-self: flex-start;
  line-height: 50px;
  position: relative;
  font-size: 32px !important;
}

.frame-224 {
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: fit-content;
  display: flex;
  position: relative;
}

.vector-22-7, .vector-23 {
  min-width: 992px;
  height: 2px;
  position: relative;
}

.frame-208 {
  margin-left: 10px;
}

@media screen and (width >= 400px) {
  .title-21 {
    width: 375px;
  }
}

@media screen and (width >= 768px) {
  services-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-projects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    margin-bottom: 100px;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .overlap-group1-3 {
    width: 145px;
    height: 146px;
    position: relative;
  }

  .overlap-group-15 {
    width: 145px;
    height: 146px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .services-5 {
    align-items: center;
    gap: 40px;
    width: 700px;
    height: 356px;
    margin-top: 112px;
    display: flex;
    position: relative;
  }

  .project {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 140px;
    width: 700px;
    height: 100%;
    display: flex;
    position: relative;
  }

  .selected-project {
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .number-8 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 74px;
    position: relative;
  }

  .selected-project-1 {
    letter-spacing: 0;
    width: 89px;
    line-height: 28px;
    position: relative;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    gap: 20px;
    width: 680px;
    margin-top: -150px;
    position: relative;
    transform: translate(25px);
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-projects-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: 52px !important;
  }

  .frame-224 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: fit-content;
    margin-top: -60px;
    display: flex;
    position: relative;
  }

  .vector-22-7, .vector-23 {
    min-width: 992px;
    height: 2px;
    position: relative;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: center;
    gap: 50px;
    width: 450px;
    margin-top: 100px;
    margin-right: 0;
    position: relative;
    transform: translate(25px);
  }
}

@media screen and (width >= 992px) {
  services-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-projects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    margin-bottom: 100px;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .overlap-group1-3 {
    width: 145px;
    height: 146px;
    position: relative;
  }

  .overlap-group-15 {
    width: 145px;
    height: 146px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .services-5 {
    align-items: center;
    gap: 40px;
    width: 900px;
    height: 356px;
    margin-top: 112px;
    display: flex;
    position: relative;
  }

  .project {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 140px;
    width: 900px;
    height: 100%;
    display: flex;
    position: relative;
  }

  .selected-project {
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .number-8 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 74px;
    position: relative;
  }

  .selected-project-1 {
    letter-spacing: 0;
    width: 89px;
    line-height: 28px;
    position: relative;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    gap: 20px;
    width: 100%;
    margin-top: 100px;
    margin-right: 0;
    position: relative;
    transform: translate(25px);
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-projects-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }

  .frame-224 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .vector-22-7, .vector-23 {
    min-width: 992px;
    height: 2px;
    position: relative;
  }
}

@media screen and (width >= 1200px) {
  services-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-projects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .overlap-group1-3 {
    width: 145px;
    height: 146px;
    position: relative;
  }

  .overlap-group-15 {
    width: 145px;
    height: 146px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .services-5 {
    align-items: center;
    gap: 20px;
    height: 356px;
    margin-top: 112px;
    display: flex;
    position: relative;
    width: 1216px !important;
  }

  .project {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 140px;
    height: 2624px;
    display: flex;
    position: relative;
    width: 1183px !important;
  }

  .selected-project {
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .frame-205 {
    align-items: center;
    gap: 12px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .number-8 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 74px;
    position: relative;
  }

  .selected-project-1 {
    letter-spacing: 0;
    width: 89px;
    line-height: 28px;
    position: relative;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    width: 100%;
    margin-top: -200px;
    margin-right: -200px;
    position: relative;
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-projects-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: 52px !important;
  }

  .frame-224 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .vector-22-7, .vector-23 {
    min-width: 1183px;
    height: 2px;
    position: relative;
  }
}

@media screen and (width >= 1400px) {
  services-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-projects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    margin-top: 75px;
    margin-bottom: 100px;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .projects-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .overlap-group1-3 {
    width: 145px;
    height: 146px;
    position: relative;
  }

  .overlap-group-15 {
    width: 145px;
    height: 146px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .services-5 {
    align-items: center;
    gap: 20px;
    height: 356px;
    margin-top: 112px;
    display: flex;
    position: relative;
    width: 1216px !important;
  }

  .project {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 140px;
    height: 2624px;
    display: flex;
    position: relative;
    width: 1183px !important;
  }

  .selected-project {
    align-items: center;
    gap: 40px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .frame-205 {
    align-items: center;
    gap: 12px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .number-8 {
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 74px;
    position: relative;
  }

  .selected-project-1 {
    letter-spacing: 0;
    width: 89px;
    line-height: 28px;
    position: relative;
  }

  .title-21 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    gap: 20px;
    margin-top: -200px;
    margin-right: 0;
    position: relative;
    transform: translate(25px);
    width: 100% !important;
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-projects-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
  }

  .frame-224 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .vector-22-7, .vector-23 {
    min-width: 1183px;
    height: 2px;
    position: relative;
  }

  @keyframes animate-enter23-frames {
    from {
      opacity: 0;
      transform: translate(-25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter29-frames {
    from {
      opacity: 0;
      transform: translate(25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter24-frames {
    from {
      opacity: 0;
      transform: translate(25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

.blog-3 {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  display: flex;
  position: relative;
}

.blog-container {
  width: 100%;
  height: auto;
}

.dib-homepage-title {
  display: none;
}

.section-our-blog {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: auto;
  margin-bottom: 100px;
  display: flex;
  position: relative;
}

.line-1-2 {
  object-fit: cover;
  flex-direction: row;
  align-items: center;
  min-width: 32px;
  position: relative;
}

.blogs-2 {
  letter-spacing: 1.5px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.services-5 {
  align-items: center;
  gap: 40px;
  width: 350px;
  height: 356px;
  margin-top: 112px;
  display: flex;
  position: relative;
}

.blog {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}

.title-212 {
  opacity: 0;
  text-align: center;
  flex-direction: row;
  width: auto;
  margin-top: 60px;
  position: relative;
  transform: translate(25px);
  align-self: center !important;
}

.chip-6 {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.see-blogs-that-we {
  letter-spacing: 0;
  align-self: flex-start;
  line-height: 50px;
  position: relative;
  font-size: 32px !important;
}

@media screen and (width >= 768px) {
  blog-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: auto;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .blog {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 700px;
    height: auto;
    display: flex;
    position: relative;
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-blogs-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }

  .blog-container {
    width: 100%;
    height: auto;
  }

  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    gap: 50px;
    width: auto;
    margin-right: 0;
    position: relative;
    transform: translate(25px);
  }
}

@media screen and (width >= 992px) {
  blog-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: auto;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    height: auto;
    display: flex;
    position: relative;
  }

  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    gap: 20px;
    width: auto;
    margin-top: 0;
    margin-right: 0;
    position: relative;
    transform: translate(25px);
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .blog-container {
    width: 100%;
    height: auto;
  }

  .see-blogs-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: var(--font-size-xxl) !important;
  }
}

@media screen and (width >= 1200px) {
  blog-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: auto;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .blog {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    display: flex;
    position: relative;
    width: 1183px !important;
  }

  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    width: auto;
    position: relative;
  }

  .blog-container {
    width: 100%;
    height: auto;
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-blogs-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
    font-size: 52px !important;
  }
}

@media screen and (width >= 1400px) {
  blog-3 {
    background-color: var(--black);
    border: 1px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    display: flex;
    position: relative;
  }

  .section-our-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: auto;
    margin-top: 75px;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }

  .line-1-2 {
    object-fit: cover;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    position: relative;
  }

  .blog-2 {
    letter-spacing: 1.5px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    line-height: 20px;
    position: relative;
  }

  .blog {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    display: flex;
    position: relative;
    width: 1183px !important;
  }

  .blog-container {
    width: 100%;
    height: auto;
  }

  .title-212 {
    opacity: 0;
    flex-direction: row;
    align-self: flex-start;
    width: auto;
    position: relative;
  }

  .chip-6 {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    display: flex;
    position: relative;
  }

  .see-blogs-that-we {
    letter-spacing: 0;
    align-self: flex-start;
    line-height: 70px;
    position: relative;
  }

  .frame-224 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: fit-content;
    display: flex;
    position: relative;
  }

  .vector-22-7, .vector-23 {
    min-width: 1183px;
    height: 2px;
    position: relative;
  }

  @keyframes animate-enter23-frames {
    from {
      opacity: 0;
      transform: translate(-25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter29-frames {
    from {
      opacity: 0;
      transform: translate(25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes animate-enter24-frames {
    from {
      opacity: 0;
      transform: translate(25px);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
}

.footer-1 {
  background-color: var(--black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 360px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.our-email {
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-3 {
  background-image: url("AlphaLogo.ec651c73.png");
  background-size: contain;
  width: 115px;
  height: 76.8px;
  margin-right: 20px;
}

.wmc-div {
  width: 100px;
  height: 76.8px;
}

.wmc-image {
  width: auto;
  height: 76.8px;
}

.logoContainer {
  flex-direction: row;
  display: flex;
}

.footerList {
  flex-direction: row;
  align-items: center;
  gap: 32px;
  display: flex;
  position: relative;
}

.footerListItem {
  color: var(--silver);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  margin-top: -3px;
  line-height: 28px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.tel {
  text-decoration: none;
  font-size: 18px !important;
}

.bottomDiv {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.vector-22-2 {
  align-self: stretch;
  width: 100%;
  height: 2px;
  position: relative;
}

.copyright {
  width: 100%;
}

.gradients {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
}

.services-2 {
  -webkit-text-stroke: 2px var(--white);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -3px;
  margin-left: -2px;
  line-height: 28px;
  position: relative;
}

.address-1, .terms-condition-1, .privacy-policy {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  color: var(--silver);
  margin-top: -1px;
  line-height: 28px;
  text-decoration: none;
  position: relative;
}

.no-decoration {
  z-index: 10;
  text-decoration: none;
}

.termsDiv {
  gap: 40px;
  display: flex;
  position: relative;
}

.bottom-blur {
  opacity: .2;
  width: 1269px;
  height: 601px;
  position: absolute;
  top: 641px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.purple-bottom {
  opacity: .2;
  filter: blur(70px);
  background: #5025bf;
  width: 93%;
  height: 621.78px;
  display: flex;
  position: absolute;
  top: 359.22px;
}

.green-bottom {
  opacity: .3;
  filter: blur(70px);
  background: #119291;
  width: 93%;
  height: 504.19px;
  position: absolute;
  top: 335px;
  left: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media screen and (width <= 1800px) {
  .bottom-blur {
    opacity: .3;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1269px;
    height: 601px;
    display: flex;
    position: absolute;
    top: 241px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .purple-bottom {
    opacity: .1;
    filter: blur(60px);
    background: #5025bf;
    width: 95%;
    height: 621.78px;
    position: absolute;
    top: 360.22px;
  }

  .green-bottom {
    opacity: .24;
    filter: blur(70px);
    background: #119291;
    width: 100%;
    height: 504.19px;
    display: flex;
    position: absolute;
    top: 291px;
    left: -20px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (width <= 1200px) {
  .bottom-blur {
    opacity: .9;
    width: 1269px;
    height: 601px;
    position: absolute;
    top: 901px;
    left: 1328px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .purple-bottom {
    opacity: .1;
    filter: blur(70px);
    background: #5025bf;
    width: 960.92px;
    height: 221.78px;
    position: absolute;
    top: 320.22px;
    left: -40.31px;
  }

  .green-bottom {
    opacity: .24;
    filter: blur(70px);
    background: #119291;
    width: 1269px;
    height: 204.19px;
    position: absolute;
    top: 260px;
    left: -40px;
  }
}

@media screen and (width <= 852px) {
  .menu {
    flex-direction: column;
  }

  .footerList {
    justify-content: center;
    width: 100%;
  }

  .logoDiv {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 30px;
  }

  .logoContainer {
    justify-content: flex-start;
  }

  .footer-1 {
    height: 400px;
  }

  .bottomDiv {
    flex-direction: column-reverse;
  }

  .addressDiv {
    align-self: center;
    margin-top: 20px;
  }

  .termsDiv {
    justify-content: space-between;
  }

  .bottom-blur {
    opacity: .2;
    width: 1269px;
    height: 601px;
    position: absolute;
    top: 901px;
    left: 800px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .purple-bottom {
    filter: blur(70px);
    background: #5025bf;
    width: 960.92px;
    height: 221.78px;
    position: absolute;
    top: 540.22px;
    left: 0;
  }

  .green-bottom {
    opacity: .24;
    filter: blur(70px);
    background: #119291;
    width: 1269px;
    height: 204.19px;
    position: absolute;
    top: 320px;
    left: -20px;
  }
}

@media screen and (width <= 700px) {
  .footerListItem, .address-1, .terms-condition-1, .privacy-policy {
    font-size: 12px;
  }

  .our-email {
    font-size: 12px !important;
  }

  .logo-3 {
    width: 48px;
    height: 32px;
    margin-right: 8px;
  }

  .wmc-div {
    justify-content: flex-start;
    width: 48px;
    height: 32px;
    display: flex;
  }

  .wmc-image {
    width: auto;
    height: 40px;
  }

  .footer-1 {
    gap: 15px;
    height: 276px;
  }
}

@media screen and (width <= 699px) {
  .bottom-blur {
    opacity: .6;
    width: 1269px;
    height: 601px;
    position: absolute;
    top: 201px;
    left: 800px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .purple-bottom {
    filter: blur(70px);
    background: #5025bf;
    width: 150px;
    height: 204.78px;
    position: absolute;
    top: 295px;
    left: 100px;
  }

  .green-bottom {
    opacity: .24;
    filter: blur(70px);
    background: #119291;
    width: 700px;
    height: 204.19px;
    position: absolute;
    top: 200px;
    left: -100px;
  }

  .tel {
    text-decoration: none;
    font-size: 12px !important;
  }
}

@media screen and (width <= 450px) {
  .footerList {
    gap: 10px;
  }
}

.cookie-consent-banner {
  color: #fff;
  text-align: center;
  z-index: 1000;
  background-color: #000;
  border: 2px solid #00d7c3;
  margin: -1px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 10px #00d7c3, 0 0 10px 2px #00d7c3;
}

.accept {
  color: #fff;
  cursor: pointer;
  background-color: #00d7c3;
  border: none;
  border-radius: 12px;
  margin: 10px;
  padding: 5px 20px;
  font-size: 14px;
}

.decline {
  color: #fff;
  cursor: pointer;
  background-color: #000c;
  border: none;
  border-radius: 12px;
  margin: 12px;
  padding: 5px 20px;
  font-size: 14px;
  box-shadow: inset 0 0 10px #00d7c3, 0 0 10px 2px #00d7c3;
}

.terms-and-conditions {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.terms-and-conditions-container {
  z-index: 10;
  flex-direction: column;
  gap: 25px;
  max-width: 1200px;
  height: auto;
  min-height: 500px;
  margin: 30px 20px 80px;
  display: flex;
}

div.terms-and-conditions-container h4 {
  line-height: 35px;
}

.terms-div {
  flex-direction: column;
  line-height: 25px;
  display: flex;
}

@media screen and (width <= 700px) {
  .terms-div {
    line-height: 15px;
    font-size: 12px !important;
  }

  div.terms-and-conditions-container h4 {
    font-size: 18px !important;
  }

  .terms-main-header {
    font-size: 32px !important;
  }
}

.privacy-policy-screen {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.privacy-policy-container {
  z-index: 10;
  flex-direction: column;
  gap: 25px;
  max-width: 1200px;
  height: auto;
  min-height: 500px;
  margin: 30px 20px 80px;
  display: flex;
}

div.privacy-policy-container h4 {
  line-height: 35px;
}

.privacy-policy-div {
  flex-direction: column;
  line-height: 25px;
  display: flex;
}

.privacy-policy-email-link {
  color: #00d7c3;
}

@media screen and (width <= 700px) {
  .privacy-policy-div {
    line-height: 15px;
    font-size: 12px !important;
  }

  div.privacy-policy-container h4 {
    font-size: 18px !important;
  }

  .privacy-policy-header {
    font-size: 32px !important;
  }
}

.not-found-screen {
  background-color: var(--black);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.not-found-container {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  max-width: 1200px;
  height: auto;
  min-height: 500px;
  display: flex;
}

@font-face {
  font-family: SF Pro Display-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("SFPRODISPLAYMEDIUM.00d4b6d2.OTF");
}

@font-face {
  font-family: Switzer-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("Switzer-Bold.f2053239.otf") format("opentype");
}

@font-face {
  font-family: Switzer-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("Switzer-Medium.63ab1611.otf") format("opentype");
}

@font-face {
  font-family: Clash Grotesk-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("ClashGrotesk-Medium.77a86405.otf") format("opentype");
}

@font-face {
  font-family: Eudoxus Sans-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("EudoxusSans-Bold.9ff25b7d.ttf") format("truetype");
}

@font-face {
  font-family: Eudoxus Sans-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("EudoxusSans-Regular.e6f933eb.ttf") format("truetype");
}

@font-face {
  font-family: Switzer-Extrabold;
  font-style: normal;
  font-weight: 400;
  src: url("Switzer-Extrabold.a3dc88b5.otf") format("opentype");
}

@font-face {
  font-family: Switzer-Semibold;
  font-style: normal;
  font-weight: 400;
  src: url("Switzer-Semibold.8b93e24b.otf") format("opentype");
}

@font-face {
  font-family: Clash Grotesk-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("ClashGrotesk-Regular.b36db043.otf") format("opentype");
}

@font-face {
  font-family: Switzer-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("Switzer-Regular.1938011d.otf") format("opentype");
}

@font-face {
  font-family: Awesome-Journey;
  font-style: normal;
  font-weight: normal;
  src: url("Awesome-Journey.838f2be7.otf") format("opentype");
}

@font-face {
  font-family: Fancy-Signature;
  font-style: normal;
  font-weight: normal;
  src: url("Fancy-Signature.f5f355d1.otf") format("opentype");
}

@font-face {
  font-family: Magical-Star;
  font-style: normal;
  font-weight: normal;
  src: url("Magical-Star.d5e7598e.otf") format("opentype");
}

@font-face {
  font-family: Monoton-Regular;
  font-style: normal;
  font-weight: normal;
  src: url("Monoton-Regular.b1a7d14f.ttf") format("truetype");
}

@font-face {
  font-family: Spiced-Cheese;
  font-style: normal;
  font-weight: normal;
  src: url("Spiced-Cheese.1c0a142c.otf") format("opentype");
}

@font-face {
  font-family: Unkempt-Regular;
  font-style: normal;
  font-weight: normal;
  src: url("Unkempt-Regular.61bc8bb2.ttf") format("truetype");
}

@font-face {
  font-family: Ice-Bold;
  font-style: normal;
  font-weight: normal;
  src: url("ICEBOLD.99405daf.ttf") format("truetype");
}

@font-face {
  font-family: Digitall;
  font-style: normal;
  font-weight: normal;
  src: url("Digitall.d9aecc30.ttf") format("truetype");
}

@font-face {
  font-family: Riviera;
  font-style: normal;
  font-weight: normal;
  src: url("Riviera.c01cae93.otf") format("opentype");
}

@font-face {
  font-family: NidusSans;
  font-style: normal;
  font-weight: normal;
  src: url("NidusSans.c152b2d2.ttf") format("opentype");
}

@font-face {
  font-family: Space;
  font-style: normal;
  font-weight: normal;
  src: url("SpaceHeavy.e67487a7.otf") format("opentype");
}

@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: normal;
  src: url("Orbitron Medium.f0f68fe2.ttf") format("truetype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: super-sick-alpha-font;
  src: url("super-sick-alpha-font.a895aaae.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-right: .2em;
  font-family: super-sick-alpha-font;
  font-size: 120%;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
}

.icon-logical-thinking:before {
  content: "";
}

.icon-web:before {
  content: "";
}

.icon-roadmap:before {
  content: "";
}

.icon-ui:before {
  content: "";
}

.icon-maintenance:before {
  content: "";
}

.icon-champagne:before {
  content: "";
}

:root {
  --anzac: #d9bc43;
  --apple: #58a438;
  --black: #000;
  --black-2: #0006;
  --black-3: #00000080;
  --black-4: #0003;
  --blue-chill: #1192914c;
  --concord: #7c7c7c;
  --eerie-black: #1c1c1c;
  --portage: #82a8f4;
  --shark: #252525;
  --silver: #bdbdbd;
  --white: #fff;
  --white-2: #ffffff1a;
  --white-3: #ffffff80;
  --white-4: #ffffff12;
  --white-5: #ffffff0a;
  --white-6: #ffffff4c;
  --white-7: #fffc;
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 36px;
  --font-size-xs: 12px;
  --font-size-xxl: 52px;
  --font-size-xxs: 9px;
  --font-size-ll: 28px;
  --font-family-clash_grotesk-medium: "Clash Grotesk-Medium", Helvetica;
  --font-family-clash_grotesk-regular: "Clash Grotesk-Regular", Helvetica;
  --font-family-eudoxus_sans-bold: "Eudoxus Sans-Bold", Helvetica;
  --font-family-eudoxus_sans-medium: "Eudoxus Sans-Medium", Helvetica;
  --font-family-eudoxus_sans-regular: "Eudoxus Sans-Regular", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-sf_pro_display-medium: "SF Pro Display-Medium", Helvetica;
  --font-family-space_grotesk: "Space Grotesk", Helvetica;
  --font-family-space_mono: "Space Mono", Helvetica;
  --font-family-switzer-bold: "Switzer-Bold", Helvetica;
  --font-family-switzer-extrabold: "Switzer-Extrabold", Helvetica;
  --font-family-switzer-medium: "Switzer-Medium", Helvetica;
  --font-family-switzer-regular: "Switzer-Regular", Helvetica;
  --font-family-switzer-thin: "Switzer-Thin", Helvetica;
  --font-family-switzer-semibold: "Switzer-Semibold", Helvetica;
}

.spacegrotesk-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-silver-20px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-white-22px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-medium-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.spacemono-normal-white-9px {
  color: var(--white);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-white-32px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-normal-silver-16px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-bold-white-28px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-ll);
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-bold-white-36px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-8px {
  color: var(--white-3);
  font-family: var(--font-family-space_mono);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.switzer-normal-white-28px {
  color: var(--white);
  font-family: var(--font-family-switzer-semibold);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.switzer-regular-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-portage-12px {
  color: var(--portage);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.inter-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-white-32px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.switzer-bold-white-64px, .switzer-bold-white-54px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-9px-2 {
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.spacemono-normal-apple-9px {
  color: var(--apple);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.spacemono-normal-anzac-9px {
  color: var(--anzac);
  font-family: var(--font-family-space_mono);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.switzer-medium-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-silver-12px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.switzer-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-white-28px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-portage-16px {
  color: var(--portage);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.spacemono-normal-white-8px-2 {
  color: var(--white);
  font-family: var(--font-family-space_mono);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-medium-white-10px {
  color: var(--white-7);
  font-family: var(--font-family-space_grotesk);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-switzer-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-17px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.switzer-bold-white-52px {
  color: var(--white);
  font-family: var(--font-family-switzer-bold);
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}

.inter-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-concord-14px {
  color: var(--concord);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-silver-14px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.eudoxussans-medium-white-20px {
  color: var(--white-3);
  font-family: var(--font-family-eudoxus_sans-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.eudoxussans-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-eudoxus_sans-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.switzer-normal-eerie-black-16px {
  color: var(--eerie-black);
  font-family: var(--font-family-switzer-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-white-64px-2 {
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.switzer-bold-portage-64px {
  color: var(--portage);
  font-family: var(--font-family-switzer-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.spacegrotesk-medium-silver-24px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.switzer-medium-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-11px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-medium-silver-12px {
  color: var(--silver);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.spacegrotesk-normal-shark-14px {
  color: var(--shark);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.spacegrotesk-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
/*# sourceMappingURL=index.d13293ce.css.map */
