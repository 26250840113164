/* For desktop: */

.contact-us-4 {
  align-items: center;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  width: 100%;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  position: relative;
}
.modal-button {
  text-align: center;
  margin-top: 20px;
  font-family: var(--font-family-switzer-thin);
  color: rgb(0, 215, 195);
}
.modal-button:hover {
  text-decoration: underline;
}

.hero-seccction {
  align-items: center;
  display: flex;
  min-height: 250px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.flex-col-4 {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  position: relative;
  width: 100%;
}

.headline {
  align-items: center;
  flex-direction: row;
  gap: 10px;
  top: 0px;
  opacity: 0;
  position: relative;
  transform: translate(-25px, 0);
  width: 330px;
}

.headline.animate-enter17 {
  animation: animate-enter17-frames 1.5s ease-in-out 1s 1 normal forwards;
  opacity: 0;
  transform: translate(-25px, 0);
}

.chip-4 {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.line-1-1 {
  height: 1px;
  min-width: 32px;
  object-fit: cover;
  position: relative;
}

.contact-us-5 {
  color: var(--portage);
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  display: flex;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.enter-your-email {
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white-2);
  border-radius: 20px;
  display: flex;
  border: none;
  gap: 10px;
  overflow: hidden;
  padding: 20px 29px 22px;
  width: 30%;
  margin-top: 40px;
}
.labelName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  margin-bottom: -185px;
}
.company-name-space {
  align-items: center;
  align-self: stretch;
  border: none;
  background-color: var(--white-2);
  border-radius: 20px;
  display: flex;
  gap: 20px;
  overflow: hidden;
  padding: 20px 24px 22px;
  position: relative;
  width: 30%;
  margin-top: -20px;
}
.label-company {
  align-items: flex-start;
  width: 70%;
  position: relative;
  margin-bottom: 40px;
}
.label-name {
  align-items: flex-start;
  gap: 10px;
  position: relative;
  width: 70%;
  margin-bottom: -45px;
}

.enter-your-name {
  align-items: flex-start;
  flex-direction: row;
  border: none;
  background-color: var(--white-2);
  border-radius: 20px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 20px 24px 22px;
  position: relative;
  width: 30%;
}
.label-name {
  padding-bottom: 20px;
}

.tell-us-about-your-project-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 620px;
  height: 200px;
}

.input-field-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 45%;
  position: relative;
}

.tell-us-about-your-project-2 {
  height: 158px;
  gap: 20px;
  position: relative;
  width: 300px;
  background-color: var(--white-2);
  border-radius: 20px;
  height: 350px;
  border: none;
}
textarea,
textarea:focus {
  text-indent: 10px;
  outline: none;
  padding-top: 5px;
}

.lets-talk-about-you {
  letter-spacing: 0;
  line-height: 54px;
  position: relative;
  width: 100%;
  color: var(--white) !important;
  font-family: var(--font-family-switzer-bold);
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.section-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 1580px;
  width: 100%;
  position: relative;
}

.form-contact-us {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: -150px;
  height: 1334px;
  position: relative;
  width: 375px;
}

.data,
.tell-us-about-your-project {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--black-3);

  border-radius: 20px;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  padding: 60px 40px;
  position: relative;
  transform: translate(25px, 0);
}

.content-8 {
  display: flex;
  flex-direction: column;
  gap: 44px;
  position: relative;
  width: 1000px;
}

.content-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 14px;
  position: relative;
}
.modal-text {
  line-height: 28px;
  text-align: center;
}
.types-of-work
/*.input-budget*/ {
  align-items: center;
  align-self: stretch;
  background-color: var(--black-3);
  border-radius: 20px;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  height: 500px;
  padding: 60px 40px;
  position: relative;
  transform: translate(-25px, 0);
}

.content-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 317px;
}

.types-of-work-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  overflow-wrap: break-word;
}

.service-choice,
.frame-238 {
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  gap: 22px;
  position: relative;
}
.mobile-app-development-2 {
  align-items: center;
  border: 1px solid;
  background-color: var(--eerie-black);
  border-color: var(--concord);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 35px 22px;
  position: relative;
}
.web-development-2 {
  align-items: center;
  border: 1px solid;
  background-color: var(--eerie-black);
  border-color: var(--concord);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 63px 22px;
  position: relative;
}
.buttonActive {
  color: black;
  background-color: white;
}

.web-development-3 {
  font-family: var(--font-family-switzer-medium);
  font-size: var(--font-size-l);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 300px;
  height: 50px;
}

.service-choice-item {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 118px 22px;
  position: relative;
  width: fit-content;
}
.marketing-1 {
  background-color: var(--eerie-black);
  border: 1px solid;
  border-color: var(--concord);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 102px 22px;
  position: relative;
  width: fit-content;
}

.mobile-app-development-2,
.marketing-1,
.design-1,
.text-8,
.text-11 {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 300px;
  height: 50px;
  align-items: center;
}
small {
  font-size: 75%;
  text-indent: 0em;
  font-family: var(--font-family-switzer-thin);
  font-weight: lighter;
}

.frame-240 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 686px;
}

.button-6 {
  align-items: center;
  background-color: var(--portage);
  border-radius: 12px;
  display: flex;
  height: 64px;
  margin-top: 30px;
  opacity: 0;
  position: relative;
  width: 200px;
}

.send-request {
  line-height: 24px;
  margin-top: -1.5px;
  white-space: nowrap;
  padding-left: 75px;
  align-self: center;
  width: fit-content;
}
.geomtris-right {
  background-image: url(../../static/img/GeomtrisRight.png);
  position: absolute;
  width: 100%;
  height: 471.89px;
  left: 1165px;
  top: 1657.43px;
  background-repeat: no-repeat;
  opacity: 0.42;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (min-width: 768px) {
  .contact-us-4 {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    position: relative;
  }

  .hero-seccction {
    align-items: center;
    display: flex;
    min-height: 250px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .flex-col-4 {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    position: relative;
    width: 100%;
  }

  .headline {
    align-items: center;
    flex-direction: row;
    gap: 10px;
    top: 0px;
    opacity: 0;
    position: relative;
    transform: translate(-25px, 0);
    width: fit-content;
    font-size: 32px !important;
  }

  .chip-4 {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: fit-content;
  }

  .line-1-1 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
  }

  .enter-your-email {
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    border: none;
    gap: 10px;
    overflow: hidden;
    padding: 20px 29px 22px;
    width: 60%;
    margin-top: 40px;
  }
  .labelName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 120px;
    margin-bottom: -185px;
  }
  .company-name-space {
    align-items: center;
    align-self: stretch;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 20px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 60%;
    margin-top: -20px;
  }
  .label-company {
    align-items: flex-start;
    width: 70%;
    position: relative;
    margin-bottom: 40px;
  }
  .label-name {
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: 70%;
    margin-bottom: -45px;
  }

  .enter-your-name {
    align-items: flex-start;
    flex-direction: row;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 60%;
  }
  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 620px;
    height: 200px;
  }

  .input-field-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    height: 158px;
    gap: 20px;
    position: relative;
    width: 620px;
    background-color: var(--white-2);
    border-radius: 20px;
    height: 300px;
    border: none;
  }
  textarea,
  textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    line-height: 74px;
    position: relative;
    width: 716px;
    color: var(--white) !important;
    font-family: var(--font-family-switzer-bold) !important;
    font-size: var(--font-size-xxl) !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }

  .section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 1680px;
    width: 100%;
    position: relative;
  }

  .form-contact-us {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: -200px;
    height: 1334px;
    position: relative;
    width: 700px;
  }

  .data,
  .tell-us-about-your-project {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);

    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px, 0);
  }

  .content-8 {
    display: flex;
    flex-direction: column;
    gap: 44px;
    position: relative;
    width: 1000px;
  }

  .content-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 14px;
    position: relative;
  }

  .types-of-work {
    align-items: center;
    align-self: stretch;
    background-color: var(--black-3);
    border-radius: 20px;
    flex-direction: column;
    height: 500px;
    gap: 10px;
    opacity: 0;
    height: 500px;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px, 0);
  }

  .content-10 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 517px;
  }

  .types-of-work-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .service-choice,
  .frame-238 {
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    display: flex;
    gap: 22px;
    position: relative;
  }
  .mobile-app-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  .web-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  .buttonActive {
    color: black;
    background-color: white;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 620px !important;
    height: 60px;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    width: 620px !important;
    height: 60px;
  }
  .marketing-1 {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    width: 620px !important;
    height: 60px;
  }

  .mobile-app-development-2,
  .marketing-1,
  .design-1,
  .text-8,
  .text-11 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 620px !important;
    height: 60px;
  }
  small {
    font-size: 75%;
    text-indent: 0em;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }

  .frame-240 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 686px;
  }

  .button-6 {
    align-items: center;
    background-color: var(--portage);
    border-radius: 12px;
    display: flex;
    height: 64px;
    margin-top: 30px;
    margin-left: 505px;
    opacity: 0;
    position: relative;
    width: 200px;
  }

  .send-request {
    line-height: 24px;
    margin-top: -1.5px;
    white-space: nowrap;
    padding-left: 75px;
    align-self: center;
    width: fit-content;
  }
  .geomtris-right {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    width: 100%;
    height: 471.89px;
    left: 1165px;
    top: 1657.43px;
    background-repeat: no-repeat;
    opacity: 0.42;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}
@media screen and (min-width: 992px) {
  .contact-us-4 {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    position: relative;
  }

  .hero-seccction {
    align-items: center;
    display: flex;
    min-height: 250px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .flex-col-4 {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 200px;

    position: relative;
    width: 100%;
  }

  .headline {
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-left: 50px;
    top: 0px;
    opacity: 0;
    position: relative;
    transform: translate(-25px, 0);
  }

  .chip-4 {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: fit-content;
  }

  .line-1-1 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
  .contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
  }

  .enter-your-email {
    flex-direction: row;
    align-items: flex-start;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    border: none;
    gap: 10px;
    overflow: hidden;
    padding: 20px 29px 22px;
    width: 48.5%;
    margin-top: 0px;
  }
  .labelName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
  }
  .mobile-app-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 25px 22px !important;
    position: relative;
  }
  .company-name-space {
    align-items: flex-start;
    align-self: stretch;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 100%;
  }
  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }
  .label-name {
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: 48.5%;
  }

  .enter-your-name {
    align-items: flex-start;
    flex-direction: row;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 48.5%;
  }
  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 1000px;

    height: 200px;
  }

  .input-field-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    height: 158px;
    gap: 20px;
    position: relative;
    width: 800px;
    background-color: var(--white-2);
    border-radius: 20px;
    height: 300px;
    border: none;
  }
  textarea,
  textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    line-height: 74px;
    position: relative;
    width: 990px;
    color: var(--white) !important;
    font-family: var(--font-family-switzer-bold) !important;
    font-size: 54px !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }

  .section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 1580px;
    width: 100%;
    position: relative;
  }

  .form-contact-us {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: -300px;
    height: 1334px;
    position: relative;
    width: 900px;
  }

  .data,
  .tell-us-about-your-project {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);

    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px, 0);
  }

  .content-8 {
    display: flex;
    flex-direction: column;
    gap: 44px;
    position: relative;
    width: 800px;
  }

  .content-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 64px;
    position: relative;
  }

  .types-of-work {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);
    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px, 0);
  }

  .content-10 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 800px;
  }

  .types-of-work-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .service-choice,
  .frame-238 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 5px;
    position: relative;
  }

  .web-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: 253px;
  }
  .buttonActive {
    color: black;
    background-color: white;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;

    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: fit-content;
  }

  .mobile-app-development-2,
  .marketing-1,
  .design-1,
  .text-8,
  .text-11 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content !important;
  }

  .frame-240 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 700px;
  }

  .button-6 {
    align-items: center;
    background-color: var(--portage);
    border-radius: 12px;
    display: flex;
    height: 64px;
    margin-top: -300px;
    margin-left: 675px;
    opacity: 0;
    position: relative;
    width: 200px;
  }

  .send-request {
    line-height: 24px;
    margin-top: -1.5px;
    white-space: nowrap;
    padding-left: 75px;
    align-self: center;
    width: fit-content;
  }
  .geomtris-right {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    width: 100%;
    height: 471.89px;
    left: 1165px;
    top: 1657.43px;
    background-repeat: no-repeat;
    opacity: 0.42;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}
@media screen and (min-width: 1200px) {
  .contact-us-4 {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    position: relative;
  }

  .hero-seccction {
    align-items: center;
    display: flex;
    min-height: 250px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .flex-col-4 {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 150px;
    position: relative;
    width: 100%;
  }

  .headline {
    align-items: center;
    flex-direction: row;
    gap: 10px;
    top: 0px;
    opacity: 0;
    position: relative;
    transform: translate(-25px, 0);
    width: fit-content;
  }

  .chip-4 {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: fit-content;
  }
  .mobile-app-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 35px 22px;
    position: relative;
  }
  .line-1-1 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .contact-us-5 {
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
  .contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
  }

  .enter-your-email {
    flex-direction: row;
    align-items: flex-start;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    border: none;
    gap: 10px;
    overflow: hidden;
    padding: 20px 29px 22px;
    width: 48.5%;
    margin-top: 0px;
  }
  .labelName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
  }
  .company-name-space {
    align-items: flex-start;
    align-self: stretch;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 100%;
  }
  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }
  .label-name {
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: 48.5%;
  }

  .enter-your-name {
    align-items: flex-start;
    flex-direction: row;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 48.5%;
  }
  .label-name {
    padding-bottom: 20px;
  }

  .tell-us-about-your-project-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 1000px;

    height: 200px;
  }

  .input-field-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    height: 158px;
    gap: 20px;
    position: relative;
    width: 1000px;
    background-color: var(--white-2);
    border-radius: 20px;
    height: 300px;
    border: none;
  }
  textarea,
  textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    line-height: 74px;
    position: relative;
    width: 1216px;
  }

  .section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 1540px;
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  .form-contact-us {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: -300px;
    height: 1334px;
    position: relative;
    width: 1080px;
  }

  .data,
  .tell-us-about-your-project {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);

    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px, 0);
  }

  .content-8 {
    display: flex;
    flex-direction: column;
    gap: 44px;
    position: relative;
    width: 1000px;
  }

  .content-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 64px;
    position: relative;
  }

  .types-of-work
/*.input-budget*/ {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);
    border-radius: 20px;
    flex-direction: column;
    height: 250px;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px, 0);
  }

  .content-10 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 917px;
  }

  .types-of-work-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .service-choice,
  .frame-238 {
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    display: flex;
    gap: 32px;
    position: relative;
  }

  .web-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: 253px;
  }
  .buttonActive {
    color: black;
    background-color: white;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;

    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: fit-content;
  }

  .mobile-app-development-2,
  .marketing-1,
  .design-1,
  .text-8,
  .text-11 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-240 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 24px;
    position: relative;
    width: 886px;
  }

  .button-6 {
    align-items: center;
    background-color: var(--portage);
    border-radius: 12px;
    display: flex;
    height: 64px;
    margin-top: -300px;
    margin-left: 875px;
    opacity: 0;
    position: relative;
    width: 200px;
  }

  .send-request {
    line-height: 24px;
    margin-top: -1.5px;
    white-space: nowrap;
    padding-left: 75px;
    align-self: center;
    width: fit-content;
  }
  .geomtris-right {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    width: 100%;
    height: 471.89px;
    left: 1165px;
    top: 1657.43px;
    background-repeat: no-repeat;
    opacity: 0.42;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  small {
    font-size: 75%;
    text-indent: 0em;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }

  @keyframes animate-enter19-frames {
    from {
      opacity: 0;
      transform: translate(-25px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

@media screen and (min-width: 1400px) {
  .contact-us-4 {
    align-items: center;
    background-color: var(--black);
    border: 1px none;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    position: relative;
  }

  .hero-seccction {
    align-items: center;
    display: flex;
    min-height: 250px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    max-width: 1400px;
    margin-top: 75px;
  }

  .flex-col-4 {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    position: relative;
    width: 100%;
  }

  .headline {
    align-items: center;
    flex-direction: row;
    gap: 10px;
    top: 0px;
    opacity: 0;
    position: relative;
    transform: translate(-25px, 0);
    width: fit-content;
  }

  .chip-4 {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: fit-content;
  }

  .line-1-1 {
    height: 1px;
    min-width: 32px;
    object-fit: cover;
    position: relative;
  }

  .contact-us-5 {
    color: var(--portage);
    font-family: var(--font-family-space_grotesk);
    font-size: var(--font-size-xs);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
  .contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
  }

  .enter-your-email {
    flex-direction: row;
    align-items: flex-start;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    border: none;
    gap: 10px;
    overflow: hidden;
    padding: 20px 29px 22px;
    width: 48.5%;
    margin-top: 0px;
  }
  .labelName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: -60px;
  }
  .company-name-space {
    align-items: flex-start;
    align-self: stretch;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 100%;
  }
  .label-company {
    align-items: flex-start;
    width: 45%;
    position: relative;
  }
  .label-name {
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: 48.5%;
  }

  .enter-your-name {
    align-items: flex-start;
    flex-direction: row;
    border: none;
    background-color: var(--white-2);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 24px 22px;
    position: relative;
    width: 48.5%;
  }
  .label-name {
    padding-bottom: 20px;
    font-family: var(--font-family-space_grotesk) !important;
  }

  .tell-us-about-your-project-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 1000px;

    height: 200px;
  }

  .input-field-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 45%;
    position: relative;
  }

  .tell-us-about-your-project-2 {
    height: 158px;
    gap: 20px;
    position: relative;
    width: 1000px;
    background-color: var(--white-2);
    border-radius: 20px;
    height: 300px;
    border: none;
  }
  textarea,
  textarea:focus {
    text-indent: 10px;
    outline: none;
    padding-top: 5px;
  }

  .lets-talk-about-you {
    letter-spacing: 0;
    line-height: 74px;
    position: relative;
    width: 1216px;
  }

  .section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 1580px;
    width: 100%;
    position: relative;
  }

  .form-contact-us {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: -300px;
    height: 1334px;
    position: relative;
    width: 1080px;
  }

  .data,
  .tell-us-about-your-project {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);

    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(25px, 0);
  }

  .content-8 {
    display: flex;
    flex-direction: column;
    gap: 44px;
    position: relative;
    width: 1000px;
  }

  .content-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 64px;
    position: relative;
  }

  .types-of-work {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--black-3);
    border-radius: 20px;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    padding: 60px 40px;
    position: relative;
    transform: translate(-25px, 0);
  }

  .content-10 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 917px;
  }

  .types-of-work-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }

  .service-choice,
  .frame-238 {
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    display: flex;
    gap: 32px;
    position: relative;
  }

  .web-development-2 {
    align-items: center;
    border: 1px solid;
    background-color: var(--eerie-black);
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: 253px;
  }
  .buttonActive {
    color: black;
    background-color: white;
  }

  .web-development-3 {
    font-family: var(--font-family-switzer-medium);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .service-choice-item {
    background-color: var(--eerie-black);
    border: 1px solid;
    border-color: var(--concord);
    border-radius: 20px;
    display: flex;

    justify-content: center;
    padding: 20px 32px 22px;
    position: relative;
    width: fit-content;
  }

  .mobile-app-development-2,
  .marketing-1,
  .design-1,
  .text-8,
  .text-11 {
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-240 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 886px;
  }

  .button-6 {
    align-items: center;
    background-color: var(--portage);
    border-radius: 12px;
    display: flex;
    height: 64px;
    margin-top: -300px;
    margin-left: 875px;
    opacity: 0;
    position: relative;
    width: 200px;
  }

  .send-request {
    line-height: 24px;
    margin-top: -1.5px;
    white-space: nowrap;
    padding-left: 75px;
    align-self: center;
    width: fit-content;
  }

  .geomtris-right {
    background-image: url(../../static/img/GeomtrisRight.png);
    position: absolute;
    width: 100%;
    height: 471.89px;
    left: 1165px;
    top: 1657.43px;
    background-repeat: no-repeat;
    opacity: 0.42;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  small {
    font-size: 75%;
    text-indent: 0em;
    font-family: var(--font-family-switzer-thin);
    font-weight: lighter;
  }
}
