.hexagon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 170px;
  position: relative;
}

@media screen and (max-width: 700px) {
  .hexagonImage {
    width: 108.25px;
    height: 95.42px;
  }
  .hexagonImage.small {
    width: 75.48px;
    height: 66.54px;
  }
}
