.home-page-option-1 {
  align-items: center;
  background-color: var(--black);
  border: 1px none;
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;
}
.geo-top-home {
  background-image: url(../../static/img/HomeGeoTop.png);
  position: absolute;
  width: 497px;
  height: 471.89px;
  left: 0px;
  background-repeat: no-repeat;
  top: 0px;
  z-index: 5;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-home-right {
  background-image: url(../../static/img/HomeGeoBottom.png);
  position: absolute;
  width: 717.39px;
  height: 681.14px;

  top: 200px;
  z-index: 5;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.geo-bottom-right {
  background-image: url(../../static/img/HomeRightGeo2.png);
  position: absolute;
  width: 997px;
  height: 871.89px;

  top: -90px;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  z-index: 5;
}
.home-top {
  position: absolute;
  width: 1135.59px;
  height: 691.75px;
  left: 2397.45px;
  top: -100px;
  display: flex;
  opacity: 0.9;
  transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
}
.purple-blur-top {
  position: absolute;
  width: 706.12px;
  height: 502.13px;
  left: 2850.7px;
  top: -1455px;
  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.green-blur-top {
  position: absolute;
  width: 852.95px;
  height: 350.6px;
  left: 2050.7px;
  top: -1330px;
  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}

.home-purple {
  position: absolute;
  display: flex;
  width: 949.9px;
  height: 350.09px;
  left: 1482.7px;
  top: 82.62px;
  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.home-green {
  position: absolute;
  width: 900.59px;
  height: 257.61px;
  left: 1600.45px;
  top: -80px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}

.home-right {
  position: absolute;
  width: 1135.59px;
  height: 691.75px;
  left: 1300px;
  top: -350px;
  display: flex;
  opacity: 0.7;
  transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
}
.home-left {
  position: absolute;
  width: 652px;
  height: 401px;
  left: 790px;
  top: -50px;
  display: flex;
  opacity: 0.7;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.home-purple2 {
  position: absolute;
  width: 529px;
  height: 330.78px;
  left: 875.18px;
  top: 400.22px;
  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
  transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
}
.home-green2 {
  position: absolute;
  width: 552px;
  height: 325.19px;
  left: 875px;
  top: 100px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.home-right2 {
  position: absolute;
  width: 752px;
  height: 601px;
  left: 1400px;
  top: -170px;
  display: flex;
  opacity: 0.7;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.home-purple3 {
  position: absolute;
  width: 540.43px;
  height: 250.78px;
  left: 1550.18px;
  top: 380.22px;

  background: #5025bf;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}
.home-green3 {
  position: absolute;
  width: 520px;
  height: 250px;
  left: 1592px;
  top: 180px;

  background: #119291;
  filter: blur(70px);
  transform: translate3d(0, 0, 0);
}

.we-are-a-digital-agency,
.what-we-offer-1,
.technologies-we-use-2,
.testimonials-1 {
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-92 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 272px;
  justify-content: center;
  position: relative;
  width: 519px;
}

.discovery-lets-chat-1 {
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 405px;
}

.span0-1 {
  letter-spacing: 1.5px;
}

.span-2 {
  letter-spacing: 0;
}

.strategy-we-make-th {
  color: transparent;
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-l);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 405px;
}

.span0-2 {
  color: #119291;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.x4 {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 72px;
  margin-top: 32px;
  position: relative;
  width: 477px;
}

.testing-qa-we-test {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 405px;
}
@media screen and (max-width: 1450px) {
  .home-left {
    left: 990px;
  }
  .geo-bottom-right {
    left: 1000px;
  }
  .home-purple2 {
    position: absolute;
    width: 529px;
    height: 330.78px;
    left: 1025.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 552px;
    height: 325.19px;
    left: 1025px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 1360px) {
  .geo-bottom-right {
    left: 900px;
  }
  .geo-home-right {
    top: 650px;
  }
  .home-purple2 {
    position: absolute;
    width: 529px;
    height: 330.78px;
    left: 1025.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 552px;
    height: 325.19px;
    left: 1025px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 1280px) {
  .home-left {
    left: 990px;
  }
  .geo-bottom-right {
    left: 700px;
    top: -3000px;
  }
  .home-top {
    position: absolute;
    width: 935.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -100px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 706.12px;
    height: 502.13px;
    left: 2700.7px;
    top: -1385px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 822.95px;
    height: 350.6px;
    left: 2000.7px;
    top: -1330px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple2 {
    position: absolute;
    width: 529px;
    height: 330.78px;
    left: 1185px;
    top: 440.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 552px;
    height: 325.19px;
    left: 1200px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    position: absolute;
    display: flex;
    width: 949.9px;
    height: 300.09px;
    left: 1382.7px;
    top: 52.62px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 1000.59px;
    height: 257.61px;
    left: 1570.45px;
    top: -110px;

    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 1135.59px;
    height: 691.75px;
    left: 1400px;
    top: -400px;
    display: flex;
    opacity: 0.9;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 652px;
    height: 401px;
    left: 1100px;
    top: -100px;
    display: flex;
    opacity: 0.9;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media screen and (max-width: 1200px) {
  .home-left {
    left: 990px;
  }
  .geo-bottom-right {
    left: 700px;
    top: -3000px;
  }
  .home-top {
    position: absolute;
    width: 935.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -100px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 706.12px;
    height: 502.13px;
    left: 2700.7px;
    top: -1385px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 822.95px;
    height: 350.6px;
    left: 2000.7px;
    top: -1330px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple2 {
    position: absolute;
    width: 529px;
    height: 330.78px;
    left: 975.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 552px;
    height: 325.19px;
    left: 975px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple {
    position: absolute;
    display: flex;
    width: 949.9px;
    height: 300.09px;
    left: 1382.7px;
    top: 52.62px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 1000.59px;
    height: 257.61px;
    left: 1570.45px;
    top: -110px;

    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 1135.59px;
    height: 691.75px;
    left: 1400px;
    top: -400px;
    display: flex;
    opacity: 0.9;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 652px;
    height: 401px;
    left: 1100px;
    top: -100px;
    display: flex;
    opacity: 0.9;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media screen and (max-width: 1050px) {
  .geo-bottom-right {
    left: 500px;
    top: -3000px;
  }
  .home-top {
    position: absolute;
    width: 635.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -50px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 506.12px;
    height: 502.13px;
    left: 2480.7px;
    top: -1405px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 852.95px;
    height: 300.6px;
    left: 1650.7px;
    top: -1330px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    position: absolute;
    display: flex;
    width: 949.9px;
    height: 280.09px;
    left: 1382.7px;
    top: 52.62px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 900.59px;
    height: 227.61px;
    left: 1550.45px;
    top: -80px;

    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 1135.59px;
    height: 491.75px;
    left: 1200px;
    top: -350px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 652px;
    height: 401px;
    left: 1050px;
    top: -100px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media screen and (max-width: 850px) {
  .geo-bottom-right {
    left: 400px;
    top: -3000px;
  }
  .home-top {
    position: absolute;
    width: 435.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -50px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 306.12px;
    height: 502.13px;
    left: 2280.7px;
    top: -1355px;
    background: #5025bf;
    filter: blur(75px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 452.95px;
    height: 300.6px;
    left: 1850.7px;
    top: -1300px;
    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    position: absolute;
    display: flex;
    width: 900.9px;
    height: 180.09px;
    left: 1382.7px;
    top: 92.62px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 850.59px;
    height: 180.61px;
    left: 1450.45px;
    top: -120px;

    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 600.59px;
    height: 491.75px;
    left: 1500px;
    top: -390px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 652px;
    height: 401px;
    left: 1000px;
    top: -100px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media screen and (max-width: 820px) {
  .geo-home-right {
    left: 250px;
  }
  .geo-top-home {
    width: 250px;
  }
}
/* 
@media screen and (max-width: 1360px) {
  .hero-section-1 {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .heroWritingSection {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
  }
  .group-298-1 {
    height: 100%;
    width: 100%;
    min-width: 500px;
  }
} */

@media screen and (max-width: 700px) {
  .we-are-a-digital-agency,
  .what-we-offer-1,
  .technologies-we-use-2,
  .testimonials-1 {
    letter-spacing: 1.5px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    font-size: 14px !important;
  }
  .geo-home-right {
    top: 400px;
    width: 797px;
    height: 571.89px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .home-right {
    top: -600px;
    left: 1200px;
  }
  .home-left {
    top: -250px;
    left: 800px;
  }
  .geo-bottom-right {
    left: 200px;
    top: -2500px;
  }
  .home-top {
    position: absolute;
    width: 435.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -50px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 306.12px;
    height: 502.13px;
    left: 2280.7px;
    top: -1485px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 292.95px;
    height: 200.6px;
    left: 2000.7px;
    top: -1230px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple {
    position: absolute;
    display: flex;
    width: 300.9px;
    height: 230.09px;
    left: 1482.7px;
    top: 52.62px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 630.59px;
    height: 110.61px;
    left: 1350.45px;
    top: -20px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 600.59px;
    height: 491.75px;
    left: 1400px;
    top: -500px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 300px;
    height: 301px;
    left: 1200px;
    top: -180px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-purple2 {
    position: absolute;
    width: 500px;
    height: 330.78px;
    left: 975.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 500px;
    height: 325.19px;
    left: 975px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-right2 {
    position: absolute;
    width: 352px;
    height: 201px;
    left: 1700px;
    top: -250px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-purple3 {
    position: absolute;
    width: 369.43px;
    height: 280.78px;
    left: 1520.18px;
    top: 301.22px;

    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green3 {
    position: absolute;
    width: 352px;
    height: 300.19px;
    left: 1572px;
    top: 120px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 500px) {
  .geo-bottom-right {
    left: 100px;
    top: -2500px;
  }
  .home-right2 {
    top: -250px;
  }
  .home-top {
    position: absolute;
    width: 435.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -50px;
    display: flex;
    opacity: 0.9;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 306.12px;
    height: 502.13px;
    left: 2280.7px;
    top: -1445px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 298.95px;
    height: 200.6px;
    left: 2000.7px;
    top: -1230px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    position: absolute;
    display: flex;
    width: 300.9px;
    height: 220.09px;
    left: 1382.7px;
    top: 52.62px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 630.59px;
    height: 140.61px;
    left: 1150.45px;
    top: -80px;

    background: #119291;
    filter: blur(90px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 600.59px;
    height: 491.75px;
    left: 1200px;
    top: -400px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 200px;
    height: 201px;
    left: 1200px;
    top: -180px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-right2 {
    position: absolute;
    width: 352px;
    height: 201px;
    left: 1700px;
    top: -250px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-purple3 {
    position: absolute;
    width: 369.43px;
    height: 150.78px;
    left: 1520.18px;
    top: 391.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-green3 {
    position: absolute;
    width: 352px;
    height: 250.19px;
    left: 1572px;
    top: 150px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple2 {
    position: absolute;
    width: 400px;
    height: 330.78px;
    left: 975.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 402px;
    height: 325.19px;
    left: 975px;
    top: 100px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 450px) {
  .geo-bottom-right {
    left: 100px;
    top: -2500px;
    width: 697px;
    height: 571.89px;
  }
  .geo-top-home {
    width: 187px;
    height: 341.89px;
  }
  .home-right {
    top: -700px;
    left: 1100px;
    width: 900px;
    height: 200px;
  }
  .geo-home-right {
    width: 697px;
    height: 371.89px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    top: 580px;
  }
  .home-top {
    position: absolute;
    width: 435.59px;
    height: 691.75px;
    left: 2397.45px;
    top: -50px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .purple-blur-top {
    position: absolute;
    width: 306.12px;
    height: 452.13px;
    left: 2200.7px;
    top: -1445px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .green-blur-top {
    position: absolute;
    width: 292.95px;
    height: 200.6px;
    left: 2000.7px;
    top: -1230px;
    background: #119291;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }

  .home-purple {
    position: absolute;
    display: flex;
    width: 300.9px;
    height: 175.09px;
    left: 1382.7px;
    top: -20.62px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-green {
    position: absolute;
    width: 350.59px;
    height: 150.61px;
    left: 1360.45px;
    top: -109px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }

  .home-right {
    position: absolute;
    width: 600.59px;
    height: 491.75px;
    left: 1200px;
    top: -440px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
  }
  .home-left {
    position: absolute;
    width: 200px;
    height: 201px;
    left: 1200px;
    top: -180px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-right2 {
    position: absolute;
    width: 352px;
    height: 201px;
    left: 1700px;
    top: -250px;
    display: flex;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .home-purple3 {
    position: absolute;
    width: 369.43px;
    height: 250.78px;
    left: 1520.18px;
    top: 331.22px;

    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
  }
  .home-green3 {
    position: absolute;
    width: 352px;
    height: 280.19px;
    left: 1572px;
    top: 150px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
  .home-purple2 {
    position: absolute;
    width: 400px;
    height: 280.78px;
    left: 995.18px;
    top: 400.22px;
    background: #5025bf;
    filter: blur(60px);
    transform: translate3d(0, 0, 0);
    transform: matrix(-0.53, -0.85, -0.85, 0.53, 0, 0);
  }
  .home-green2 {
    position: absolute;
    width: 452px;
    height: 405.19px;
    left: 955px;
    top: 180px;

    background: #119291;
    filter: blur(70px);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 1055px) {
  .services-1 {
    height: auto;
    /* overflow: hidden; */
    width: 100%;
    margin: auto;
  }
  .section-services-1 {
    height: auto;
  }
  .section-how-we-work-1 {
    height: auto;
  }
}
