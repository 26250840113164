.terms-and-conditions {
  background-color: var(--black);
  border: 1px none;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.terms-and-conditions-container {
  min-height: 500px;
  height: auto;
  z-index: 10;
  max-width: 1200px;
  gap: 25px;
  display: flex;
  flex-direction: column;

  margin: 30px 20px 80px 20px;
}
div.terms-and-conditions-container h4 {
  line-height: 35px;
}
.terms-div {
  display: flex;
  flex-direction: column;
  line-height: 25px;
}

@media screen and (max-width: 700px) {
  .terms-div {
    font-size: 12px !important;
    line-height: 15px;
  }
  div.terms-and-conditions-container h4 {
    font-size: 18 !important;
  }
  .terms-main-header {
    font-size: 32px !important;
  }
}
