.frame-266-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 4px;
  position: relative;
}

.vuesaxoutlineadd-1 {
  height: 24px;
  min-width: 24px;
  position: relative;
}

.create-issue-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.8;
  position: relative;
}

